import gql from 'graphql-tag';

export const loginEmailPass = gql`
    query loginEmailPass($correo_electronico: String!, $password: String!){
      loginEmailPass(
        correo_electronico: $correo_electronico,
        password: $password
      ){
        id,
        primer_nombre
        otros_nombres
        apellido_paterno
        apellido_materno
        correo_electronico,
        nro_ci,
        compl_ci,
        exped_ci,
        password,
        estado,
        created_at,
        updated_at,
        cod_user,
        codigo_user,
        user_roles{
          rol{
            id,
            nombre,
            cod_rol,
            estado,
            created_at,
            updated_at,
            cod_user
          }
        },
        user_permisos{
          permiso{
            id,
            cod_permiso,
            nombre,
            estado,
            created_at,
            updated_at,
            cod_user,
          }
        }
      }
    }
    
`;

export const getUsers=gql`
  query getUsers{
    getUsers{
      id,
      primer_nombre,
      otros_nombres,
      apellido_paterno,
      apellido_materno,
      codigo_user,
      correo_electronico,
      nro_ci,
      compl_ci,
      exped_ci,
      password,
      created_at,
      updated_at,
      cod_user
    }
  }
`;

export const getUserCodigoUser=gql`
  query getUserCodigoUser($codigo_user:String!){
    getUserCodigoUser(codigo_user:$codigo_user){
      id,
      primer_nombre,
      otros_nombres,
      apellido_paterno,
      apellido_materno,
      codigo_user,
      correo_electronico,
      nro_celular
      nro_ci,
      compl_ci,
      exped_ci,
      password,
      estado
    }
  }
`;

export const existeCorreoElectronico=gql`
  query existeCorreoElectronico($correo_electronico:String!){
    existeCorreoElectronico(correo_electronico:$correo_electronico)
  }
`;
export const reenviarCorreoCreacionCuenta=gql`
  mutation reenviarCorreoCreacionCuenta($correo_electronico:String!){
    reenviarCorreoCreacionCuenta(
      correo_electronico:$correo_electronico){id}
  }
`; 
