import gql from 'graphql-tag';

export const guardarOpcionRespuestaUser=gql`
    mutation guardarOpcionRespuestaUser($opcion_multiple:Boolean!, $valor_opcion_respuesta_user:Boolean!, $opcion_respuesta_user_id:Float!){
        guardarOpcionRespuestaUser(
          opcion_multiple: $opcion_multiple
          valor_opcion_respuesta_user: $valor_opcion_respuesta_user
          opcion_respuesta_user_id: $opcion_respuesta_user_id       
          ){
            id,
            pregunta_examen_generado_id
            opcion_respuesta_id,
            valor_opcion,
            nro_orden,
            estado
        }
    }
`;

