import gql from 'graphql-tag';

export const insertOrUpdateExamen= gql`
    mutation insertOrUpdateExamen($examenData:ExamenInput!){
        insertOrUpdateExamen( examenData: $examenData){
          id,
          nombre,
          descripcion,
          duracion_examen_minutos,
          estado,
          created_at,
          updated_at,
          cod_user
        }
    }
`;

export const deleteExamen= gql`
    mutation deleteExamen($id:Int!){
        deleteExamen(id:$id)
    }
`;

