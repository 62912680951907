import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../@core/interfaces/user.interface';
import { UsersService } from '../@core/services/users.service';

@Component({
  selector: 'app-restablecer-password',
  templateUrl: './restablecer-password.component.html',
  styleUrls: ['./restablecer-password.component.scss']
})
export class RestablecerPasswordComponent implements OnInit {

  userFormGroup: FormGroup;
  restableciendo_password = false;
  passwords_iguales;
  verificando_token = false;
  token_correcto = false;
  token;
  user;
  password_restablecida;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private router:Router
  ) {
    this.token = this.activatedRoute.snapshot.params['token'].toString();
    this.verificarToken(this.token);
    this.buildUserFormGroup()

  }
  verificarToken(token: string) {
    this.verificando_token = true;
    this.usersService.verificarTokenRestablecerPassword(token)
      .subscribe((res: any) => {
        // console.log(res);
        if (res.data.verificarTokenRestablecerPassword != null) {
          this.token_correcto = true;
          this.user=res.data.verificarTokenRestablecerPassword;
        }
        else {
          this.token_correcto = false;
        }
      },
        err => { 
          // console.log(err);
         },
        () => { this.verificando_token = false; 
          // console.log("completado"); 
        }
      )
  }

  ngOnInit(): void {
  }

  buildUserFormGroup() {
    this.userFormGroup = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      password_repetido: ['', [Validators.required, Validators.minLength(4)]]
    });
    this.userFormGroup.valueChanges.subscribe(data => this.validar(data));

  }

  restablecer_password() {
    let p1 = this.userFormGroup.get('password').value;
    let p2 = this.userFormGroup.get('password_repetido').value;
    if (p1 == p2) {
      this.restableciendo_password=true;
      this.passwords_iguales = true;
      let user={}as User;
      user.id=this.user.id
      user.password=p1;
      this.usersService.updateUser(user).subscribe((res:any)=>{
        // console.log(res);
        this.password_restablecida=true;
        this.restableciendo_password=false;
      },
      err=>{
        this.password_restablecida=false;
      },
      ()=>{
        this.restableciendo_password=false;
      }
      )
    }
    else {
      this.passwords_iguales = false;
    }
  }

  irAHome(){
    localStorage.clear();
    this.router.navigate(['home-estudiante']);
  }

  validar(data?:any){
  	if (!this.userFormGroup) { return; }
    const form = this.userFormGroup;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  formErrors = {
    'password': '',
    'password_repetido': '',
  };
  validationMessages = {
    'password': {
      'required': 'Este campo es requerido',
      'minlength': 'La contraseña debe tener minimo 4 caracteres',
    },
    'password_repetido': {
      'required': 'Este campo es requerido',
      'minlength': 'La contraseña debe tener minimo 4 caracteres'
    }

  };

}
