import { variable } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { finalizarExamenGenerado, generarExamen, generarExamenRepetido } from '../operations/examenes-generados.mutations';
import { getDuracionRestanteExamenGenerado, getExamenGenerado, getUltimosExamenesGeneradosCodUser } from '../operations/examenes-generados.queries';
import { getPreguntasExameneGenerado } from '../operations/preguntas_examenes_generados.queries';

@Injectable({
  providedIn: 'root'
})
export class ExamenesGeneradosService {

  constructor(private apollo: Apollo) { }

  generarExamen(cod_user: string, user_examen_id: number) {
    return this.apollo.mutate({
      mutation: generarExamen,
      variables: {
        cod_user: cod_user,
        user_examen_id: user_examen_id
      }
    });
  }

  generarExamenRepetido(cod_user: string, examen_generado_id: number) {
    return this.apollo.mutate({
      mutation: generarExamenRepetido,
      variables: {
        cod_user: cod_user,
        examen_generado_id: examen_generado_id
      }
    });
  }

  getExamenGenerado(id:number){
    return this.apollo.watchQuery({
      query:getExamenGenerado,
      variables:{
        id
      },
      fetchPolicy:'network-only',
    }).valueChanges.pipe(map((result:any)=>{
      return result.data;
    }))
  };

  getDuracionRestanteExamenGenerado(id:number){
    return this.apollo.query({
      query:getDuracionRestanteExamenGenerado,
      variables:{ id:id },
      fetchPolicy:'network-only'
    })
  };

  getUltimosExamenesGeneradosCodUser(cod_user:string){
    // console.log("codigo de usuario para get examenes generados"+cod_user);
    return this.apollo.query({
      query: getUltimosExamenesGeneradosCodUser,
      variables:{
         cod_user: cod_user
        },
      fetchPolicy:'network-only',
    })
  }

  finalizarExamenGenerado(id: number){
    return this.apollo.mutate({
      mutation:finalizarExamenGenerado,
      variables:{id: id}
    })
  }

  getPreguntasExameneGenerado(examen_generado_id){
    return this.apollo.watchQuery({
      query:getPreguntasExameneGenerado,
      variables:{
        examen_generado_id
      },
      fetchPolicy:'network-only',
    }).valueChanges.pipe(map((result:any)=>{
      return result.data;
    }))
  }
  query_getPreguntasExameneGenerado(examen_generado_id){
    return this.apollo.query({
      query:getPreguntasExameneGenerado,
      variables:{
        examen_generado_id
      },
      fetchPolicy:'network-only',
    }).pipe(map((result:any)=>{
      return result.data;
    }))
  }

}
