
import gql from 'graphql-tag';

export const insertOrUpdateExamenBancoPregunta= gql`
    mutation insertOrUpdateExamenBancoPregunta($examen_banco_preguntaData: ExamenBancoPreguntaInput!){
      insertOrUpdateExamenBancoPregunta(examen_banco_preguntaData: $examen_banco_preguntaData){
            id
            examen_id
            banco_pregunta_id
            nombre
            descripcion
            nro_preguntas
            porcentaje_calificacion
            duracion_minutos
            nro_orden
            estado
            created_at
            updated_at
            cod_user
          }
        }
`;