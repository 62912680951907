import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ExamenBancoPregunta } from '../interfaces/examen-banco-pregunta.interface';
import { insertOrUpdateExamenBancoPregunta } from '../operations/examenes-bancos-preguntas.mutations';

@Injectable({
  providedIn: 'root'
})
export class ExamenesBancosPreguntasService {

  constructor(private apollo: Apollo) { }

  insertExamenBancoPregunta(examen_banco_pregunta:ExamenBancoPregunta){
    // console.log(examen_banco_pregunta);
    return this.apollo.mutate({
      mutation:insertOrUpdateExamenBancoPregunta,
      variables:{
        examen_banco_preguntaData:examen_banco_pregunta
      }
    });
  }
}
