import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class GuardHomeChildsGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private userService: UsersService
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.userService.getEstadoLogin()=="t") {
        
        let roles:string[]=this.userService.getRoles();
        if(roles.includes("est")){
          this.router.navigate(['home-estudiante']);
        }else{
          return true;
        }
      }else{
        // return true;
        // console.log('No estás logueado');
        this.router.navigate(['login']);
        return false;
      }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let roles:string[]=this.userService.getRoles();
    if(roles.includes("adm")){
      return true
    }else{
      return false
    }
  }
  
}
