<app-header-estudiante></app-header-estudiante>
<div class="container">


    <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="5px" class="mt-3 mb-1">
        <div fxFlex="80" fxFlex.xs="100">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx" class="mb-1 mx-1">

                <div fxLayout="row" fxLayoutAlign="start center">
                    <img src="assets/images/img_default_user.png" alt="Avatar" class="avatar">

                    <h3 class="name-estudiante mb-0 ml-1 color-1">
                        <!-- Cesar Sarzuri Gutierrez -->
                        {{user.primer_nombre+" "+user.otros_nombres}} {{user.apellido_paterno}}
                        {{user.apellido_materno}}
                    </h3>
                </div>
                <span class="example-spacer"></span>
                <button mat-icon-button matTooltip="Configuración" [routerLink]="['/edit-info-estudiante']">
                    <!-- <button mat-icon-button matTooltip="Menu" [mat-menu-trigger-for]="menu" > -->
                    <mat-icon>settings</mat-icon>
                </button>
                <!-- <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                      <mat-icon>dialpad</mat-icon>
                      <span>Información personal</span>
                    </button>
                  </mat-menu> -->

            </div>
        </div>
    </div>
    <div *ngIf="cargando" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">

        <!-- <p>Cargando...</p> -->
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

    </div>

    <div *ngFor="let element of ultimosExamenesGenerados; let i =index">
        <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx">
            <mat-card fxFlex="80" fxFlex.xs="100" class="mb-2">
                <mat-card-header>
                    <mat-card-title>{{element.user_examen.examen.nombre}}</mat-card-title>
                    <mat-card-subtitle>
                        <span> </span>
                        <span
                            *ngFor="let banco_pregunta of element.user_examen.examen.examen_bancos_preguntas; let i =index">
                            <span *ngIf="i!=0">-</span> {{banco_pregunta.nombre}}
                        </span>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>

                <div *ngIf="element.ultimo_examen_generado">
                    <div fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="start center"
                        fxLayoutGap="0px" class="my-3">

                        <!-- <p class="mx-1 my-05"><span class="fw-500">Último Examen: </span>{{moment(dateTimeLocal(element.ultimo_examen_generado.created_at)).format('DD/MM/YYYY HH:mm:ss a')}}</p> -->
                        <p class="mx-1 my-05"><span class="fw-500">Último Examen:
                            </span>{{opAux.fechaTimeZone(element.ultimo_examen_generado.created_at)}}</p>


                        <p *ngIf=" (moment(element.ultimo_examen_generado.fecha_hora_fin).diff(moment(),'seconds')>0) && 
                                    (element.ultimo_examen_generado.examen_finalizado==false)  
                                    ; else elseTemplate" class="my-05 color_success fw-500 mx-1" fxFlex.xs="100%">
                            Examen en curso...
                        </p>

                        <ng-template #elseTemplate>
                            <p fxFlex.xs="100%" class="my-05 mx-1">
                                <span class="fw-500">Nota: </span>
                                {{element.ultimo_examen_generado.nota_examen}}/100
                            </p>

                            <p class="my-05 mx-1" fxFlex.xs="100%">
                                <span>{{element.ultimo_examen_generado.nota_examen>=element.user_examen.examen.nota_aprobacion?'Aprobado':"Reprobado"}}</span>
                                -
                                <span
                                    *ngIf="element.ultimo_examen_generado.nota_examen>=element.user_examen.examen.nota_admision"
                                    class="color_success fw-500">Admitido</span>
                                <span
                                    *ngIf="element.ultimo_examen_generado.nota_examen<element.user_examen.examen.nota_admision"
                                    class="color_warn">No Admitido</span>
                            </p>

                            <!-- <div *ngIf="element.ultimo_examen_generado.nota_examen>=element.user_examen.examen.nota_aprobacion; else elseBlock"
                                     fxFlex.xs="100%">
                                    Aprobado
                                </div>
                                <ng-template #elseBlock>
                                    <div  fxFlex.xs="100%">
                                        Reprobado
                                    </div>
                                </ng-template> -->

                            <!-- <ng-container
                                    *ngIf="element.ultimo_examen_generado.nota_examen>=element.user_examen.examen.nota_admision; else elseTemplate">
                                    <div  fxFlex.xs="100" fxLayoutAlign="center center"
                                        class="color_success fw-500 my-1"> Admitido </div>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <div  fxFlex.xs="100" class="color_warn my-1"
                                        fxLayoutAlign="center center"> No Admitido </div>
                                </ng-template> -->
                            <div fxFlex.xs="100">
                                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx"
                                    class="my-05 mx-1">
                                    <button mat-stroked-button
                                        [routerLink]="['/revisar-examen',element.ultimo_examen_generado.id]">Ver
                                        respuestas</button>
                                </div>
                            </div>
                        </ng-template>


                        <!-- <div fxFlex.xs="100" *ngIf="element.ultimo_examen_generado.nota_examen>=element.user_examen.examen.nota_admision">     -->





                        <div *ngIf="moment(element.ultimo_examen_generado.fecha_hora_fin).diff(moment(),'seconds')>0 && (element.ultimo_examen_generado.examen_finalizado==false) ;else elseBlock2"
                            fxFlex.xs="100">
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                                <button mat-raised-button color="primary" class="my-05 mx-1"
                                    (click)="continuarExamen(element.ultimo_examen_generado.id)">Continuar
                                    Examen</button>
                            </div>

                        </div>
                        <ng-template #elseBlock2>
                            <div *ngIf="element.ultimo_examen_generado.nota_examen<element.user_examen.examen.nota_admision; else elseBlock1"
                                fxFlex.xs="100">
                                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                                    <button mat-raised-button color="primary" class="my-05 mx-1 "
                                        (click)="generarExamenRepetido(element.ultimo_examen_generado.id)"
                                        [disabled]="this.generando_examen_repetido==true"
                                        >
                                        <!-- {{this.generando_examen_repetido==false?'Repetir Examen':'Generando Examen...'}} -->
                                        Repetir Examen
                                        </button>
                                </div>
                            </div>
                            <ng-template #elseBlock1>
                                <div fxFlex.xs="100">
                                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                                        <button mat-raised-button color="primary" class="my-05 mx-1"
                                            (click)="generarExamen(element.user_examen.id)"
                                            [disabled]="this.generando_examen_nuevo==true"
                                            >
                                            <!-- {{this.generando_examen==false?'Nuevo Examen':'Generando Examen...'}} -->
                                            Nuevo Examen
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-template>


                    </div>
                </div>
                <div *ngIf="!element.ultimo_examen_generado">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                        <button mat-raised-button color="primary" (click)="generarExamen(element.user_examen.id)"
                            class="my-05 mx-1 mt-4"
                            [disabled]="this.generando_examen_nuevo==true"
                            >
                            <!-- {{this.generando_examen_nuevo==false?'Generar Examen':'Generando Examen...'}} -->
                            Generar Examen
                        </button>
                    </div>

                </div>

                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx" class="mt-2">
                        
                    </div> -->

                <!-- <div *ngFor="let examen_generado of examen_preuniv.examenes_generados; let i_ex_gen=index">
                        <mat-divider></mat-divider>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="gappx" class="pt-1 pb-1">
                            <div fxFlex="25">
                                {{moment(dateTimeLocal(examen_generado.created_at)).format('DD/MM/YYYY HH:mm:ss')}}
                            </div>
                            <div fxFlex="25" class="pl-2">
                                {{examen_generado.nota_examen}}
                            </div>
                            <div *ngIf="examen_generado.nota_examen>50; else elseBlock" fxFlex="25" class="pl-2">
                                Aprobado
                            </div>
                            <ng-template #elseBlock>
                                <div fxFlex="25" class="pl-2">
                                    Reprobado
                                </div>
                            </ng-template>
                            <div *ngIf="moment(examen_generado.fecha_hora_fin).diff(moment(),'seconds')>0 ;else elseBlock2" fxFlex.xs="100"
                                >
                                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" >
                                    <button mat-raised-button color="primary"
                                    (click)="continuarExamen(examen_generado.id)">Continuar Examen</button>    
                                </div>
                                
                            </div>
                            <ng-template #elseBlock2>
                                <div *ngIf="examen_generado.nota_examen<51 && i_ex_gen+1==examen_preuniv.examenes_generados.length; else elseBlock1"
                                    class="mb-1"  fxFlex.xs="100">
                                    <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="5px">
                                        <button mat-raised-button color="primary"
                                            (click)="generarExamenRepetido(examen_generado.id)">
                                            Repetir Examen</button>
                                    </div>
                                </div>
                                <ng-template #elseBlock1>
                                    <div *ngIf="i_ex_gen+1==examen_preuniv.examenes_generados.length" fxFlex.xs="100">
                                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                                            <button mat-raised-button color="primary"
                                                (click)="generarExamen(examen_preuniv.id)">
                                                Nuevo Examen
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>
                    </div>
                    <div *ngIf="!examen_preuniv.examenes_generados" class="div_center">
                        <button mat-raised-button color="primary" (click)="generarExamen(examen_preuniv.id)">
                            <mat-icon>add</mat-icon> Generar Examen
                        </button>
                    </div> -->
                <!-- </mat-card-content> -->
                <mat-card-actions>
                    <button mat-stroked-button [routerLink]="['/historial-examenes',element.user_examen.id]">
                        <mat-icon>format_list_bulleted</mat-icon>
                        Ver historial</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>



</div>
<br><br>
<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx" class="mt-4" *ngIf="cargando==false">
    <h2 class="mb-0" id="t1" >Estudia con nosotros</h2>
    <h3 class="mb-0" id="st1">Contamos con el apoyo de las siguientes instituciones.</h3>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="gappx">
    
        <a href="https://esam.edu.bo/" target="_blank">
            <img src="assets/images/logoEsam.png" alt="esam" class="img-inst" >
        </a>
        <a href="https://cca.edu.bo/" target="_blank">
            <img src="assets/images/logoCCA2.png" alt="cca" class="img-inst">
        </a>
        <a href="https://cybercorp.com.bo/" target="_blank">
            <img src="assets/images/logoCyberCorp.png" alt="cybercorp" class="img-inst">
        </a>
        <a href="https://ciberkids.cca.edu.bo/" target="_blank">
            <img src="assets/images/logoCiberkids.png" alt="ciberkids" class="img-inst">
        </a>
    </div>
</div>

<br><br><br><br><br>