import gql from 'graphql-tag';

export const getExamenes= gql`
   query getExamenes{
      getExamenes{
        id
        codigo
        nombre
        descripcion
        duracion_examen_minutos
        nota_aprobacion
				nota_admision
        estado
        created_at
        updated_at
        cod_user
        examen_bancos_preguntas{
          id
          examen_id
          banco_pregunta_id
          banco_pregunta{
            id
            user_id
            nombre
            descripcion
            estado
            created_at
            updated_at
            cod_user
          }
          nombre
          descripcion
          nro_preguntas
          porcentaje_calificacion
          duracion_minutos
          nro_orden
          estado
          created_at
          updated_at
          cod_user
        }
      }
    }

`;