// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyB8odyaB4re7UAaZm8Yv8dDbt-Q-fK7hWU",
    authDomain: "simulacro-exam-preuniv.firebaseapp.com",
    databaseURL: "https://simulacro-exam-preuniv.firebaseio.com",
    projectId: "simulacro-exam-preuniv",
    storageBucket: "simulacro-exam-preuniv.appspot.com",
    messagingSenderId: "825610812452",
    appId: "1:825610812452:web:edb11d6cdac016bb442cb1",
    measurementId: "G-L0KV34W9GK"
  },
  // url_nestjs_graphql: 'http://localhost:3000/graphql',
  url_nestjs_graphql: 'https://service.examenes.com.bo/graphql',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
