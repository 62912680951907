import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { throwServerError } from '@apollo/client/core';
import { Rol } from '../@core/interfaces/rol.interface';
import { User } from '../@core/interfaces/user.interface';
import { UsersService } from '../@core/services/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  messageLogin:string="";
  userForm: FormGroup;
  user;
  cargando=false;

  hide = true;
  get emailInput() { return this.userForm.get('correo_electronico'); }
  get passwordInput() { return this.userForm.get('password'); } 

  constructor(
    private fb: FormBuilder,
    private apiUserGraphql:UsersService,
    private route: Router,
    // private location: Location
  ) {
    this.createForm()
  }

  

  createForm(){
    this.userForm=this.fb.group({
      correo_electronico:['', [Validators.required, Validators.email]],
      password:['', [Validators.required,Validators.minLength(4)]]
    });
    this.userForm.valueChanges.subscribe(data=> this.validar(data));
  }
  validar(data?: any) {
    if (!this.userForm) { return; }
    const form = this.userForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
  formErrors = {
    'correo_electronico': '',
    'password': '',
  };
  validationMessages = {
    'correo_electronico': {
      'required': 'El correo es requerido',
      'email': 'Correo electrónico no válido',
    },
    'password': {
      'required': 'La contraseña es requerida',
      'minlength': 'La contraseña debe tener al menos 4 caracteres'
    }
  };

   



  ngOnInit(): void {
  }

  login(){
    if(this.userForm.valid){
      // console.log("el formulario es valido");
   

    this.cargando=true;
    this.messageLogin="";
    // console.log(this.userForm.value);
    let correo_electronico=this.userForm.value.correo_electronico;
    let password=this.userForm.value.password;
    
    this.apiUserGraphql.loginEmailPass(correo_electronico, password).subscribe(data=>{
      // console.log(data);
      this.cargando=false;
        if(data==null){
          this.messageLogin="El correo y/o la contraseña son inválidos";
        }
        else{
          localStorage.clear();
          
          this.apiUserGraphql.setEstadoLogin("t");
          this.user=this.apiUserGraphql.getUser();
        
          // console.log(data);
          let roles:string[]=[];
          let user:User=data;

          for (const element of user.user_roles) {
            roles.push(element.rol.cod_rol)
          }
          // console.log(roles);

          var userParaLocalStorage: User={};
          userParaLocalStorage.codigo_user=user.codigo_user;
          userParaLocalStorage.primer_nombre=user.primer_nombre;
          userParaLocalStorage.otros_nombres=user.otros_nombres;
          userParaLocalStorage.apellido_paterno=user.apellido_paterno;
          userParaLocalStorage.apellido_materno=user.apellido_materno;

          // console.log(userParaLocalStorage);

          localStorage.setItem("roles", JSON.stringify(roles));
          localStorage.setItem("user", JSON.stringify(userParaLocalStorage));
          // console.log(JSON.parse(localStorage.getItem("roles")));
          // console.log(JSON.parse(localStorage.getItem("user")));

          if(roles.length>0 && roles.includes('est')){
            // console.log("rol solo estudiante");
            this.route.navigateByUrl('/home-estudiante');
          }
          else{
            // console.log('ss');
            this.route.navigateByUrl('/home/list-of-question-banks');
          } 
        }
      })
  }
  }


}
