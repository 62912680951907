import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { insertOrUpdateUserExamen } from '../operations/users-examenes.mutations';
import { getExamenesUser, getExamenUser } from '../operations/users-examenes.queries';
import { deleteUserExamen } from '../operations/users/users-mutations';


@Injectable({
  providedIn: 'root'
})
export class UsersExamenesService {

  constructor( private apollo:Apollo) { }

  getExamenesUser(cod_user: string){
    return this.apollo.query({
      query:getExamenesUser,
      variables:{
        cod_user:cod_user
      },
      fetchPolicy:"network-only"
    });
  }

  getExamenUser(id:number){
    return this.apollo.query({
      query:getExamenUser,
      fetchPolicy:'network-only',
      variables:{id:id}
    });
  }

  insertUserExamen(user_examen){
    return this.apollo.mutate({
      mutation:insertOrUpdateUserExamen,
      variables:{user_examenData:user_examen}
    })
  }

  deleteUserExamen(user_examen_id){
    return this.apollo.mutate({
      mutation:deleteUserExamen,
      variables:{user_examen_id:user_examen_id}
    })
  }
}
