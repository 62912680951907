import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { UsersExamenesService } from '../@core/services/users-examenes.service';
import { Operaciones } from '../@core/utils/opetarions';

@Component({
  selector: 'app-historial-examenes-estudiante',
  templateUrl: './historial-examenes-estudiante.component.html',
  styleUrls: ['./historial-examenes-estudiante.component.scss']
})
export class HistorialExamenesEstudianteComponent implements OnInit {

  moment:any=moment;
  user_examen_id:number;
  user_examen;
  opAux=Operaciones;
  constructor(
    private users_examenesService:UsersExamenesService,
    private rutaActiva: ActivatedRoute,
    public location:Location,
  ) { }

  ngOnInit(): void {
    this.user_examen_id=parseInt(this.rutaActiva.snapshot.params['user_examen_id']);
    const getExamenUser= this.users_examenesService.getExamenUser(this.user_examen_id).
      subscribe((res:any)=>{
        console.log(res);
        this.user_examen=res.data.getUserExamen;
      },
      err=>{ 
        // console.log(err);
      },
      ()=>{
        // console.log("completado"); 
        getExamenUser.unsubscribe();}
      )
  }

  // dateTimeLocal(dateTime){
  //   return Operaciones.fechaTimeZone(dateTime);
  // }

}
