
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/@core/services/users.service';

@Component({
  selector: 'app-header-estudiante',
  templateUrl: './header-estudiante.component.html',
  styleUrls: ['./header-estudiante.component.scss']
})
export class HeaderEstudianteComponent implements OnInit {

  user;
  constructor(
    private usersService:UsersService,
    private router:Router
  ) { 
    this.user=this.usersService.getUser();
  }

  ngOnInit(): void {
  }
  cerrarSesion(){
    this.usersService.logout();
    this.router.navigateByUrl('login');
  }
}
