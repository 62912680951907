import gql from 'graphql-tag';

export const generarExamen = gql`
    mutation generarExamen($cod_user: String!,$user_examen_id:Float!){
      generarExamen(
            cod_user: $cod_user
            user_examen_id: $user_examen_id
        ){
        id,
        codigo_examen,
        duracion_examen_minutos,
        user_examen_id,
        nota_examen,
        examen_finalizado,
        estado,
        created_at,
        updated_at,
        cod_user,
        preguntas_examenes_generados{
          id,
          examen_generado_id,
          pregunta_id,
          respuesta_correcta,
          estado,
          created_at,
          updated_at,
          opciones_respuestas_users{
            id,
            opcion_respuesta_id,
            valor_opcion,
            estado,
            created_at,
            updated_at,
            cod_user
          }     
        }
      }
    }
`;

export const generarExamenRepetido = gql`
    mutation generarExamenRepetido($cod_user: String!,$examen_generado_id:Float!){
      generarExamenRepetido(
            cod_user: $cod_user
            examen_generado_id: $examen_generado_id
        ){
        id,
        codigo_examen,
        duracion_examen_minutos,
        user_examen_id,
        nota_examen,
        examen_finalizado,
        estado,
        created_at,
        updated_at,
        cod_user,
        preguntas_examenes_generados{
          id,
          examen_generado_id,
          pregunta_id,
          respuesta_correcta,
          estado,
          created_at,
          updated_at,
          opciones_respuestas_users{
            id,
            opcion_respuesta_id,
            valor_opcion,
            estado,
            created_at,
            updated_at,
            cod_user
          }     
        }
      }
    }
`;


export const finalizarExamenGenerado = gql`
  mutation finalizarExamenGenerado($id: Int!){
    finalizarExamenGenerado(id:$id){
      id,
      examen_finalizado
    }
  }
`;