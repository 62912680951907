import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Examen } from '../interfaces/examen.interface';
import { insertOrUpdateExamen, deleteExamen } from '../operations/examenes-mutations';
import { getExamenes } from '../operations/examenes-queries';

@Injectable({
  providedIn: 'root'
})
export class ExamenesService {

  constructor(private apollo:Apollo) { }

  insertExamen(examen:Examen){
    delete examen.examen_bancos_preguntas;
    // console.log(examen);
    return this.apollo.mutate({
      mutation:insertOrUpdateExamen,
      variables:{
        examenData:examen
      }
    })
  }

  getExamenes(){
    return this.apollo.query({
      query:getExamenes,
      fetchPolicy:'network-only'
    })
  }
  delete(id:number){
    return this.apollo.mutate({
      mutation:deleteExamen,
      variables:{
        id:id
      }
    });
  }
}
