<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx" class="mx-4 my-1 ">
    <img src="assets/images/logoCCA2.png" width="80px">
</div>
<br>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
    <form novalidate #form="ngForm" [formGroup]="userFormGroup" autocomplete="off">
        <mat-card fxFlex="30rem" fxFlex.lt-md="20rem" class="mat-elevation-z2">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx">
                <h3 id="titulo_card">Restablecer contraseña</h3>    
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px" class="mt-2">
                <mat-form-field appearance="" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Correo electronico</mat-label>
                    <input matInput formControlName="correo_electronico" required>
                    <mat-icon matSuffix>alternate_email</mat-icon>
                    <!-- <mat-error *ngIf="this.userFormGroup.get('correo_electronico').errors?.existe"> El correo ya fue registrado </mat-error> -->
                </mat-form-field>
            </div>

            <div *ngIf="enviando_email_recuperacion" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">
                
                    <p>Enviando correo...</p>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                
            </div>
            <div *ngIf="correo_enviado" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">
                <p class="fw-500">Se le envió un correo para restablecer su contraseña.<span><mat-icon class="color_success">done_outline</mat-icon></span></p>
                <!-- <div id="messageInfo">
                    <p>Se le ha enviado un correo a la dirección de correo electrónico <span class="fw-500">{{correoElectronico.value}}</span> para la verificación y activación de su cuenta.</p>
                    <p>Si no encuentra este correo en su bandeja de entrada, revise las carpetas de ‘SPAM’ o ‘Correo no deseado’, ya que algunos clientes de correo electrónico mueven este tipo de correos a estas carpetas al no reconocer el remitente.</p>
                </div> -->
                
               
            </div>
            <mat-card-actions align="end" *ngIf="correo_enviado==false">
                <button mat-raised-button color=primary (click)="enviar_correo_verificacion() " [disabled]="userFormGroup.invalid|| enviando_email_recuperacion">Enviar correo</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
<br><br><br>