import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { deletePreguntaPorEstado } from '../operations/preguntas/preguntas-mutations';
import { getPreguntasByBancoDePreguntasId } from '../operations/preguntas/preguntas-queries';

@Injectable({
  providedIn: 'root'
})
export class PreguntasService {

  constructor(private apollo:Apollo) { }

  deletePreguntaPorEstado(id:number){
    console.log(id);
    return this.apollo.mutate({
      mutation:deletePreguntaPorEstado,
      variables:{
        id:id
      }
    });
  }

  getPreguntasByBancoDePreguntasId(banco_pregunta_id){
    return this.apollo.query({
      query:getPreguntasByBancoDePreguntasId,
      fetchPolicy:'network-only',
      variables:{
        banco_pregunta_id:banco_pregunta_id
      }
    })
  }
}
