import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../@core/services/users.service';

@Component({
  selector: 'app-recuperar-cuenta',
  templateUrl: './recuperar-cuenta.component.html',
  styleUrls: ['./recuperar-cuenta.component.scss']
})
export class RecuperarCuentaComponent implements OnInit {

  userFormGroup:FormGroup;
  enviando_email_recuperacion=false;
  correo_enviado=false;
  constructor(
    private usersService:UsersService,
    private fb: FormBuilder
  ) { 
    this.buildUserFormGroup()
  }

  ngOnInit(): void {
  }

  buildUserFormGroup(){
    this.userFormGroup= this.fb.group({
      correo_electronico:['',[Validators.required, Validators.email, Validators.minLength(10)]]
    })
  }

  enviar_correo_verificacion(){
    this.enviando_email_recuperacion=true;
    let correo_electronico=this.userFormGroup.get('correo_electronico').value;
    this.usersService.restablecer_password(correo_electronico).subscribe((res:any)=>{
      // console.log(res);
      this.correo_enviado=true;
    },err=>{},
    ()=>{this.enviando_email_recuperacion=false}
    )
  }

}
