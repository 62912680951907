import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExamenesService } from '../@core/services/examenes.service';

@Component({
  selector: 'app-eliminar-exam-alert-dialog',
  templateUrl: './eliminar-exam-alert-dialog.component.html',
  styleUrls: ['./eliminar-exam-alert-dialog.component.scss']
})
export class EliminarExamAlertDialogComponent implements OnInit {

  confirmar:boolean=false;
  constructor( 
    public dialogRef: MatDialogRef<EliminarExamAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private examenesService:ExamenesService
    ){}

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close({eliminado:true});
  }
  eliminarExamen(id){
    console.log('id',id);
    
    this.examenesService.delete(id).subscribe((data)=>{
      // console.log(data);
      this.close();
    });
    
  }

}
