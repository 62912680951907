import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ExamenesGeneradosService } from '../@core/services/examenes-generados.service';

@Component({
  selector: 'app-revisar-examen-estudiante',
  templateUrl: './revisar-examen-estudiante.component.html',
  styleUrls: ['./revisar-examen-estudiante.component.scss']
})
export class RevisarExamenEstudianteComponent implements OnInit {

  examen_generado_id;
  preguntasExamen: any[] = [];
  examen_generado;

  resumen_examen;      
  preguntas_por_bancos_d_preg; 

  displayedColumns: string[] = ['materia','porcentaje_calificacion', 'nro_preguntas', 'preg_resp_correctamente', ];
  dataSource = new MatTableDataSource();
  constructor(
    private rutaActiva: ActivatedRoute,
    private examenes_generadosService:ExamenesGeneradosService,
    public location:Location
  ) { }

  ngOnInit(): void {
    this.examen_generado_id=parseInt(this.rutaActiva.snapshot.params['examen_generado_id']);
    const getExamenGenerado= this.examenes_generadosService.getExamenGenerado(this.examen_generado_id).
      subscribe((res:any)=>{
        // console.log(res);
        this.examen_generado=res.getExamenGenerado;
        this.preguntasExamen = res.getExamenGenerado.preguntas_examenes_generados;

        let filtrado_preguntas = this.preguntasExamen.reduce(
          function (r, a) { 
            r[a.examen_banco_pregunta_id] = r[a.examen_banco_pregunta_id] || [];
            r[a.examen_banco_pregunta_id].push(a); 
            return r; 
          }, Object.create(null)
          );
        // console.log(filtrado_preguntas);
        
        let preguntas_por_responder=[];
        for (const key in filtrado_preguntas) {
          // console.log(filtrado_preguntas[key]);
          let preguntas= filtrado_preguntas[key];
          let preguntas_con_opciones=[];
          for (const p of preguntas) {
            let copy={...p};
            let opciones_marcadas= p.opciones_respuestas_users.filter(o=> o.valor_opcion==true);
            copy.pregunta_respondida=opciones_marcadas.length > 0?true:false;
            preguntas_con_opciones.push(copy);
          }
          // console.log(preguntas_con_opciones);
          let preguntas_por_responder_= (preguntas_con_opciones.filter(p=>p.pregunta_respondida==false)).length;
          let preguntas_respondidas_correctamente=(preguntas_con_opciones.filter(p=>p.respuesta_correcta==true)).length;
          preguntas_por_responder.push({
            banco_de_pregunta: preguntas_con_opciones[0].examen_banco_pregunta,
            banco_pregunta_examen: key,
            // preguntas: filtrado_preguntas[key],
            preguntas:preguntas_con_opciones,
            preguntas_por_responder: preguntas_por_responder_,
            preguntas_respondidas_correctamente:preguntas_respondidas_correctamente,
          });
        }
        // console.log(preguntas_por_responder);
        this.resumen_examen= preguntas_por_responder;
        
        this.preguntas_por_bancos_d_preg=filtrado_preguntas;
        this.dataSource.data= this.resumen_examen;

      },
      err=>{
        // console.log(err);
      },
      ()=>{
        // console.log("completado"); 
        getExamenGenerado.unsubscribe();}
      );
  }

  diferenteBancoDePreguntas(preguntaActual: any, preguntaAnterior: any): boolean {
    // console.log(preguntaActual);
    // console.log(preguntaAnterior);
    if (!preguntaAnterior) {
      return true;
    }
    else {
      if (preguntaActual.banco_pregunta_id != preguntaAnterior.banco_pregunta_id) {
        return true;
      }
      else { return false }
    }
  }

  letraOpcion(numeroOpcion) {
    switch (numeroOpcion) {
      case 1: return "a"; break;
      case 2: return "b"; break;
      case 3: return "c"; break;
      case 4: return "d"; break;
      case 5: return "e"; break;
      case 6: return "f"; break;
      case 7: return "g"; break;
      case 8: return "i"; break;
      case 9: return "j"; break;
      case 10: return "k"; break;
      case 11: return "l"; break;
      default: console.log("numero de opcion invalida"); break;
    }
  }

}
