<div class="container">
  <h2>To do</h2>

  <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo"
      [cdkDropListConnectedTo]="doneList" class="list" (cdkDropListDropped)="drop($event)">
    <div class="list-item" *ngFor="let item of todo" cdkDrag>{{item}}</div>
  </div>
</div>

<div class="container">
  <h2>Done</h2>

  <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
      [cdkDropListConnectedTo]="todoList" class="list" (cdkDropListDropped)="drop($event)">
    <div class="list-item" *ngFor="let item of done" cdkDrag>{{item}}</div>
  </div>
</div>

<button mat-button (click)="verlistas()" >ver lista done</button>
<!-- <button mat-button (click)="verpublicaciones()" >ver lista publicaciones</button>
<button mat-button (click)="addpublicaciones()" >crear publicacion</button> -->
