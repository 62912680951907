import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BancoPregunta } from '../@core/interfaces/banco-pregunta.interface';
import { ApiService } from '../@core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Pregunta } from '../@core/interfaces/pregunta.interface';
import { PartePregunta } from '../@core/interfaces/parte-pregunta.interface';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OpcionRespuesta } from '../@core/interfaces/opcion-respuesta.interface';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-form-add-question',
  templateUrl: './form-add-question.component.html',
  styleUrls: ['./form-add-question.component.scss']
})
export class FormAddQuestionComponent implements OnInit {
  questionPartTextFormGroup: FormGroup;
  questionPartImageFormGroup: FormGroup;

  answerOptionTextFormGroup: FormGroup;
  answerOptionImageFormGroup: FormGroup;
  isEditable = true;
  typeQuestionPart: 'text' | 'image' = 'text';
  question = {} as Pregunta;
  questionPart: PartePregunta;
  questionParts: PartePregunta[] = [];

  idQuestionBank: number;
  questionBank: BancoPregunta;
  confirmationQuestion: boolean = false;
  typeAnswerQuestion = 'onlyOption'; //'onlyOption'|'multipleOption'
  confirmationTypeAnswer: boolean = false;


  file = null;
  carga: Observable<number>;
  fileurl: string = 'https://firebasestorage.googleapis.com/v0/b/simulacro-exam-preuniv.appspot.com/o/imagesQuestionPart%2Fsinimagen.png?alt=media&token=5f9fa85e-bc1e-4ecb-94eb-ae0173dc28cb';
  imagen_height = 80;
  countCorrectOptions: number = 0;

  typeAnswerOption: 'text' | 'image' = 'text';
  answerOption: OpcionRespuesta;
  answerOptions: OpcionRespuesta[] = [];

  preguntaFormGroup: FormGroup;

  get opciones_respuesta(): FormArray {
    return this.preguntaFormGroup.get('opciones_respuesta') as FormArray;
  }
  constructor(
    public router: Router,
    private apiGraphql: ApiService,
    private rutaActiva: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    public location: Location
  ) { }

  ngOnInit(): void {
    this.idQuestionBank = Number(this.rutaActiva.snapshot.params['id-question-bank']);
    this.apiGraphql.getBancoPregunta(Number(this.idQuestionBank)).subscribe((res:any) => {
      this.questionBank = res.data.getBancoPregunta;
    });

    this.questionPartTextFormGroup = this._formBuilder.group({
      texto: '',
    })
    this.questionPartImageFormGroup = this._formBuilder.group({
      imagen_url: ['', Validators.required],
      imagen_nombre: "",
    })
    this.answerOptionTextFormGroup = this._formBuilder.group({
      texto: ['', []],
      opcion_correcta: [false]
    });
    this.answerOptionImageFormGroup = this._formBuilder.group({
      imagen_url: ['', Validators.required],
      imagen_nombre: "",
      opcion_correcta: [false],
    });
    this.buildFormGroupPregunta();
  }

  buildFormGroupPregunta(){
    this.preguntaFormGroup = this._formBuilder.group({
      partes_pregunta:this._formBuilder.array([], [Validators.required]),
      tipo_respuesta:['',[Validators.required]],
      opciones_respuesta:this._formBuilder.array([], [Validators.required]),
    });
  }

  pushQuestionPartText() {
    this.questionPart = this.questionPartTextFormGroup.value;
    this.questionPart.imagen_nombre = "";
    this.questionPart.imagen_url = ""
    this.questionPart.imagen_height = 0;
    this.questionPart.nro_orden = this.questionParts.length;
    this.questionParts.push(this.questionPart);
    // console.log(this.questionParts);
    this.questionPartTextFormGroup.reset();
  }

  pushQuestionPartImage() {
    let image_part = document.getElementById('image_part').getBoundingClientRect();
    this.questionPart = this.questionPartImageFormGroup.value;
    this.questionPart.texto = "";
    this.questionPart.imagen_height = image_part.height;
    this.questionPart.nro_orden = this.questionParts.length;
    this.questionParts.push(this.questionPart);
    // console.log(this.questionParts);

    this.questionPartImageFormGroup.reset();
    this.imagen_height = 80;
    this.fileurl = 'https://firebasestorage.googleapis.com/v0/b/simulacro-exam-preuniv.appspot.com/o/imagesQuestionPart%2Fsinimagen.png?alt=media&token=5f9fa85e-bc1e-4ecb-94eb-ae0173dc28cb';
  }

  pushAnswerOptionText() {
    this.answerOption = this.answerOptionTextFormGroup.value;
    this.answerOption.imagen_nombre = "";
    this.answerOption.imagen_url = ""
    this.answerOption.imagen_height = 0;
    this.answerOption.nro_orden = this.answerOptions.length;

    this.answerOptions.push(this.answerOption);
    const form = this.preguntaFormGroup.get('opciones_respuesta') as FormArray
    form.push(this.createItem(this.answerOption));

    if (this.answerOption.opcion_correcta) { this.countCorrectOptions++ }
    // console.log(this.answerOptions);
    this.answerOptionTextFormGroup.reset();

    
  }

  pushAnswerOptionImage() {
    
    let image_part = document.getElementById('image_part_resp').getBoundingClientRect();

    this.answerOption = this.answerOptionImageFormGroup.value;
    this.answerOption.texto = "";
    this.answerOption.imagen_height = image_part.height;
    this.answerOption.nro_orden = this.answerOptions.length;
    this.answerOptions.push(this.answerOption);
    const form = this.preguntaFormGroup.get('opciones_respuesta') as FormArray
    form.push(this.createItem(this.answerOption));


    if (this.answerOption.opcion_correcta) { this.countCorrectOptions++ }
    // console.log(this.answerOptions);

    this.answerOptionImageFormGroup.reset();
    this.imagen_height = 80;
    this.fileurl = 'https://firebasestorage.googleapis.com/v0/b/simulacro-exam-preuniv.appspot.com/o/imagesQuestionPart%2Fsinimagen.png?alt=media&token=5f9fa85e-bc1e-4ecb-94eb-ae0173dc28cb';
  }

  
createItem(opcion_respuesta:OpcionRespuesta): FormGroup {
  return this._formBuilder.group({
    imagen_height: opcion_respuesta.imagen_height ,
    imagen_nombre:  opcion_respuesta.imagen_nombre  ,
    imagen_url:  opcion_respuesta.imagen_url ,
    nro_orden:  opcion_respuesta.nro_orden ,
    opcion_correcta: opcion_respuesta.opcion_correcta ,
    texto:  opcion_respuesta.texto , 
  });
}

  uploadImageQuestionPart(event) {
    try {
      this.file = event.target.files[0];
      let filePath: string;
      let urlimagen: Observable<string>;
      let date = new Date().valueOf();
      // console.log(date);
      this.questionPartImageFormGroup.controls['imagen_nombre'].setValue(event.target.files[0].nombre + date.toString());
      filePath = 'localDevCesar/imagesQuestionPart/' + this.questionPartImageFormGroup.value.imagen_nombre;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.file);
      this.carga = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(
          () => {
            urlimagen = fileRef.getDownloadURL();
            urlimagen.subscribe(
              url => {
                this.fileurl = url;
                this.questionPartImageFormGroup.controls['imagen_url'].setValue(url);
                event.target.value='';
              }
            );
          }
        )
      ).subscribe();
      this.file = null;
    } catch (error) {
      // console.log(error);
    }
  }

  uploadImageAnswerOption(event) {
    try {
      this.file = event.target.files[0];
      let filePath: string;
      let urlimagen: Observable<string>;
      let date = new Date().valueOf();
      // console.log(date);
      this.answerOptionImageFormGroup.controls['imagen_nombre'].setValue(event.target.files[0].nombre + date.toString());
      filePath = 'localDevCesar/imagesAnswerOption/' + this.answerOptionImageFormGroup.value.imagen_nombre;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.file);
      this.carga = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(
          () => {
            urlimagen = fileRef.getDownloadURL();
            urlimagen.subscribe(
              url => {
                this.fileurl = url;
                this.answerOptionImageFormGroup.controls['imagen_url'].setValue(url);
                event.target.value='';
              }
            );
          }
        )
      ).subscribe();
      this.file = null;
    } catch (error) {
      // console.log(error);
    }
  }

  reiniciarConstruccionDePregunta() {
    this.questionParts = [];
  }

  reiniciarRegistroDeRespuestas() {
    this.answerOptions = [];
    this.countCorrectOptions = 0;
  }

  subirAlturaImagen() {
    this.imagen_height = this.imagen_height + 2;
  }

  bajarAlturaImagen() {
    this.imagen_height = this.imagen_height - 2;
  }


  guardarPregunta() {
    // console.log("id del banco de preguntas: " + this.idQuestionBank);
    this.question.banco_pregunta_id = this.idQuestionBank;
    this.question.descripcion = '';
    this.question.opcion_multiple = this.typeAnswerQuestion == "multipleOption" ? true : false;
    // console.log(this.answerOptions);
    // console.log(this.preguntaFormGroup.value.opciones_respuesta);
    this.apiGraphql.create_complete_question(this.question, this.questionParts, this.preguntaFormGroup.value.opciones_respuesta);
    alert('Pregunta creado correctamente.');
    this.location.back();

  }

  radioChange($event: MatRadioChange) {
    // console.log($event.source.name, $event.value);
    // console.log($event);
    if($event.value=="onlyOption"){
      let primer_marcado=false;
      this.opciones_respuesta.controls.forEach(option => {
        if(option.get('opcion_correcta').value==true  && primer_marcado==false){
          primer_marcado=true;
        }else{
          option.get('opcion_correcta').setValue(false);
        }
      });
    }
    if($event.value=="multipleOption"){
    }
    // console.log(this.opciones_respuesta);
  }
  changesOpcionesRespuesta(){
    this.preguntaFormGroup.controls['opciones_respuesta'].valueChanges.subscribe((res:any)=>{

    })
  }
  showOptions(e, i){
    // console.log(e);
    // console.log(i);
    if( e.checked==true && this.typeAnswerQuestion=='onlyOption' ){
      this.opciones_respuesta.controls.forEach((option, index) => {
        if(option.get('opcion_correcta').value==true && index!=i  ){
          option.get('opcion_correcta').setValue(false);
        }else{
          // option.get('opcion_correcta').setValue(false);
        }
      });
    }
  }

  conteoMarcado():number {
    let correctos= this.opciones_respuesta.value.filter(op=>op.opcion_correcta==true);
    return correctos.length;
  }

  removeOpcionRespuesta(index) {
    this.opciones_respuesta.removeAt(index);
  }
  removePartePregunta(index){
    this.questionParts.splice(index, 1);
  }
}
