import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Examen } from '../@core/interfaces/examen.interface';
import { UserExamen } from '../@core/interfaces/user-examen.interface';
import { User } from '../@core/interfaces/user.interface';
import { ExamenesService } from '../@core/services/examenes.service';
import { UsersExamenesService } from '../@core/services/users-examenes.service';
import { UsersService } from '../@core/services/users.service';
import {Location} from '@angular/common';
import { UserRol } from '../@core/interfaces/user-rol.interface';
import { UsersRolesService } from '../@core/services/users-roles.service';

@Component({
  selector: 'app-form-add-estudiante',
  templateUrl: './form-add-estudiante.component.html',
  styleUrls: ['./form-add-estudiante.component.scss']
})
export class FormAddEstudianteComponent implements OnInit {

  estudianteFormGroup:FormGroup;
  examenes:Examen[]=[];
  user;



  constructor(
    private fb: FormBuilder,
    private examenesService:ExamenesService,
    private usersService:UsersService,
    private users_examenesService:UsersExamenesService,
    private location:Location,
    private users_rolesService:UsersRolesService
  ) { 
    this.user = JSON.parse(localStorage.getItem("user"));
    const getExamenesPreuniversitario= this.examenesService.getExamenes().
    subscribe((res:any)=>{
      // console.log(res);
      this.examenes=res.data.getExamenes;
    },
    err=>{
      // console.log(err);
    },
    ()=>{
      // console.log("completado"); 
      getExamenesPreuniversitario.unsubscribe();
    }
    );
    this.buildEstudianteForm();
  }

  ngOnInit(): void {

  }

  buildEstudianteForm(){
    this.estudianteFormGroup= this.fb.group({
      primer_nombre: ['',[Validators.required]],
      otros_nombres: [''],
      apellido_paterno: [''],
      apellido_materno: [''],
      correo_electronico: ['',[Validators.required]],
      nro_ci: ['',[Validators.required]],
      compl_ci: [''],
      exped_ci: ['',[Validators.required]],
      password: [''],
      examenPreUniversitario_id:[,[Validators.required]],
      cod_user:[this.user.codigo_user],
      estado_validacion_correo_electronico:[1]
    })
  }

  registrarEstudiante(){
    let estudiante:any=this.estudianteFormGroup.value;
    delete estudiante.examenPreUniversitario_id;
    estudiante.password=estudiante.nro_ci;
    let examen_id=this.estudianteFormGroup.get('examenPreUniversitario_id').value;

    // console.log(estudiante);
    // console.log(examen_id);
    
    const registarUser=this.usersService.insertUser(estudiante).
      subscribe((res:any)=>{
        // console.log(res);
        let user_id=res.data.insertUser.id;
        let user_examen={} as UserExamen;
        user_examen.cod_user=this.user.codigo_user;
        user_examen.user_id=user_id;
        user_examen.examen_id=examen_id;
        const registrarUserExamen=this.users_examenesService.insertUserExamen(user_examen).
          subscribe((res:any)=>{
            // console.log(res);
            let user_rol={} as UserRol;
            user_rol.rol_id=3; //el id 3 es del rol estudiante
            user_rol.user_id=user_id;
            user_rol.cod_user=this.user.codigo_user;

            const registrarUserRol= this.users_rolesService.insertUserRol(user_rol).
              subscribe((res:any)=>{
                // console.log(res);
                 alert('Estudiante registrado correctamente.');
                  this.location.back();
              },
              err=>{
                // console.log(err);
              },
              ()=>{
                // console.log("completado registro user_rol"); 
                registrarUserRol.unsubscribe();
              }
            );
           
          },
          err=>{
            // console.log(err);
          },
          ()=>{
            // console.log("completado registro user_examen"); 
            registrarUserExamen.unsubscribe();
          }
          )
      },
      err=>{
        // console.log(err);
      },
      ()=>{
        // console.log("completado"); registarUser.unsubscribe();
      }
      )

  }

  departamentos_exped_ci: string[] = ['Chuquisaca', 'La Paz', 'Cochabamba', 'Oruro', 'Potosi', 'Tarija', 'Santa Cruz', 'Beni', 'Pando'];
}
