import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BancoPregunta } from '../@core/interfaces/banco-pregunta.interface';
import { ApiService } from '../@core/services/api.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { User } from 'firebase';

@Component({
  selector: 'app-form-add-question-bank',
  templateUrl: './form-add-question-bank.component.html',
  styleUrls: ['./form-add-question-bank.component.scss']
})
export class FormAddQuestionBankComponent implements OnInit {

  // @ViewChild('fform', { static: false }) QuestionBankFormDirective;
  questionBankForm: FormGroup;
  questionBank: BancoPregunta;

  constructor(
    private fb: FormBuilder, 
    private apiGraphql:ApiService,
    private route:Router,
    private location:Location
  ) { this.createForm() }

  createForm() {
    this.questionBankForm = this.fb.group({
      nombre: ['', [Validators.required,
      Validators.minLength(3)
      ]],
    });
    this.questionBankForm.valueChanges.subscribe(data => this.validar(data));
  }

  validar(data?: any) {
    if (!this.questionBankForm) { return; }
    const form = this.questionBankForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  formErrors = {
    'nombre': '',
  };
  validationMessages = {
    'nombre': {
      'required': 'El nombre es requerido'
      , 'minlength': 'El nombre debe tener al menos 3 carateres'
    }
  };

  ngOnInit(): void {
  }

  onSubmit() {
    let userLocalStorage=JSON.parse(localStorage.getItem("user")); 
    let codigo_user1=userLocalStorage.codigo_user ;
    // console.log(codigo_user1);

    this.questionBank=this.questionBankForm.value;
    // this.questionBank.cod_user=userLocalStorage.codigo_user;
    this.questionBank.cod_user=codigo_user1;

    // console.log(this.questionBank);
    this.apiGraphql.insert_question_bank(this.questionBank).subscribe((data)=>{
      // console.log(data);
    }
    );
    alert('Examen creado correctamente.');
    // this.route.navigate(['exams']);
    this.location.back();
    
  }

}
