import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalles-estudiante',
  templateUrl: './detalles-estudiante.component.html',
  styleUrls: ['./detalles-estudiante.component.scss']
})
export class DetallesEstudianteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
