
<div class="contenedor">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx" >
    <h2 *ngIf="bancoDePreguntas" class="mb-0">{{bancoDePreguntas.nombre}}</h2>
    <span class="example-spacer"></span>
    <button mat-raised-button color="primary" [routerLink]="['/home/form-add-question',idQuestionBank]"><i
        class="material-icons">add</i> Crear pregunta</button>
  </div>

  <mat-form-field>
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>

  <div class="mat-elevation-z1 div-tabla" >
    <table mat-table [dataSource]="dataSource" matSort [trackBy]="trackByUid" class="animate">
      <!-- Position Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <!-- <td mat-cell *matCellDef="let element"> {{moment(dateTimeLocal(element.created_at)).lang("es").format('YYYY/MM/DD HH:mm:ss')}} </td> -->
        <td mat-cell *matCellDef="let element"> {{opAux.fechaTimeZone(element.created_at)}} </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
        <!-- <td mat-cell *matCellDef="let element"> {{moment(dateTimeLocal(element.created_at)).lang("es").format('YYYY/MM/DD HH:mm:ss')}} </td> -->
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="texto_busqueda">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
        [style.display]="'none'"
        > Texto busqueda </th>
        <td mat-cell *matCellDef="let element"
        [style.display]="'none'"
        > {{element.texto_pregunta_respuestas}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="question_parts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pregunta </th>
        <td mat-cell *matCellDef="let element">

          <!-- {{element.status}}  -->

          <div fxLayout="row wrap " fxLayoutAlign="start center" class="mt-2 question">
            <div *ngFor="let questionPart of element.partes_pregunta">
              <ng-container *ngIf="questionPart.texto==''; else elseTemplate">
                <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':questionPart.imagen_height}"
                  src={{questionPart.imagen_url}} alt="">
              </ng-container>
              <ng-template #elseTemplate>
                <div class="mr-1" ng-i>
                  {{questionPart.texto}}
                </div>
              </ng-template>
            </div>
          </div>

          <div fxLayout="column" fxLayoutAlign=" start" class="m-1rem">
            <div *ngFor="let answerOption of element.opciones_respuesta; index as i" class="mb-1 divAnswerOption">
              <!-- <label class="mr-1">{{i+1+") "}}</label> -->
              <mat-checkbox class="example-margin" [disabled]="true" color="primary"
                [checked]="answerOption.opcion_correcta"></mat-checkbox>
              <label class="mr-1">{{answerOption.nro_orden+1+") "}}</label>

              <ng-container *ngIf="answerOption.texto==''; else elseTemplate">
                <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':answerOption.imagen_height}"
                  src={{answerOption.imagen_url}} alt="">
              </ng-container>
              <ng-template #elseTemplate>
                <div class="mr-1" ng-i>
                  {{answerOption.texto}}
                </div>
              </ng-template>
              <!-- <label *ngIf="answerOption.correct_option" class="ml-1"> Correcto</label> -->

            </div>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="opciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Opciones </th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="beforeMenu">
            <!-- <mat-icon>more_vert</mat-icon> -->
            Opciones
          </button>
          <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item [routerLink]="['/home/form-edit-question',element.id]"> <mat-icon color="primary">edit</mat-icon>Editar</button>
            <button mat-menu-item (click)="openDialog(element)" >
              <mat-icon color="warn">delete</mat-icon>
              <span>Eliminar</span>
            </button>
            <!-- <button mat-menu-item [routerLink]="['/list-of-questions',element.id]">Ver preguntas</button> -->
          </mat-menu>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
      </tr>
    </table>
    
  </div>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

</div>