import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { guardarOpcionRespuestaUser } from '../operations/opciones-respuestas-users.mutations';

@Injectable({
  providedIn: 'root'
})
export class OpcionesRespuestasUsersService {

  constructor(private apollo:Apollo) {}

  guardarOpcionRespuestaUser(opcion_multiple:boolean, valor_opcion_respuesta_user:boolean, opcion_respuesta_user_id:number){
    return this.apollo.mutate({
      mutation:guardarOpcionRespuestaUser,
      variables:{
        opcion_multiple:opcion_multiple,
        valor_opcion_respuesta_user:valor_opcion_respuesta_user,
        opcion_respuesta_user_id:opcion_respuesta_user_id
      }
    });
  }
  
}
