import gql from 'graphql-tag';

export const getPreguntasExameneGenerado = gql`
  query getPreguntasExameneGenerado($examen_generado_id: Float!) {
    getPreguntasExameneGenerado(examen_generado_id: $examen_generado_id) {
      id
      examen_generado_id
      examen_banco_pregunta_id
      examen_banco_pregunta {
        id
        nombre
        descripcion
        nro_preguntas
        porcentaje_calificacion
        duracion_minutos
        nro_orden
      }
      pregunta_id
      pregunta {
        opcion_multiple
        partes_pregunta {
          texto
          imagen_nombre
          imagen_url
          imagen_height
          nro_orden
          estado
        }
      }
      banco_pregunta_id
      respuesta_correcta
      estado
      created_at
      updated_at
      opciones_respuestas_users {
        id
        pregunta_examen_generado_id
        opcion_respuesta_id
        opcion_respuesta {
          texto
          imagen_nombre
          imagen_url
          imagen_height
          nro_orden
          opcion_correcta
        }
        valor_opcion
        estado
        created_at
        updated_at
        cod_user
      }
    }
  }
`;
