<div class="container">

  <div class="mt-1">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <h2 class="truncar_texto_nowrap">Lista de Exámenes</h2>
        <span class="example-spacer"></span>
        <button mat-raised-button color="primary" routerLink="/home/form-add-exam"> Crear Examen</button>
    </div>    
</div>

<div class="mat-elevation-z1">

    <table mat-table [dataSource]="dataSource" matSort [trackBy]="trackByUid" class="animate">
  
        <!-- Position Column -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
          <td mat-cell *matCellDef="let element"> {{opAux.fechaTimeZone(element.created_at)}} </td>
        </ng-container>
  
        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </th>
            <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
          </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="bancos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Bancos </th>
          <td mat-cell *matCellDef="let element">  </td>
        </ng-container>
  
        <ng-container matColumnDef="opciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Opciones </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button color="primary" (click)="openDialog(element)"> 
              <mat-icon>visibility</mat-icon> <span> Ver</span>
            </button>
            <button mat-icon-button color="primary"  [matMenuTriggerFor]="beforeMenu" >
              <mat-icon>more_vert</mat-icon>
            </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="deleteExam(element)" ><mat-icon color="warn" >delete</mat-icon> <span>Eliminar</span></button>
              </mat-menu>
          </td>
        </ng-container>
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <!-- <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
        </tr> -->
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>


</div>

</div>