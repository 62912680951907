<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx" class="mx-2" id="altura_header">
    <img src="assets/images/logoCCA2.png" id="altura_imagen">
    <h3 [fxHide.lt-md]="true" class="mb-0 ml-1 truncar_texto_nowrap" id="title"> EXÁMENES SIMULACROS </h3>
    <span class="example-spacer"></span>
    

    <button mat-icon-button [matMenuTriggerFor]="menu" >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button >
          <mat-icon>dialpad</mat-icon>
          <span>Redial</span>
        </button> -->
        <button mat-menu-item *ngIf="user" (click)="cerrarSesion()">
            <mat-icon>logout</mat-icon>
            <span>Cerrar sesión</span> 
        </button>
      </mat-menu>

</div>
<mat-divider></mat-divider>