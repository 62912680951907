import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UsersService
  ) { }

  canActivate() {
    // console.log(this.userService.getEstadoLogin());
    // route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.getEstadoLogin()=="t") {
      return true;
    }else{
      // return true;
      // console.log('No estás logueado');
      this.router.navigate(['login']);
      return false;
    }
  }


}
