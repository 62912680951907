import { PreguntasService } from './../@core/services/preguntas.service';
import { forkJoin } from 'rxjs';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../@core/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BancoPregunta } from '../@core/interfaces/banco-pregunta.interface';
import { Operaciones } from '../@core/utils/opetarions';

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Pregunta } from '../@core/interfaces/pregunta.interface';
import { EliminarPreguntaAlertDialogComponent } from '../eliminar-pregunta-alert-dialog/eliminar-pregunta-alert-dialog.component';
import { Subscription } from 'rxjs';

interface row {}
@Component({
  selector: 'app-list-of-questions',
  templateUrl: './list-of-questions.component.html',
  styleUrls: ['./list-of-questions.component.scss'],
})
export class ListOfQuestionsComponent implements OnInit, AfterViewInit  {
  moment: any = moment;
  questions: any[] = [];
  idQuestionBank: number;
  bancoDePreguntas: BancoPregunta;
  opAux = Operaciones;

  displayedColumns: string[] = ['created_at','id','texto_busqueda', 'question_parts', 'opciones'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  sb:Subscription;
  constructor(
    public router: Router,
    private apiGraphql: ApiService,
    private preguntasService: PreguntasService,
    private rutaActiva: ActivatedRoute,
    public matDialog: MatDialog
  ) {
    this.idQuestionBank = Number(
      this.rutaActiva.snapshot.params['id-question-bank']
    );
    this.getInfo();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getInfo() {
    forkJoin([
      this.apiGraphql.getBancoPregunta(this.idQuestionBank),
      this.preguntasService.getPreguntasByBancoDePreguntasId(
        this.idQuestionBank
      ),
    ]).subscribe(
      (res: any) => {
        // console.log(res);
        this.bancoDePreguntas = res[0].data.getBancoPregunta;
        this.questions = res[1].data.getPreguntasByBancoDePreguntasId; //question es una lista
        this.setDatosATabla(this.questions);
      },
      (err) => {
        // console.log(err);
      },
    )
    ;
  }

  setDatosATabla(preguntas:any[]){
    // preguntas.map(x => ({
    //     ...x,
    //     // c: Number(x.a) - Number(x.b)
    // }));
    var r = preguntas.map( (x:any) => {
      // x.c = Number(x.b) - Number(x.a);
      let texto_pregunta_respuestas='';
      for (const parte of x.partes_pregunta) {
        texto_pregunta_respuestas=texto_pregunta_respuestas+''+parte.texto;
      }
      for (const opcion of x.opciones_respuesta) {
        texto_pregunta_respuestas=texto_pregunta_respuestas+''+opcion.texto;
      }
      // let text_sin_espacios=texto_pregunta_respuestas.replace(/ /g, "");
      let res=Object.assign({}, x, {texto_pregunta_respuestas:texto_pregunta_respuestas});
      // console.log(res);
      return res
      })
    
    this.dataSource.data = r;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //averiguar para que funciona
  trackByUid(index, item) {
    return item.uid;
  }

  // dateTimeLocal(dateTime){
  //   return Operaciones.convertUTCDateToLocalDate(dateTime);
  // }

  openDialog(pregunta) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = pregunta;
    let dialogRef = this.matDialog.open(
      EliminarPreguntaAlertDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((res) => {
      // console.log('dialog envio: ' + res);
      if (res) {
        this.getInfo();
      }
    });
  }

  // openDialog(pregunta:Pregunta): void {
  //   const dialogRef = this.dialog.open(DialogConfirmEliminar, {
  //     width: '250px',
  //     data: pregunta
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //      let id:number = result;
  //   });
  // }
}

// @Component({
//   selector: 'dialog-confirm-eliminar',
//   templateUrl: 'dialog-confirm-eliminar.html',
//   styleUrls: ['./list-of-questions.component.scss']
// })
// export class DialogConfirmEliminar {

//   constructor(
//     public dialogRef: MatDialogRef<DialogConfirmEliminar>,
//     @Inject(MAT_DIALOG_DATA) public data: BancoPregunta) {}

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }
