

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
    <div class="header max_width" fxFlex="95%" fxFlex.xs="95%" >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
            <mat-icon>grid_view</mat-icon>
            <h2 class="mb-0 title nunito truncar_texto_nowrap">
                EXÁMENES SIMULACROS
            </h2>
            <!-- <span class="example-spacer"></span>
            <button mat-button>Crear cuenta</button>
            <button mat-button color="primary" >Iniciar sesión</button> -->
        </div>
    </div>     
</div>
  
  <form novalidate #fform="ngForm" [formGroup]="userForm" >
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" class="mt-3rem">


    <mat-card fxFlex="25rem" fxFlex.xs="95" class="mat-elevation-z2">

        

            <mat-card-content>
                <div class="card-login">

                    <div class="">
                        <a href="https://cca.edu.bo/" target="_blank">
                            <img src="assets/images/logoCCA2.png" width="100px">
                        </a>
                        
                    </div>

                    <h2 class="color1">Iniciar sesión</h2>
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Correo electrónico</mat-label>
                        <input placeholder="Correo electronico" matInput formControlName="correo_electronico" required>
                        
                        <mat-hint *ngIf="!emailInput.value">Ingrese su correo electrónico</mat-hint>
                        <mat-error *ngIf="formErrors.correo_electronico">
                            {{formErrors.correo_electronico}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Contraseña</mat-label>
                        <input matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'" formControlName="password" required>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint *ngIf="!passwordInput.value">Ingrese su contraseña</mat-hint>
                        <mat-error *ngIf="formErrors.password">
                            {{formErrors.password}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <a id="color_href_reset_pass" routerLink="/recuperar-cuenta">¿Has olvidado tu contraseña?</a>
                    
                    
                     
                    
                    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="gappx" class="mt-2">
                        <div *ngIf="cargando">
                            <p>Verificando...</p>
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                        
                        <p *ngIf="messageLogin!=''">{{messageLogin}}</p>
                    </div>
                
            </mat-card-content>
            
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx">
                <button mat-stroked-button type="button" color="primary" routerLink="/crear-cuenta-estudiante" >Crear cuenta</button>
                <span class="example-spacer"></span>
                <button mat-raised-button color="primary" (click)="login()" >Iniciar sesión</button>
            </div>
    </mat-card>
</div>
</form>

<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx" class="mt-4">
    <h2 class="mb-0" id="t1" >Estudia con nosotros</h2>
    <h3 class="mb-0" id="st1">Contamos con el apoyo de las siguientes instituciones.</h3>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="gappx">
    
        <a href="https://esam.edu.bo/" target="_blank">
            <img src="assets/images/logoEsam.png" alt="esam" class="img-inst" >
        </a>
        <a href="https://cca.edu.bo/" target="_blank">
            <img src="assets/images/logoCCA2.png" alt="cca" class="img-inst">
        </a>
        <a href="https://cybercorp.com.bo/" target="_blank">
            <img src="assets/images/logoCyberCorp.png" alt="cybercorp" class="img-inst">
        </a>
        <a href="https://ciberkids.cca.edu.bo/" target="_blank">
            <img src="assets/images/logoCiberkids.png" alt="ciberkids" class="img-inst">
        </a>
    
    </div>
</div>
