import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { insertOrUpdateUserRol } from '../operations/users-roles.mutations';
import { getUsersEstudiantes } from '../operations/users-roles.queries';

@Injectable({
  providedIn: 'root'
})
export class UsersRolesService {

  constructor(private apollo:Apollo) { }

  insertUserRol(user_rol){
    return this.apollo.mutate({
      mutation:insertOrUpdateUserRol,
      variables:{user_rolData:user_rol}
    })
  }

  getUsersEstudiantes(){
    return this.apollo.query({
      query:getUsersEstudiantes,
      fetchPolicy:'network-only'
    })
  }

}
