<mat-toolbar class="toolbar_home_estudiante">

    <img src="assets/images/logoCCA2.png" width="40px">
    <span class="ml-1 t_h_e_title truncar_texto_nowrap"> EXÁMENES SIMULACROS</span>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="0px">
    <button (click)="this.location.back()" mat-button matTooltip="Pagina anterior" matTooltipPosition="below" class="mr-1 silver ">
        <mat-icon> arrow_back</mat-icon> Atras
      </button>
</div>
<br>

<div class="container" *ngIf="user_examen">
    <div fxLayout="row" fxLayoutAlign="center start" >
        <mat-card fxFlex="70" fxFlex.xs="100">
            <mat-card-header>
                <mat-card-title>Historial de exámenes</mat-card-title>
                <mat-card-subtitle>{{user_examen.examen.nombre}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                 <div *ngFor="let examen_generado of user_examen.examenes_generados; let i_ex_gen=index">
                    <mat-divider></mat-divider>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="gappx" class="pt-1 pb-1">
                        <div fxFlex="25">
                            <!-- {{moment(dateTimeLocal(examen_generado.created_at)).format('DD/MM/YYYY HH:mm:ss')}} -->
                            {{opAux.fechaTimeZone(examen_generado.created_at)}}
                        </div>
                        <div fxFlex="25" class="pl-2">
                            {{examen_generado.nota_examen}}/100
                        </div>

                        <div *ngIf="examen_generado.nota_examen>=user_examen.examen.nota_aprobacion; else elseBlock" fxFlex="25" class=" color_success fw-500">
                            Aprobado
                        </div>
                        <ng-template #elseBlock>
                            <div fxFlex="25" class="color_warn">
                                Reprobado
                            </div>
                        </ng-template>

                        <ng-container *ngIf="examen_generado.nota_examen>=user_examen.examen.nota_admision; else elseTemplate">
                            <div fxFlex="25" class="color_success fw-500"> Admitido </div>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div fxFlex="25" class="color_warn"> No Admitido </div>
                        </ng-template>
                        
                    </div>
                 </div>



            </mat-card-content>
            <!-- <mat-card-actions>
                <button mat-button>Ok</button>
            </mat-card-actions> -->
        </mat-card>
    </div>
   

</div>
<br><br><br>