import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { deleteBancoPreguntaPorEstado } from '../operations/bancos_preguntas/bancos_preguntas_mutations';
import { getBancosPreguntas } from '../operations/bancos_preguntas/bancos_preguntas_queries';

@Injectable({
  providedIn: 'root'
})
export class BancosPreguntasService {

  constructor(private apollo: Apollo) { }

  deleteBancoPreguntaPorEstado(id:number){
    // console.log(id);
    return this.apollo.mutate({
      mutation:deleteBancoPreguntaPorEstado,
      variables:{
        id:id
      }
    });
  }

  getBancosPreguntas(){
    return this.apollo.query({
      query:getBancosPreguntas,
      fetchPolicy: 'network-only'
    }).pipe(map((result:any)=>{
      // console.log(result);
      return result.data.getBancosPreguntas;
    }))
  }
}
