import gql from 'graphql-tag';

export const insertOrUpdateUserRol=gql`
    mutation insertOrUpdateUserRol($user_rolData:UserRolInput!){
      insertOrUpdateUserRol( user_rolData: $user_rolData ){
        id,
        user_id,
        rol_id,
        estado,
        created_at,
        updated_at,
        cod_user
      }
    }
`;

export const restablecer_password=gql`
  mutation restablecer_password($correo_electronico:String!){
    restablecer_password(correo_electronico:$correo_electronico){
      id,
      primer_nombre,
      codigo_user,
      token_validacion_correo_electronico
    }
  }
`;
