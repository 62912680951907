import gql from 'graphql-tag';



export const getUsersEstudiantes=gql`

    query getUsersEstudiantes{
      getUsersEstudiantes{
        id,
        user_id,
        user{
          id,
          primer_nombre,
          otros_nombres,
          apellido_paterno,
          apellido_materno,
          codigo_user,
          correo_electronico,
          nro_ci,
          compl_ci,
          nro_celular,
          exped_ci,
          estado,
          created_at,
          updated_at,
        },
        rol_id,
        id,
        rol{
          id,
          nombre,
          cod_rol,
          estado,
          created_at,
          updated_at,      
        },
        estado,
        created_at,
        updated_at,
        cod_user
      }
    }

`; 