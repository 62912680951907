
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { title } from 'process';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { map } from 'rxjs/operators';
import { OpcionRespuesta } from '../interfaces/opcion-respuesta.interface';
import { PartePregunta } from '../interfaces/parte-pregunta.interface';
import { Pregunta } from '../interfaces/pregunta.interface';
import { insertOrUpdateBancoPregunta, insertOrUpdatePregunta, insertOrUpdatePartePregunta, insertOrUpdateOpcionRespuesta } from '../operations/mutation';

import { getBancoPregunta, getBancosPreguntas,getPregunta, listaPublicaciones, question_bank } from '../operations/query';
import { BancoPregunta } from '../interfaces/banco-pregunta.interface';
import { element } from 'protractor';
import { getBancosPreguntasCodigoUser } from '../operations/bancos_preguntas/bancos_preguntas_queries';




@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private apollo: Apollo) { }

  // //lista de publicaciones
  // getpublicaciones(){
  //   return this.apollo.watchQuery({
  //     query:listaPublicaciones,
  //     fetchPolicy: 'network-only'
  //   }).valueChanges.pipe(map((result:any)=>{
  //     console.log(result.data);
  //     return result.data.feed;
  //   }));
  // }
  // //add publicacion de prueba
  // addPublicacion(title:string, content:string, authorEmail:string){
  //   return this.apollo.mutate({
  //     mutation: addPublicacion,
  //     variables:{
  //       title,
  //       content,
  //       authorEmail
  //     }
  //   });
  // }

  insert_question_bank(banco_pregunta: BancoPregunta) {
    // console.log(banco_pregunta);
    return this.apollo.mutate({
      mutation: insertOrUpdateBancoPregunta,
      variables: {
        banco_preguntaData: banco_pregunta
      }
    });
  }

  update_question_bank(banco_pregunta: BancoPregunta) {
    // console.log(banco_pregunta);
    return this.apollo.mutate({
      mutation: insertOrUpdateBancoPregunta,
      variables: {
        banco_preguntaData: banco_pregunta
      }
    });
  }

  getBancosPreguntas() {
    let roles: string[] = JSON.parse(localStorage.getItem("roles"));
    // console.log('roles user: ' + roles);
    if (roles.find(element => element == "adm")) {
      // console.log("administrador");
      try {
        return this.apollo.query({
          query: getBancosPreguntas,
          fetchPolicy: 'network-only',
        }).pipe(map((result: any) => {
          // console.log(result.data.getBancosPreguntas);
          return result.data.getBancosPreguntas;
        }))
      } catch (error) {
        // console.log("Error getBancosPreguntas");
      }
    }
    if (roles.find(element => element == "prof")) {
      // console.log("profesor");
      let user = JSON.parse(localStorage.getItem("user"));
      // console.log(user);
      let codigo_user = user.codigo_user;
      // console.log(codigo_user);
      try {
        return this.apollo.query({
          query: getBancosPreguntasCodigoUser,
          variables: {
            codigo_user: codigo_user
          },
          fetchPolicy: 'network-only',
        }).pipe(map((result: any) => {
          // console.log(result.data.getBancosPreguntasCodigoUser);
          return result.data.getBancosPreguntasCodigoUser;
        }))
      } catch (error) {
        // console.log("Error getBancosPreguntasCodigoUser");
      }
    }

  }

  getBancoPregunta(id: number) {
    return this.apollo.query({
      query: getBancoPregunta,
      variables: { id },
      fetchPolicy: 'network-only'
    })
  }
  getPregunta(id: number) {
    return this.apollo.query({
      query: getPregunta,
      variables: { id },
      fetchPolicy: 'network-only'
    })
  }


  // edit_question_bank(id:number, name:string){
  //   console.log('datos para editar id: '+id+' name: '+name);
  //   return this.apollo.mutate({
  //     mutation: edit_question_bank,
  //     variables:{
  //       id,
  //       name
  //     }
  //   });
  // }

  create_complete_question(question: Pregunta, questionParts: PartePregunta[], answerOptions: OpcionRespuesta[]) {

    // console.log(question);
    let userLocalStorage=JSON.parse(localStorage.getItem("user")); 
    question.cod_user=userLocalStorage.codigo_user;
    return this.insert_pregunta(question).subscribe((result: any) => {
      // console.log(result);
      // console.log("crear pregunta completa"+result.data.insertOrUpdatePregunta.id);
      const id = result.data.insertOrUpdatePregunta.id;
      // console.log(id);

      questionParts.forEach((questionPart) => {
        questionPart.pregunta_id = id;
        questionPart.cod_user=userLocalStorage.codigo_user;
        // console.log(questionPart);
        this.insert_parte_pregunta(questionPart).subscribe((data: any) => {
          // console.log(data);
        })
      })

      answerOptions.forEach((answerOption) => {
        answerOption.pregunta_id = id;
        answerOption.cod_user=userLocalStorage.codigo_user;
        // console.log(answerOption);
        this.insert_opcion_respuesta(answerOption).subscribe((data: any) => {
          // console.log(data);
        })
      })
    })
  }

  insert_pregunta(question: Pregunta) {
    // const question_bank_id=question.question_bank_id;
    // const description=question.description;
    // const multiple_option=question.multiple_option;
    return this.apollo.mutate({
      mutation: insertOrUpdatePregunta,
      variables: {
        preguntaData: question
      }
    })
  }

  insert_parte_pregunta(partePregunta: PartePregunta) {
    // const question_id= questionId;
    // const text= question_part.text;
    // const image_name= question_part.image_name ;
    // const image_url= question_part.image_url ;
    // const image_height= question_part.image_height; 
    // const order_number= question_part.order_number;

    return this.apollo.mutate({
      mutation: insertOrUpdatePartePregunta,
      variables: {
        parte_preguntaData: partePregunta
      }
    })
  }

  insert_opcion_respuesta(opcionRespuesta: OpcionRespuesta) {
    // const question_id= questionId; 
    // const text= answer_option.text;
    // const image_name= answer_option.image_name; 
    // const image_url= answer_option.image_url;
    // const image_height= answer_option.image_height;
    // const order_number= answer_option.order_number;
    // const correct_option= answer_option.correct_option;

    return this.apollo.mutate({
      mutation: insertOrUpdateOpcionRespuesta,
      variables: {
        opcion_respuestaData: opcionRespuesta
      }
    })
  }





}
