
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersExamenesService } from 'src/app/@core/services/users-examenes.service';

@Component({
  selector: 'app-eliminar-examen-estudiante',
  templateUrl: './eliminar-examen-estudiante.component.html',
  styleUrls: ['./eliminar-examen-estudiante.component.scss']
})
export class EliminarExamenEstudianteComponent implements OnInit {

  row;
  index;
  confirmacion = false;
  eliminando = false;

  constructor(
    public dialogRef: MatDialogRef<EliminarExamenEstudianteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private users_examenesService:UsersExamenesService,
  ) {

    // console.log(data);
    this.row=data.row;
  }

  ngOnInit(): void {}

  eliminar(){
    this.eliminando=true;
    this.users_examenesService.deleteUserExamen(this.row.id).subscribe(
      (res:any)=>{
        this.eliminando=false;
        // console.log(res.data.deleteUserExamen);
        if(res.data.deleteUserExamen==true){
          // alert("Examen eliminado correctamente.");
          this.showSnackBar('Examen eliminado');
          this.dialogRef.close({eliminando:true});
        }else{
          // alert("Error! no se elimino el examen.");
          this.showSnackBar('Error, no se elimino el examen');
          this.dialogRef.close();
        }
      },err => {
        this.eliminando=false;
        // console.log(err);
        this.showSnackBar('Error, no se elimino el examen');
        this.dialogRef.close();
      }
    )
  }
  showSnackBar(message:string) {
    this.snackBar.open(message,null,{horizontalPosition:'right',verticalPosition:'bottom', duration: 3000 })
  }
}
