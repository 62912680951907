import gql from 'graphql-tag';

export const listaPublicaciones = gql`
query listaPublicaciones{
    feed {
      id
      title
      content
      published
      author {
        id
        name
        
      }
    }
  }
`;

export const getBancosPreguntas = gql`
  # query list_question_banks{
  #   list_question_banks{
  #     id
  #     name
  #     status
  #     created_at
  #     updated_at
  #   }
  # }

  query getBancosPreguntas{
  getBancosPreguntas{
    id,
    nombre,
    descripcion,
    estado,
    created_at,
    updated_at,
    cod_user,
    preguntas{
      id
    }
  }
}
`;
export const getPregunta = gql`
query getPregunta($id:Int!){
  getPregunta(id:$id){
    id,
    banco_pregunta_id,
    duracion,
    descripcion,
    opcion_multiple,
    estado,
    created_at,
    updated_at,
    cod_user,
    partes_pregunta{
      id,
      pregunta_id,
      texto,
      imagen_nombre,
      imagen_url,
      imagen_height,
      nro_orden,
      estado,
      created_at,
      updated_at,
      cod_user,        
    },
    opciones_respuesta{
      id,
      pregunta_id,
      texto,
      imagen_nombre,
      imagen_url,
      imagen_height,
      nro_orden,
      opcion_correcta,
      estado,
      created_at,
      updated_at,
      cod_user,        
    }
    
  }}
`;

export const getBancoPregunta = gql`
  query getBancoPregunta($id:Int!){
    getBancoPregunta(id:$id){
      id,
      nombre,
      descripcion,
      estado,
      created_at,
      updated_at,
      cod_user,   
      preguntas{
        id
        banco_pregunta_id,
        duracion,
        descripcion,
        opcion_multiple,
        estado,
        created_at,
        updated_at,
        cod_user,
        partes_pregunta{
          id,
          pregunta_id,
          texto,
          imagen_nombre,
          imagen_url,
          imagen_height,
          nro_orden,
          estado,
          created_at,
          updated_at,
          cod_user,        
        },
        opciones_respuesta{
          id,
          pregunta_id,
          texto,
          imagen_nombre,
          imagen_url,
          imagen_height,
          nro_orden,
          opcion_correcta,
          estado,
          created_at,
          updated_at,
          cod_user,        
        }
      }  
    }
  }
`;

export const question_bank = gql`
  query question_bank($id: Int!){
    question_bank(id:$id){
      id
      name
      status
      created_at
      updated_at
      question{
        id
        question_bank_id
        duration
        description
        multiple_option
        status
        created_at
        updated_at 
        question_part(orderBy:{order_number:asc}){
          id
          question_id
          text
          image_name
          image_url
          image_height
          status
          order_number
          created_at
          updated_at
        }
        answer_option(orderBy:{order_number:asc}){
          id
          question_id
          text
          image_name
          image_url
          image_height
          status
          order_number
          correct_option
          created_at
          updated_at
        }
      }
    }
  }
`;


