<h2 mat-dialog-title>{{examen.nombre}}</h2>
<mat-dialog-content class="mat-typography div_contenedor">

    <table>
        <tr>
            <td class="mr-1">Duración: </td>
            <td>{{examen.duracion_examen_minutos}} min.</td>
        </tr>
        <tr>
            <td class="mr-1">Nota aprobación: </td>
            <td>{{examen.nota_aprobacion}} </td>
        </tr>
        <tr>
            <td class="mr-1">Nota admisión: </td>
            <td>{{examen.nota_admision}} </td>
        </tr>
    </table>
    <br>


  <table >
    <thead>
        <tr>
            <th>Materia</th>
            <th>Banco de preguntas</th>
            <th>Nro. preguntas</th>
            <th>% calificación</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let materia of examen.examen_bancos_preguntas">
            <td>{{materia.nombre}}</td>
            <td>{{materia.banco_pregunta.nombre}}</td>
            <td>{{materia.nro_preguntas}}</td>
            <td>{{materia.porcentaje_calificacion}}</td>
        </tr>
    </tbody>
  </table>
  <!-- *ngFor="let item of items; let i=index" -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>