import gql from 'graphql-tag';

export const getBancosPreguntasCodigoUser = gql`
    query getBancosPreguntasCodigoUser($codigo_user: String!){
      getBancosPreguntasCodigoUser(
        codigo_user: $codigo_user
      ){
        id,
        nombre,
        descripcion,
        estado,
        created_at,
        updated_at,
        cod_user
      }
    }
    
`;

export const getBancosPreguntas = gql`
    query getBancosPreguntas{
      getBancosPreguntas{
        id,
        nombre,
        descripcion,
        estado,
        created_at,
        updated_at,
        cod_user
      }
    }
    
`;

