
import { MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { BancosPreguntasService } from '../@core/services/bancos-preguntas.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-eliminar-banco-preguntas-alert-dialog',
  templateUrl: './eliminar-banco-preguntas-alert-dialog.component.html',
  styleUrls: ['./eliminar-banco-preguntas-alert-dialog.component.scss']
})
export class EliminarBancoPreguntasAlertDialogComponent implements OnInit {

  confirmar:boolean=false;
  constructor(
    private bancos_preguntasService:BancosPreguntasService,
    public dialogRef: MatDialogRef<EliminarBancoPreguntasAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  eliminarBancoPreguntasPorEstado(id){
    this.bancos_preguntasService.deleteBancoPreguntaPorEstado(id).subscribe((data)=>{
      // console.log("banco de pregunta eliminado");
      // console.log(data);
      this.close();
    })
 
  }

  close() {
    this.dialogRef.close("hola");
    
  }

}
