
<div class="div_cabecera_dialog">
    <h2 class="color_warn">¿Esta seguro de eliminar este examen?</h2>
    <p class="color_warn">
        Esta acción borrará permanentemente los datos del examen.
    </p>
</div>
<div class="mt-2">
    <!-- <h4>Docente: {{row.nombre_completo}}</h4> -->
    
    <p>
        <mat-checkbox class="example-margin" [(ngModel)]="confirmacion"> Confirmar acción</mat-checkbox>
    </p>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="gappx">
        <button mat-raised-button color=warn [disabled]="confirmacion==false || eliminando "
            (click)="eliminar()">{{eliminando?'Eliminando examen...':'Eliminar examen'}}</button>
    </div>
</div> 