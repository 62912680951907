<!-- <p>form-add-exam works!</p> -->
<div class="container">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <h4 class="title-1 mt-1">Crear Examen Preuniversitario</h4>
    </div>

    <form [formGroup]="bancoPreguntaFormGroup" novalidate #fform="ngForm">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
            <mat-form-field appearance="outline" fxFlex="40" fxFlex.xs="100">
                <mat-label>Banco de preguntas</mat-label>
                <mat-select formControlName="bancoPreguntas" required>
                    <!-- <mat-option [value]=""></mat-option> -->
                    <mat-option *ngFor="let bancoPregunta of bancosPreguntas" [value]=bancoPregunta>
                        {{bancoPregunta.nombre}}
                    </mat-option>
                </mat-select>
                <mat-hint>Seleccion el banco de pregunta</mat-hint>
                <!-- <mat-error *ngIf="formErrors.pais_id_nacimiento">
                        {{formErrors.pais_id_nacimiento}}
                    </mat-error> -->
            </mat-form-field>
            <button mat-raised-button class="mt-2" type="button" (click)="agregarBancoDePreguntas()"
                [disabled]="bancoPreguntaFormGroup.invalid">Agregar
                banco de pregunta</button>
        </div>
    </form>


    <mat-divider class="mt-1">
    </mat-divider>


    <form [formGroup]="examenFormGroup" novalidate #fform="ngForm">

        <mat-card class="mt-1">
            <mat-card-header>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-card-title class="mr-2">Examen Preuniversitario</mat-card-title>
                    <mat-form-field appearance="outline" class="mt-1" fxFlex="15rem">
                        <mat-label>Nombre de Area preuniversitario</mat-label>
                        <input matInput type="text" formControlName="nombre" required>
                    </mat-form-field>
                </div>

            </mat-card-header>
            <mat-card-content>
                <mat-divider></mat-divider>

                <div *ngIf="examen_bancos_preguntas.length==0" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" class="pt-2">
                    <p>Agregue bancos de preguntas.</p>
                </div>
                <div formArrayName="examen_bancos_preguntas">
                    <br>
                    <div *ngFor="let item of examen_bancos_preguntas.controls ; let i=index" [formGroupName]="i">

                        <!-- <button (click)='removeInput(i)'>remove input</button> -->

                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px">
                            <mat-form-field>
                                <mat-label>Banco de preguntas</mat-label>
                                <input matInput type="text" formControlName="banco_pregunta_nombre">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Nombre</mat-label>
                                <input matInput type="text" formControlName="nombre">
                            </mat-form-field>
                            <mat-form-field fxFlex="8rem">
                                <mat-label>Nro. de preguntas</mat-label>
                                <input type="number" matInput formControlName="nro_preguntas">
                                <!-- <span matSuffix>min</span> -->
                            </mat-form-field>
                            <mat-form-field fxFlex="5rem">
                                <mat-label>Porcentaje</mat-label>
                                <input type="number" matInput formControlName="porcentaje_calificacion">
                                <span matSuffix>%</span>
                            </mat-form-field>
                            <!-- <mat-form-field fxFlex="6rem">
                                <mat-label>Duración</mat-label>
                                <input type="number" matInput formControlName="duracion_minutos">
                                <span matSuffix>min</span>
                            </mat-form-field> -->
                            <button class="ml-2" mat-button matTooltip="Eliminar" (click)="borrarExamenBancoPreguntas(i)">
                                <mat-icon color="warn">delete</mat-icon>
                            </button>
                        </div>


                    </div><br>

                </div>

                <mat-divider>
                </mat-divider>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="mt-1">
                    <mat-form-field  fxFlex="9rem" class="mr-2">
                        <mat-label>Porcentaje total</mat-label>
                        <input matInput type="number" [value]=porcentajeTotal disabled>
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="9rem" class="mr-2">
                        <mat-label>Duracion Total examen</mat-label>
                        <input matInput type="number" formControlName="duracion_examen_minutos">
                        <span matSuffix>min</span>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="9rem" class="mr-2">
                        <mat-label>Nota Aprobación</mat-label>
                        <input matInput type="number"  formControlName="nota_aprobacion">
                        <!-- <span matSuffix>min</span> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="9rem" class="mr-2">
                        <mat-label>Nota Admisión</mat-label>
                        <input matInput type="number"  formControlName="nota_admision" >
                        <!-- <span matSuffix>min</span> -->
                    </mat-form-field>
                
                </div>


            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" [disabled]="examenFormGroup.invalid || porcentajeTotal!=100" (click)="registrarExamen()">
                    <mat-icon class="mat-18">save</mat-icon> Guardar
                </button>
            </mat-card-actions>
        </mat-card>




    </form>



</div>