import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../@core/interfaces/user.interface';
import { UsersService } from '../@core/services/users.service';
import {Location} from '@angular/common';
import { map } from 'rxjs/operators';
import { UsersExamenesService } from '../@core/services/users-examenes.service';
import { ExamenesService } from '../@core/services/examenes.service';
import { UserExamen } from '../@core/interfaces/user-examen.interface';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EliminarExamenEstudianteComponent } from '../dialogs/eliminar-examen-estudiante/eliminar-examen-estudiante.component';

@Component({
  selector: 'app-edit-info-estudiante',
  templateUrl: './edit-info-estudiante.component.html',
  styleUrls: ['./edit-info-estudiante.component.scss']
})
export class EditInfoEstudianteComponent implements OnInit {

  cambiarPasswordFormGroup:FormGroup;
  examenFormGroup:FormGroup;
  // infoPersonalFormGroup
  userLocalStorage=JSON.parse(localStorage.getItem("user"));
  user;
  hide=false;
  passwordsIguales=true;
  passwordActualIncorrecto=false;
  examenes_user;
  examenes_preuniversitarios;


  estudianteFormGroup: FormGroup;

  actualizando_info_personal=false;
  eliminando_examen=false;
  agregando_examen=false;
  cambiando_contrasenia=false;

  constructor(
    private fb: FormBuilder,
    private apiUserGraphql:UsersService,
    private router: Router,
    public location:Location,
    private usersService:UsersService,
    private users_examenesService:UsersExamenesService,
    private examenesService:ExamenesService,
    private snackBar:MatSnackBar,
    private matDialog:MatDialog
  ) { 
    this.createForm();
    this.buildEstudianteForm();
    this.buildAddExamenForm();
   }
   buildEstudianteForm() {
    this.estudianteFormGroup = this.fb.group({
      primer_nombre: ['', [Validators.required,Validators.minLength(3)]],
      otros_nombres: [''],
      apellido_paterno: [''],
      apellido_materno: [''],
      correo_electronico: new FormControl({value:'',disabled: true}, [Validators.required, Validators.email], this.validarCorreoElectronico.bind(this)),
      nro_celular:new FormControl({value:'',disabled: true}, [Validators.required, Validators.minLength(8),Validators.maxLength(8)]),
      // nro_celular: ['',[Validators.required,Validators.minLength(8),Validators.maxLength(8)]],
      // compl_ci: [''],
      // exped_ci: ['',[Validators.required]],
      // password: ['', [Validators.required]],
      // confirmar_password: ['', [Validators.required]],
      // examenPreUniversitario_id: [, [Validators.required]],
      // visible_pass: [false]
    })
  }
  buildAddExamenForm(){
    this.examenFormGroup=this.fb.group({
      examen_preuniversitario_id:[, [Validators.required]]
    })
  }

  createForm(){
    this.cambiarPasswordFormGroup= this.fb.group({
      passwordActual:['',[Validators.required]],
      passwordNuevo:['',[Validators.required,Validators.minLength(8)]],
      passwordNuevoRepetido:['',[Validators.required ,Validators.minLength(8)]]
    });
    this.cambiarPasswordFormGroup.valueChanges.subscribe(data=>this.validar(data));
  };
  validar(data?: any) {
    if (!this.cambiarPasswordFormGroup) { return; }
    const form = this.cambiarPasswordFormGroup;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
  formErrors = {
    'passwordActual': '',
    'passwordNuevo': '',
    'passwordNuevoRepetido': '',
  };
  validationMessages = {
    'passwordActual': {
      'required': 'Este campo es requerido',
      // 'minlength':'Este campo debe contener al menos 8 caracteres',
    },
    'passwordNuevo': {
      'required': 'Este campo es requerido',
      'minlength':'Este campo debe contener al menos 8 caracteres',
    },
    'passwordNuevoRepetido': {
      'required': 'Este campo es requerido',
      'minlength':'Este campo debe contener al menos 8 caracteres',
    }
  };

  ngOnInit(): void {
   this.cargarInformacionUser();
   this.cargarExamenesUser();
   this.cargarExamenesPreuniversitarios();
  }
  cargarInformacionUser(){
      this.usersService.getUserCodigoUser(this.userLocalStorage.codigo_user).subscribe(
        (res:any)=>{
          // console.log(res.data.getUserCodigoUser);
          this.user=res.data.getUserCodigoUser;
          let user=res.data.getUserCodigoUser;
          this.estudianteFormGroup.controls['primer_nombre'].setValue(user.primer_nombre);
          this.estudianteFormGroup.controls['otros_nombres'].setValue(user.otros_nombres);
          this.estudianteFormGroup.controls['apellido_paterno'].setValue(user.apellido_paterno);
          this.estudianteFormGroup.controls['apellido_materno'].setValue(user.apellido_materno);
          this.estudianteFormGroup.controls['correo_electronico'].setValue(user.correo_electronico);
          this.estudianteFormGroup.controls['nro_celular'].setValue(user.nro_celular);
        }
      )
  }
  cambiarPassword(){
    this.passwordActualIncorrecto=false;
    let passwordsIguales= this.verificarPasswordsIguales();
    if(passwordsIguales){
      this.cambiando_contrasenia=true;
      const getUserCodigoUser=this.apiUserGraphql.getUserCodigoUser(this.userLocalStorage.codigo_user).
      subscribe((res:any)=>{
        this.cambiando_contrasenia=false;
        // console.log(res);
        let pasActual:string=this.cambiarPasswordFormGroup.get('passwordActual').value;
        var user1={} as User;
        // user1=res.data.getUserCodigoUser;
        user1.password=this.cambiarPasswordFormGroup.get('passwordNuevo').value;
        user1.id=res.data.getUserCodigoUser.id;
        if(pasActual==res.data.getUserCodigoUser.password){
          this.cambiando_contrasenia=true;
          // console.log("la contraseña actual es correcta");
          
          this.passwordActualIncorrecto=false;
          const updateUser=this.apiUserGraphql.updateUser(user1).
            subscribe((res:any)=>{
              this.cambiando_contrasenia=false;
              // console.log(res);
              this.showSnackBar('Contraseña modificada correctamente');
              // alert("Contraseña modificada correctamente");
              // this.location.back();
              this.apiUserGraphql.logout();
              this.router.navigate(['login']);
            },
            err=>{
              // console.log(err);
              this.cambiando_contrasenia=false;
            },
            ()=>{
              // console.log("completado update user"); 
              updateUser.unsubscribe();
             }
            )
        }
        else{
          // console.log("la contraseña actual es incorrecta")
          this.passwordActualIncorrecto=true;
          // console.log(this.passwordActualIncorrecto);
        }

      },
      err=>{
        // console.log(err);
        this.cambiando_contrasenia=false;
      },
      ()=>{
        // console.log("completado getUserCodigoUser"); 
        getUserCodigoUser.unsubscribe();
      }
      );
    }


  }

  verificarPasswordsIguales():boolean{
    this.passwordsIguales=true;
    let valuePasswordNuevo=this.cambiarPasswordFormGroup.get('passwordNuevo').value;
    let valuePasswordNuevoRepetido=this.cambiarPasswordFormGroup.get('passwordNuevoRepetido').value;
    // console.log(valuePasswordNuevo+" "+valuePasswordNuevoRepetido)
    if(valuePasswordNuevo==valuePasswordNuevoRepetido){
      this.passwordsIguales=true;
      return true;
    }else{
      this.passwordsIguales=false;
      return false;
    }
  }
  validarCorreoElectronico(control: AbstractControl) {
    return this.usersService.existeCorreoElectronico(control.value).pipe(map((res: any) => {
      // console.log(res.data.existeCorreoElectronico);
      return !res.data.existeCorreoElectronico ? null : { existe: true }
    }))
  }
  actualizarInformacion(){
    this.actualizando_info_personal=true;
    let user={}as User;
    user.id=this.user.id;
    user.primer_nombre=this.estudianteFormGroup.controls['primer_nombre'].value;
    user.otros_nombres=this.estudianteFormGroup.controls['otros_nombres'].value;
    user.apellido_paterno=this.estudianteFormGroup.controls['apellido_paterno'].value;
    user.apellido_materno=this.estudianteFormGroup.controls['apellido_materno'].value;
    user.correo_electronico=this.estudianteFormGroup.controls['correo_electronico'].value;
    user.nro_celular=this.estudianteFormGroup.controls['nro_celular'].value;

    this.usersService.updateUser(user).subscribe(
      (res:any)=>{
        this.actualizando_info_personal=false;
        // console.log(res);
        this.showSnackBar('Información personal actualizada');
        this.cargarInformacionUser();
      },
      err=>{
        this.actualizando_info_personal=false;
        this.showSnackBar('Error, no se pudo realizar la operación');
        // console.log(err);
      }
    )
  }

  cargarExamenesUser(){
    this.users_examenesService.getExamenesUser(this.userLocalStorage.codigo_user).subscribe(
      (res:any)=>{
        // console.log(res.data.getExamenesUser);
        this.examenes_user=[];
        this.examenes_user=res.data.getExamenesUser;
      }
    )
  }
  cargarExamenesPreuniversitarios(){
    this.examenesService.getExamenes().subscribe(
      (res:any)=>{
        // console.log(res.data.getExamenes);
        this.examenes_preuniversitarios=res.data.getExamenes;
      }
    )
  }
  insertarUserExamen(){
    this.agregando_examen=true;
    let user_examen={}as UserExamen;
    user_examen.user_id=this.user.id;
    user_examen.examen_id=this.examenFormGroup.controls['examen_preuniversitario_id'].value;
    this.users_examenesService.insertUserExamen(user_examen).subscribe(
      (res:any)=>{
        this.agregando_examen=false;
        // console.log(res.data.insertOrUpdateUserExamen);
        
        this.showSnackBar('Examen agregado correctamente');
        this.examenFormGroup.controls['examen_preuniversitario_id'].reset('');
        // console.log(this.examenFormGroup.controls['examen_preuniversitario_id'])
        this.cargarExamenesUser();
      },
      err=>{
        this.agregando_examen=false;
        this.showSnackBar('Error, no se pudo agregar el examen');
      }
    )
  }
  eliminarExamen(user_examen){
    // this.eliminando_examen=true;
    // this.users_examenesService.deleteUserExamen(user_examen_id).subscribe(
    //   (res:any)=>{
    //     this.eliminando_examen=false;
    //     console.log(res.data.deleteUserExamen);
    //     if(res.data.deleteUserExamen==true){
    
    //       this.showSnackBar('Examen eliminado');
    //       this.cargarExamenesUser();
    //     }else{
    
    //       this.showSnackBar('Error, no se elimino el examen');
    //     }
    //   },err => {
    //     this.eliminando_examen=false;
    //     console.log(err);
    //     this.showSnackBar('Error, no se elimino el examen');
    //   }
    // )

    
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.restoreFocus=false;
      dialogConfig.data={
        row: user_examen
      }
      let dialogRef = this.matDialog.open(
        EliminarExamenEstudianteComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.cargarExamenesUser();
        }
      });
    
  }

  showSnackBar(message:string) {
    this.snackBar.open(message,null,{horizontalPosition:'right',verticalPosition:'bottom', duration: 3000 })
  }


}
