import gql from 'graphql-tag';

export const getExamenesUser =gql`
query getExamenesUser($cod_user: String!){
  getExamenesUser(cod_user: $cod_user){
    id,
    user_id,
    examen_id,
    examen{
      id
      nombre,
      codigo,
      examen_bancos_preguntas{
        id
        nombre,
        nro_preguntas,
        porcentaje_calificacion,
        estado,        
      }
    },
    estado,
    created_at,
    updated_at,
    cod_user,
    examenes_generados{
      id,
      codigo_examen,
      duracion_examen_minutos,
      nota_examen,
      estado,
      nota_examen,
      estado,
      fecha_hora_inicio,
      fecha_hora_fin,
      examen_finalizado,
      created_at,
      updated_at,
      cod_user
    }
  } 
}

`;

export const getExamenUser =gql`
  query getUserExamen($id:Int!){
    getUserExamen(id:$id){
      id,
      user_id,
      examen{
        id
        nombre,
        descripcion,
        duracion_examen_minutos,
        nota_aprobacion,
        nota_admision,
        estado      
      }
      examenes_generados{
        id,
        duracion_examen_minutos,
        nota_examen,
        fecha_hora_inicio,
        fecha_hora_fin,
        examen_finalizado,
        created_at
      }
    }
  }
`;