<app-header-estudiante></app-header-estudiante>
<div class="container">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="0px">
        <button (click)="this.location.back()" mat-button matTooltip="Pagina anterior" matTooltipPosition="below" class="mr-1 silver ">
            <mat-icon> arrow_back</mat-icon> Atras
          </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
        <form novalidate #form="ngForm" [formGroup]="estudianteFormGroup" autocomplete="off">
            <mat-card fxFlex="30rem" fxFlex.lt-md="20rem" class="mat-elevation-z2 mb-2">
                <mat-card-header>
                    <mat-card-title>Información personal</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0rem" class="mt-3">
                    <mat-form-field appearance="" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1">
                        <mat-label>Nombres</mat-label>
                        <input matInput required formControlName="primer_nombre">
                    </mat-form-field>
                    <!-- <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                        <mat-label>Otros nombres</mat-label>
                        <input matInput formControlName="otros_nombres">
                    </mat-form-field> -->
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                    <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                        <mat-label>Apellido Paterno</mat-label>
                        <input matInput formControlName="apellido_paterno">
                    </mat-form-field>


                    <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                        <mat-label>Apellido Materno</mat-label>
                        <input matInput formControlName="apellido_materno">
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                    <mat-form-field appearance="" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1">
                        <mat-label>Correo electronico</mat-label>
                        <input matInput formControlName="correo_electronico" required>
                        <mat-icon matSuffix>alternate_email</mat-icon>
                        <mat-error *ngIf="this.estudianteFormGroup.get('correo_electronico').errors?.existe"> El correo
                            ya fue registrado </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                    <mat-form-field appearance="" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1">
                        <mat-label>Celular</mat-label>
                        <input matInput formControlName="nro_celular" required type="tel" autocomplete="new-nro_celular">
                        <mat-icon matSuffix>phone</mat-icon>
                    </mat-form-field>
                </div>





                <!-- <div *ngIf="mensaje_error" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" class="my-2">
                    {{mensaje_error}}
                </div>
                <div *ngIf="registrando_estudiante" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">
                    
                        <p>Procesando la solicitud...</p>
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    
                </div> -->

                <mat-card-actions align="end">
                    <button mat-raised-button color=primary (click)="actualizarInformacion() "
                        [disabled]="estudianteFormGroup.invalid || this.actualizando_info_personal">
                        <mat-icon>save</mat-icon>
                        {{this.actualizando_info_personal?'Guardando...':'Guardar'}}
                        </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
        <form novalidate #form="ngForm" [formGroup]="examenFormGroup" autocomplete="off">
            <mat-card fxFlex="30rem" fxFlex.lt-md="20rem" class="mat-elevation-z2 mb-2">
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx">
                    <h3 id="titulo_card">Información personal</h3>    
                </div> -->
                <mat-card-header>
                    <mat-card-title>Mis exámenes</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                <div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mt-1">
                        <mat-form-field appearance="outline" class="mx-1 mb--1 ">
                            <mat-label>Seleccionar examen</mat-label>
                            <mat-select required formControlName="examen_preuniversitario_id">
                                <mat-option *ngFor="let examen of examenes_preuniversitarios" [value]=examen.id>
                                    {{examen.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="mb-1" mat-raised-button color="accent" type="button" 
                            [disabled]="examenFormGroup.invalid ||this.agregando_examen"
                            (click)="insertarUserExamen()"
                            ><mat-icon>add</mat-icon> {{this.agregando_examen?'Agregando examen...':'Agregar examen'}}</button>
                    </div>
                </div>
                <mat-divider class="mb-0"></mat-divider>

                <div *ngFor="let examen_user of examenes_user" class="my-1">       
                    <div fxLayout="row " fxLayoutAlign="start center" >
                        <span class="truncar_texto_nowrap" fxFlex>{{examen_user.examen.nombre}}</span>
                        <!-- <span class="example-spacer"></span> -->
                        <button mat-stroked-button color="warn" type="button" (click)="eliminarExamen(examen_user)"
                        [disabled]="this.eliminando_examen"
                        >
                            <mat-icon>delete</mat-icon>
                            Eliminar</button>
                    </div>
                    
                </div>

                <mat-card-actions align="end">

                </mat-card-actions>
            </mat-card>
        </form>
    </div>



    <form novalidate #fform="ngForm" [formGroup]="cambiarPasswordFormGroup">
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-card fxFlex="30rem" fxFlex.lt-md="20rem" class="mat-elevation-z2">
                <mat-card-header>
                    <mat-card-title>Cambiar contraseña</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                <div>
                    
                    <div class="mt-1 " >
                        <mat-form-field fxFlex appearance="outline" >
                            <mat-label>Contraseña Actual</mat-label>
                            <input matInput type="password" formControlName="passwordActual" required>
                            <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                            <!-- <mat-hint *ngIf="!passwordInput.value">Ingrese su contraseña</mat-hint> -->
                            <mat-error *ngIf="formErrors.passwordActual">
                                {{formErrors.passwordActual}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field fxFlex appearance="outline">
                            <mat-label>Nueva Contraseña</mat-label>
                            <input matInput type="password" formControlName="passwordNuevo" required>
                            <mat-error *ngIf="formErrors.passwordNuevo">
                                {{formErrors.passwordNuevo}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field  fxFlex appearance="outline">
                            <mat-label>Repetir nueva contraseña</mat-label>
                            <input matInput type="password" formControlName="passwordNuevoRepetido" required>
                            <mat-error *ngIf="formErrors.passwordNuevoRepetido">
                                {{formErrors.passwordNuevoRepetido}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <p *ngIf="!passwordsIguales">Las contraseñas no son iguales</p>
                    <p *ngIf="passwordActualIncorrecto">La contraseña actual es incorrecta</p>
                </div>
                <mat-card-actions align="end">
                    <button mat-raised-button color="primary" type="button" (click)="cambiarPassword()"
                    [disabled]="cambiando_contrasenia|| cambiarPasswordFormGroup.invalid"
                    >
                    {{this.cambiando_contrasenia?'Cambiando contraseña...':'Cambiar contraseña'}}
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </form>


</div>
<br><br><br>