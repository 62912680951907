<h2 mat-dialog-title>Eliminar pregunta</h2>

<mat-dialog-content>
    <div fxLayout="row wrap " fxLayoutAlign="start center" class="mt-2 mb-2 question">
        <div *ngFor="let questionPart of data.partes_pregunta">
            <ng-container *ngIf="questionPart.texto==''; else elseTemplate">
                <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':questionPart.imagen_height}"
                    src={{questionPart.imagen_url}} alt="">
            </ng-container>
            <ng-template #elseTemplate>
                <div class="mr-1" ng-i>
                    {{questionPart.texto}}
                </div>
            </ng-template>
        </div>
        <span>...</span>
    </div>
    <p>
        <mat-checkbox [(ngModel)]="confirmar" color="primary">Confirmar</mat-checkbox>
    </p>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button  (click)="close()" cdkFocusInitial>Cancelar</button>
    <button mat-raised-button color="warn" (click)="eliminarPreguntaPorEstado(data.id)" [disabled]="confirmar==false">Eliminar</button>
</mat-dialog-actions>