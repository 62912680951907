import moment from "moment";

export class Operaciones{
 
    // static convertUTCDateToLocalDate(date_time:string) {
    //     let date=new Date(date_time);
    //     var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    
    //     var offset = date.getTimezoneOffset() / 60;
    //     var hours = date.getHours();
    
    //     newDate.setHours(hours - offset);
    
    //     return newDate;   
    //   }
    static fechaTimeZone(fecha){
      // let time_zone_offset=new Date(fecha).getTimezoneOffset();
      // let d=moment(fecha).subtract(time_zone_offset,'minutes');
      // return d.format('DD/MM/YYYY HH:mm:ss a');
      return moment(fecha).format('DD/MM/YYYY h:mm:ss a');
  }
}