<div fxLayout="row" fxLayoutAlign="center none">
    <div fxFlex="80" fxFlex.xs="100">
      <form novalidate #fform="ngForm" [formGroup]="questionBankForm" (ngSubmit)="onSubmit()">
        <mat-card class="shipping-card">
          <mat-card-header>
            <mat-card-title>Editar Banco de preguntas</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Nombre examen" formControlName="nombre" required>
                  <mat-error *ngIf="formErrors.nombre">
                    {{formErrors.nombre}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" type="submit" [disabled]="questionBankForm.invalid">Guardar cambios</button>
          </mat-card-actions>
        </mat-card>
      </form>
  
    </div>
  </div>