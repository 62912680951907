import { Component,OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UsersService } from '../@core/services/users.service';
import { Router } from '@angular/router';
import { userInfo } from 'os';
import { User } from '../@core/interfaces/user.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  roles:string[]=JSON.parse(localStorage.getItem('roles'));
  

  user=JSON.parse(localStorage.getItem("user")); 
 
  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService:UsersService,
    private route:Router
    ) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // console.log(this.user);
  }
  cerrarSesion(){
    this.userService.logout();
    this.route.navigateByUrl('login');
  }
  tieneAcceso(){
    if(this.roles.includes('adm')){
      return true;
    }
    else{
      return false;
    }
  }

}
