<div class="nunito print">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
        <div class="header" fxFlex.gt-md="1200px" fxFlex.gt-sm="90%" fxFlex.xs="100%">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="6px">
                <mat-icon>grid_view</mat-icon>
                <h2 class="mb-0 title truncar_texto_nowrap">
                    EXÁMENES SIMULACROS
                </h2>
                <span class="example-spacer"></span>
                <button mat-button fxHide.xs [routerLink]="['/crear-cuenta-estudiante']">Crear cuenta</button>
                <button mat-button color="info" fxHide.xs [routerLink]="['/login']">Iniciar sesión</button>
            </div>
        </div>
    </div>

    <div class="carousel-container">
        <div class="carousel" [ngStyle]="{'transform': 'translateX(-' + (currentSlide * 100) + '%)'}">
            <div class="carousel-item" *ngFor="let item of carouselItems"
                [ngStyle]="{'background-image': 'url(/assets/expocruz/ilustrator/fondo.webp)'}">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
                    <div fxFlex.gt-md="1200px" fxFlex.gt-sm="90%" fxFlex.xs="90%">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
                            <div fxFlex="50%" fxFlex.xs="100%">
                                <div class="sl">
                                    <span class="admission">Asegura tu admisión</span> 
                                    <span class="item-title animated-title">{{item.title}}</span>
                                </div>
                                
                                
                                <div class="b animate__animated mb-4">
                                    {{item.description}}
                                </div>
                                <div class="pt-2 mb-4">
                                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="6px" 
                                         fxLayoutAlign.xs="center center"> <!-- Centro para pantallas pequeñas -->
                                        <button class="btn1 custom-btn btn-3" [routerLink]="['/login']">
                                            <span>Iniciar sesión</span>
                                        </button>
                                        <button mat-stroked-button class="btn1" [routerLink]="['/crear-cuenta-estudiante']">
                                            Crear cuenta
                                        </button>
                                    </div>
                                </div>
                                


                            </div>
                            <div fxFlex="50%" fxFlex.xs="95%">
                                <figure class="w-100">
                                    <img [src]="item.image" alt="carousel item">
                                </figure>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <button class="carousel-control prev" (click)="prevSlide()">&#10094;</button>
        <button class="carousel-control next" (click)="nextSlide()">&#10095;</button>
    </div>

    <div class="section tabbar_menu">
        <div class="container" fxLayout="row" fxLayoutAlign="center center">
            <div class="row" fxLayout="row wrap" fxLayoutAlign="center center">
                <div class="card-bar" fxFlex="22%" fxFlex.md="45%" fxFlex.sm="100%" fxLayout="column"
                    fxLayoutAlign="center center">
                    <span class="icon">
                        <img src="/assets/images/logo_usfx_.webp" alt="ESFM" width="50" />
                    </span>
                    <p>U.S.F.X.</p>
                </div>
                <div class="card-bar" fxFlex="22%" fxFlex.md="45%" fxFlex.sm="100%" fxLayout="column"
                    fxLayoutAlign="center center">
                    <span class="icon">
                        <img src="/assets/images/logo_mariscal.webp" alt="U.S.F.X." width="70" />
                    </span>
                    <p>ESFM</p>
                </div>
                <div class="card-bar" fxFlex="22%" fxFlex.md="45%" fxFlex.sm="100%" fxLayout="column"
                    fxLayoutAlign="center center">
                    <span class="icon">
                        <img src="/assets/images/logo_fatescipol.webp" alt="FATESCIPOL" width="50" />
                    </span>
                    <p>FATESCIPOL</p>
                </div>
            </div>

        </div>
    </div>
   <div class="section layout-padding">
  <div class="container-cards">
    <div fxLayout="row" fxLayoutAlign="center center" class="heading-container">
      <div class="heading-main">
        <h2><span>¿Por qué escogernos?</span></h2>
      </div>
      
    </div>
    <p class="s2_card_t2">Explora algunas de nuestras características principales.</p>

    <div class="s2">
      <div class="card-container">
        <div class="card">
          <img src="assets/expocruz/ilustrator/1-card.webp" class="img_card" alt="Illustration" />
          <div class="card-overlay">
            <h3 class="s2_card_t1">Facilidad de uso</h3>
            <p class="s2_card_t2">Nuestro sistema de exámenes es bastante fácil de utilizar e intuitivo.</p>
          </div>
        </div>
      </div>
      <div class="card-container">
        <div class="card">
          <img src="assets/expocruz/ilustrator/2-card.webp" class="img_card" alt="Illustration" />
          <div class="card-overlay">
            <h3 class="s2_card_t1">Calificación automática</h3>
            <p class="s2_card_t2">Los resultados se califican automáticamente al finalizar el examen.</p>
          </div>
        </div>
      </div>
      <div class="card-container">
        <div class="card">
          <img src="assets/expocruz/ilustrator/3-card.webp" class="img_card" alt="Illustration" />
          <div class="card-overlay">
            <h3 class="s2_card_t1">Historial de resultados</h3>
            <p class="s2_card_t2">Consulta el historial de tus exámenes y resultados fácilmente.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      
      



    <div class="parallax-section">
        <div class="parallax-content">
            <h4 class="s2_t2">"La elección de los que quieren ser grandes"</h4>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx" class="mt-4">
        <div class="heading-main">
            <h2 class="pt-4"><span>Estudia con nosotros</span></h2>
          </div>
        <h3 class="mb-0" id="st1">Contamos con el apoyo de las siguientes instituciones.</h3>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="gappx" class="logos-container">
    
            <a href="https://esam.edu.bo/" target="_blank">
                <img src="assets/images/logoEsam.webp" alt="esam" class="img-inst">
            </a>
           
            <a href="https://cybercorp.com.bo/" target="_blank">
                <img src="assets/images/logoCyberCorp.webp" alt="cybercorp" class="img-inst">
            </a>
            <a href="https://cca.edu.bo/" target="_blank">
                <img src="assets/images/logoCCA2.webp" alt="cca" class="img-inst">
            </a>
            <a href="https://ciberkids.cca.edu.bo/" target="_blank">
                <img src="assets/images/logoCiberkids.webp" alt="ciberkids" class="img-inst">
            </a>
            <a href="https://ciberkids.cca.edu.bo/" target="_blank">
                <img src="assets/images/logo_newcenter.webp" alt="newcenter" class="img-inst">
            </a>
    
        </div>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0spx" class="footer">
        <div fxFlex.gt-md="1200px" fxFlex.gt-sm="90%" fxFlex.xs="90%">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                <p class="mb-0" style="text-align: center;">
                    © 2024 CCA Todos los derechos reservados
                </p>
            </div>
        </div>
    </div>
    

    <!-- <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary">
        <div class="container">
            <div class="row mt-5">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="assets/expocruz/ilustrator/illustration-5.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration" />
                            </div>
                            <h5 class="h4 lh-130 mb-3">Unleash you creativity</h5>
                            <p class="text-muted mb-0">Quick Website UI Kit (FREE) contains components and pages that are easy to customize and change.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="assets/expocruz/ilustrator/illustration-6.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration" />
                            </div>
                            <h5 class="h4 lh-130 mb-3">Get more results</h5>
                            <p class="text-muted mb-0">Quick Website UI Kit (FREE) contains components and pages that are easy to customize and change.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="assets/i/ilustrator/illustration-7.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration" />
                            </div>
                            <h5 class="h4 lh-130 mb-3">Increase your audience</h5>
                            <p class="text-muted mb-0">Quick Website UI Kit (FREE) contains components and pages that are easy to customize and change.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

</div>