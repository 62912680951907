import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreguntasService } from '../@core/services/preguntas.service';

@Component({
  selector: 'app-eliminar-pregunta-alert-dialog',
  templateUrl: './eliminar-pregunta-alert-dialog.component.html',
  styleUrls: ['./eliminar-pregunta-alert-dialog.component.scss']
})
export class EliminarPreguntaAlertDialogComponent implements OnInit {

  confirmar:boolean=false;
  constructor( 
    public dialogRef: MatDialogRef<EliminarPreguntaAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private preguntasService:PreguntasService
    ){}

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close({eliminado:true});
  }
  eliminarPreguntaPorEstado(id){
    this.preguntasService.deletePreguntaPorEstado(id).subscribe((data)=>{
      // console.log(data);
      this.close();
    });
    
  }

}
