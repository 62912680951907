

  
  <div class=" contenedor">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
        <h2 class="mb-0">Bancos de preguntas</h2>
        <span class="example-spacer"></span>
        <button mat-raised-button color="primary" routerLink="/home/form-add-question-bank"><i class="material-icons">add</i><span> Crear Banco de preguntas</span></button>
    </div>
    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder=" " #input>
    </mat-form-field>
  
    <div class="mat-elevation-z1 div-tabla ">
      <table mat-table [dataSource]="dataSource" matSort [trackBy]="trackByUid" class="animate">
  
        <!-- Position Column -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
          <!-- <td mat-cell *matCellDef="let element"> {{moment(element.createDate).fromNow()}} </td> -->
          <!-- <td mat-cell *matCellDef="let element"> {{moment(element.created_at).fromNow()}} </td> -->
          <!-- <td mat-cell *matCellDef="let element"> {{moment(dateTimeLocal(element.created_at)).format('YYYY/MM/DD HH:mm:ss')}} </td> -->
          <td mat-cell *matCellDef="let element"> {{opAux.fechaTimeZone(element.created_at)}} </td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="nro_preguntas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. preguntas </th>
          <td mat-cell *matCellDef="let element"> {{element.preguntas.length}} </td>
        </ng-container>
  
        <ng-container matColumnDef="opciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Opciones </th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button [routerLink]="['/home/list-of-questions',element.id]" class="mr-1" >Ver Preguntas</button> 
            
              <button mat-icon-button color="primary"  [matMenuTriggerFor]="beforeMenu" >
                <mat-icon>more_vert</mat-icon>
              </button>
            
            
            <mat-menu #beforeMenu="matMenu" xPosition="before">
              <button mat-menu-item [routerLink]="['/home/form-edit-question-bank',element.id]"> <mat-icon color="primary">create</mat-icon> <span>Editar</span></button>
              <button mat-menu-item (click)="openDialog(element)"><mat-icon color="warn">delete</mat-icon> <span>Eliminar</span></button>
            </mat-menu>
          </td>
        </ng-container>
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
        </tr>
      </table>

    </div>
    <div>
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
    
  
  </div>