import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-edit-exam',
  templateUrl: './form-edit-exam.component.html',
  styleUrls: ['./form-edit-exam.component.scss']
})
export class FormEditExamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
