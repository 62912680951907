

<app-header-estudiante></app-header-estudiante>
<div class="container">
    <form novalidate #fform="ngForm" [formGroup]="userFormGroup" autocomplete="off">
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-card fxFlex="30rem" fxFlex.lt-sm="100%">
                <mat-card-header>
                    <mat-card-title>Cambiar contraseña</mat-card-title>
                    <!-- <mat-card-subtitle>{{user.prime}}</mat-card-subtitle> -->
                </mat-card-header>
                <div *ngIf="verificando_token">
                    <div fxLayout="column" fxLayoutAlign="center center" class="my-2">
                        <h3>
                            Verificando token...
                        </h3>
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                </div>
                <div *ngIf="!verificando_token">
                    <div *ngIf="token_correcto">
                        <mat-card-content>
                            <mat-form-field  fxFlexFill>
                                <mat-label>Contraseña</mat-label>
                                <input matInput  type="password"
                                    formControlName="password" required>
                                    <mat-error *ngIf="formErrors.password">
                                        {{formErrors.password}}
                                    </mat-error>
                            </mat-form-field>
                            <mat-form-field  fxFlexFill>
                                <mat-label>Repetir contraseña</mat-label>
                                <input matInput  type="password"
                                    formControlName="password_repetido" required>
                                    <mat-error *ngIf="formErrors.password_repetido">
                                        {{formErrors.password_repetido}}
                                    </mat-error>
                            </mat-form-field>
                            <p *ngIf="passwords_iguales==false">Las contraseñas no son iguales</p>
                            <div *ngIf="restableciendo_password" fxLayout="column" fxLayoutAlign="center center" >
                                <h3>Procesando operación...</h3>
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end" *ngIf="password_restablecida!=true" >
                            <button mat-stroked-button (click)="restablecer_password()" [disabled]="restableciendo_password">Cambiar contraseña</button>
                        </mat-card-actions>
                        <div *ngIf="password_restablecida==true" fxLayout="column" fxLayoutAlign="center center" >
                            <p class="fw-500">Contraseña restablecida correctamente.<span><mat-icon class="color_success">done_outline</mat-icon></span></p>
                            <button mat-raised-button color="primary" (click)="irAHome()" >Ir a la página principal</button>
                        </div>
                    </div>
                    <div *ngIf="!token_correcto" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                        <h3 class="">El token es invalido o ya expiró.</h3>
                    </div>
                </div>

          
            </mat-card>
        </div>
    </form>


</div>