import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../@core/services/users.service';

@Component({
  selector: 'app-verificacion-correo',
  templateUrl: './verificacion-correo.component.html',
  styleUrls: ['./verificacion-correo.component.scss']
})
export class VerificacionCorreoComponent implements OnInit {

  verificando_token=false;
  token="";
  token_verificado_correctamente=false;
  constructor(
    private activatedRoute:ActivatedRoute,
    private usersService:UsersService,
    private router:Router
  ) { 
    this.token=this.activatedRoute.snapshot.params['token'].toString();
  }

  ngOnInit(): void {
    this.verificarToken(this.token);

  }
  verificarToken(token){
    localStorage.clear();
    this.verificando_token=true;
    this.usersService.confirmacionTokenCorreoElectronico(token)
      .subscribe((res:any)=>{
        // console.log(res.data.confirmacionTokenCorreoElectronico);
        if(res.data.confirmacionTokenCorreoElectronico===true){
          this.token_verificado_correctamente=true;
          
        }else{
          this.token_verificado_correctamente=false;
        }
        this.verificando_token=false;
      },
      err=>{
        this.verificando_token=false;
      },
      ()=>{}
      )
  }
  ir_a_home_estudiante(){
    this.router.navigateByUrl('home-estudiante');
  }

}
