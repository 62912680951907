import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BancoPregunta } from '../@core/interfaces/banco-pregunta.interface';
import { ApiService } from '../@core/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Operaciones } from '../@core/utils/opetarions';
import { EliminarBancoPreguntasAlertDialogComponent } from '../eliminar-banco-preguntas-alert-dialog/eliminar-banco-preguntas-alert-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

interface row{
  id: number;
  fecha: string;
  nombre: string;
}

@Component({
  selector: 'app-list-of-question-banks',
  templateUrl: './list-of-question-banks.component.html',
  styleUrls: ['./list-of-question-banks.component.scss']
})
export class ListOfQuestionBanksComponent implements AfterViewInit {
  moment: any = moment;
  questionBanks:any[]=[];
  opAux=Operaciones;

  displayedColumns: string[] = [ 'created_at','nombre','nro_preguntas','opciones'];
  dataSource= new MatTableDataSource<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public router:Router,
    private apiGraphql: ApiService,
    public matDialog: MatDialog
  ){}


  ngAfterViewInit() {
     this.apiGraphql.getBancosPreguntas().subscribe((data)=>{
      //  console.log(data);
      //  let datosTabla:[];
      //  for (const elem of data) {
      //    let row={} as row;
      //    row.id=elem.id;
      //    row.nombre=nombre
      //  }
       this.questionBanks=data;
      //  console.log("esto a la tabla:"+this.questionBanks);
       this.dataSource.data= this.questionBanks;
       this.dataSource.paginator=this.paginator;
       this.dataSource.sort=this.sort;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //averiguar para que funciona
  trackByUid(index, item) {
    return item.uid;
  }

  // dateTimeLocal(dateTime){
  //   return Operaciones.convertUTCDateToLocalDate(dateTime);
  // }
  
  // convertUTCDateToLocalDate(date_time:string) {
  //   let date=new Date(date_time);
  //   var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  //   var offset = date.getTimezoneOffset() / 60;
  //   var hours = date.getHours();

  //   newDate.setHours(hours - offset);

  //   return newDate;   
  // }

  openDialog(banco_pregunta) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data=banco_pregunta;
    let dialogRef=this.matDialog.open(EliminarBancoPreguntasAlertDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( value=>{
      // console.log(`Dialog envio: ${value}`);
      window.location.reload();
    });
  }

}
