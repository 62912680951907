import gql from 'graphql-tag';

// export const addPublicacion = gql`
//     mutation createDraft($title:String!,$content:String!, $authorEmail:String!) {
//       createDraft(
//         title: $title
//         content: $content
//         authorEmail: $authorEmail
//       ) {
//         id
//         published
//         authorId
//       }
//     }
// `;

// export const create_question_bank = gql`
//   mutation create_question_bank($name:String!){
//     create_question_bank(name:$name){
//       name
//     }
//   }
// `;

export const insertOrUpdateBancoPregunta = gql`
  mutation insertOrUpdateBancoPregunta($banco_preguntaData:BancoPreguntaInput!){
    insertOrUpdateBancoPregunta(
      banco_preguntaData:$banco_preguntaData
    ){
      id,
      nombre,
      descripcion,
      estado,
      created_at,
      updated_at,
      cod_user
    }
  }
`;

export const insertOrUpdatePregunta= gql `
  mutation insertOrUpdatePregunta($preguntaData:PreguntaInput!){
    insertOrUpdatePregunta( preguntaData: $preguntaData ){
      id
      banco_pregunta_id,
      duracion,
      descripcion,
      opcion_multiple,
      estado,
      created_at,
      updated_at,
      cod_user
    }
  }
`;

export const insertOrUpdatePartePregunta= gql `
  mutation insertOrUpdatePartePregunta( $parte_preguntaData: PartePreguntaInput!){
    insertOrUpdatePartePregunta( parte_preguntaData: $parte_preguntaData){
      id,
      pregunta_id,
      texto,
      imagen_nombre,
      imagen_url,
      imagen_height,
      nro_orden,
      estado,
      created_at,
      updated_at,
      cod_user
    }
  }
`;

export const insertOrUpdateOpcionRespuesta = gql `
  mutation insertOrUpdateOpcionRespuesta($opcion_respuestaData: OpcionRespuestaInput!){
    insertOrUpdateOpcionRespuesta( opcion_respuestaData: $opcion_respuestaData ){
      id,
      pregunta_id,
      texto,
      imagen_nombre,
      imagen_url,
      imagen_height,
      nro_orden,
      opcion_correcta,
      estado,
      created_at,
      updated_at,
      cod_user
      
    }
  }
`;

// export const edit_question_bank = gql`
//   mutation edit_question_bank($id:Int!, $name: String!){
//     edit_question_bank(
//       id:$id
//       name:$name
//     ){
//       id
//       name
//       status
//       created_at
//       updated_at
//     }
//   }
// `;

export const create_question = gql`
mutation create_question($question_bank_id:Int!, $description: String, $multiple_option: Boolean){
  create_question(
    question_bank_id: $question_bank_id
    description: $description
    multiple_option: $multiple_option
  ){
    id
    question_bank_id
    description
    multiple_option
    status
    created_at
    updated_at
  }
}
`

export const create_question_part = gql`
  mutation create_question_part($question_id:Int!, $text:String, $image_name: String, $image_url:String, $image_height:Int, $order_number: Int){
    create_question_part(
      question_id: $question_id,
      text:$text,
      image_name: $image_name,
      image_url: $image_url,
      image_height: $image_height,
      order_number: $order_number
    ){
      id
      question_id
      text
      image_name
      image_url
      image_height
      status
      order_number
      created_at
      updated_at
    }
  }
`

export const create_answer_option = gql`
  mutation create_answer_option($question_id:Int!, $text:String, $image_name: String, $image_url:String, $image_height:Int, $order_number:Int, $correct_option:Boolean){
    create_answer_option(
      question_id: $question_id,
      text: $text,
      image_name: $image_name,
      image_url: $image_url,
      image_height: $image_height,
      order_number: $order_number,
      correct_option: $correct_option
    ){
      id,
      question_id,
      text,
      image_name,
      image_url,
      image_height,
      status,
      order_number,
      correct_option,
      created_at,
      created_at
    }
  }
`

