import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BancoPregunta } from '../@core/interfaces/banco-pregunta.interface';
import { ApiService } from '../@core/services/api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-form-edit-question-bank',
  templateUrl: './form-edit-question-bank.component.html',
  styleUrls: ['./form-edit-question-bank.component.scss']
})
export class FormEditQuestionBankComponent implements OnInit {

  // @ViewChild('fform', { static: false }) ExamFormDirective;
  questionBankForm: FormGroup;
  questionBank: BancoPregunta;

  idQuestionBank:number;

  constructor(private fb: FormBuilder, 
              private apiGraphql:ApiService,
              private route:Router,
              private location:Location,
              private rutaActiva: ActivatedRoute,
              ) { this.createForm() }
  ngOnInit(){
    this.idQuestionBank=Number(this.rutaActiva.snapshot.params['id']);
    console.log("id del banco de pregunta: "+ this.idQuestionBank);
    this.apiGraphql.getBancoPregunta(Number(this.idQuestionBank)).subscribe((res:any)=>{ 
      console.log(res);
      this.questionBank=res.data.getBancoPregunta;
      console.log('nombre exam: '+this.questionBank.nombre);
      this.questionBankForm.controls['nombre'].setValue(this.questionBank.nombre);
    });
  }

  createForm() {
    this.questionBankForm = this.fb.group({
      nombre: ['', [Validators.required,
      Validators.minLength(3)
      ]],
    });
    this.questionBankForm.valueChanges.subscribe(data => this.validar(data));
  }

  validar(data?: any) {
    if (!this.questionBankForm) { return; }
    const form = this.questionBankForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  formErrors = {
    'nombre': '',
  };
  validationMessages = {
    'nombre': {
      'required': 'El nombre es requerido'
      , 'minlength': 'El nombre debe tener al menos 3 carateres'
    }
  };

  
  onSubmit() {
    this.questionBank=this.questionBankForm.value;
    this.questionBank.id=this.idQuestionBank;
    this.apiGraphql.update_question_bank(this.questionBank).subscribe((data)=>
     {
      //  console.log(data);
      }
    );
    alert('Banco de preguntas editado correctamente.');
    this.location.back();
  }

}
