import gql from 'graphql-tag';

export const getPreguntasByBancoDePreguntasId = gql`
  query getPreguntasByBancoDePreguntasId($banco_pregunta_id: Int!) {
    getPreguntasByBancoDePreguntasId(banco_pregunta_id: $banco_pregunta_id) {
      id
      banco_pregunta_id
      duracion
      descripcion
      opcion_multiple
      estado
      created_at
      updated_at
      cod_user
      partes_pregunta {
        id
        pregunta_id
        texto
        imagen_nombre
        imagen_url
        imagen_height
        nro_orden
        estado
        created_at
        updated_at
        cod_user
      }
      opciones_respuesta {
        id
        pregunta_id
        texto
        imagen_nombre
        imagen_url
        imagen_height
        nro_orden
        opcion_correcta
        estado
        created_at
        updated_at
        cod_user
      }
    }
  }
`;
