import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ExamenesGeneradosService } from '../@core/services/examenes-generados.service';
import { OpcionesRespuestasUsersService } from '../@core/services/opciones-respuestas-users.service';
import {
  MatSnackBar
} from '@angular/material/snack-bar';
import { ConnectionService } from 'ng-connection-service';
import { CountdownComponent } from 'ngx-countdown/countdown.component';
import moment from 'moment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';



@Component({
  selector: 'app-examen-estudiante',
  templateUrl: './examen-estudiante.component.html',
  styleUrls: ['./examen-estudiante.component.scss']
})
export class ExamenEstudianteComponent implements OnInit {

  moment: any = moment;

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  tiempoExamen = "";


  status = 'ONLINE';
  isConnected = true;
  examen_generado_id;
  preguntasExamen: any[] = [];
  examenFormGroup: FormGroup;
  cargando=false;
  preguntas_por_bancos_d_preg;
  preguntas_por_bancos_d_preg_query;
  resumen_examen;

  get preguntas_exameneFormArray(): FormArray {
    return this.examenFormGroup.get('preguntas_exameneFormArray') as FormArray;
  }

  constructor(
    private rutaActiva: ActivatedRoute,
    private examenes_generadosService: ExamenesGeneradosService,
    private formBuilder: FormBuilder,
    private opciones_respuestas_usersService: OpcionesRespuestasUsersService,
    private _snackBar: MatSnackBar,
    private connectionService: ConnectionService,
    private location: Location,
    private route: Router
  ) {
    this.examen_generado_id = parseInt(this.rutaActiva.snapshot.params['id-examen-generado']);
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      // console.log(isConnected);
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
      }
    });

    this.getExamen(this.examen_generado_id);
    this.getExamen_query(this.examen_generado_id);
  }

  ngOnInit(): void {
  }

  getExamen_query(id){
    this.cargando=true;
    this.examenes_generadosService.query_getPreguntasExameneGenerado(id).subscribe(res => {
      // console.log(res);
      let preguntasExamen = res.getPreguntasExameneGenerado;
     
      let filtrado_preguntas = preguntasExamen.reduce(
        function (r, a) { 
          r[a.examen_banco_pregunta_id] = r[a.examen_banco_pregunta_id] || [];
          r[a.examen_banco_pregunta_id].push(a); 
          return r; 
        }, Object.create(null)
        );
      // console.log(filtrado_preguntas);
      
      let preguntas_por_responder=[];
      for (const key in filtrado_preguntas) {
        // console.log(filtrado_preguntas[key]);
        let preguntas= filtrado_preguntas[key];
        let preguntas_con_opciones=[];
        for (const p of preguntas) {
          let copy={...p};
          let opciones_marcadas= p.opciones_respuestas_users.filter(o=> o.valor_opcion==true);
          copy.pregunta_respondida=opciones_marcadas.length > 0?true:false;
          // preguntas_con_opciones.push({
          //   pregunta:p,
          //   pregunta_respondida: opciones_marcadas.length > 0?true:false,
          // });
          // console.log(p);
          preguntas_con_opciones.push(copy);
        }
        // console.log(preguntas_con_opciones);
        let preguntas_por_responder_= (preguntas_con_opciones.filter(p=>p.pregunta_respondida==false)).length;
        preguntas_por_responder.push({
          banco_de_pregunta: preguntas_con_opciones[0].examen_banco_pregunta.nombre,
          banco_pregunta_examen: key,
          // preguntas: filtrado_preguntas[key],
          preguntas:preguntas_con_opciones,
          preguntas_por_responder: preguntas_por_responder_,
        });
      }
      // console.log(preguntas_por_responder);
      // this.resumen_examen= preguntas_por_responder;
      
      this.preguntas_por_bancos_d_preg_query=filtrado_preguntas;
      // this.getTiempoRestante();
      this.cargando=false;

      // console.log(this.preguntasExamen);
    });
  }

  getExamen(id){
    this.cargando=true;
    this.examenes_generadosService.getPreguntasExameneGenerado(id).subscribe(res => {
      // console.log(res);
      this.preguntasExamen = res.getPreguntasExameneGenerado;
     
      let filtrado_preguntas = this.preguntasExamen.reduce(
        function (r, a) { 
          r[a.examen_banco_pregunta_id] = r[a.examen_banco_pregunta_id] || [];
          r[a.examen_banco_pregunta_id].push(a); 
          return r; 
        }, Object.create(null)
        );
      // console.log(filtrado_preguntas);
      
      let preguntas_por_responder=[];
      for (const key in filtrado_preguntas) {
        // console.log(filtrado_preguntas[key]);
        let preguntas= filtrado_preguntas[key];
        let preguntas_con_opciones=[];
        for (const p of preguntas) {
          let copy={...p};
          let opciones_marcadas= p.opciones_respuestas_users.filter(o=> o.valor_opcion==true);
          copy.pregunta_respondida=opciones_marcadas.length > 0?true:false;
          // preguntas_con_opciones.push({
          //   pregunta:p,
          //   pregunta_respondida: opciones_marcadas.length > 0?true:false,
          // });
          // console.log(p);
          preguntas_con_opciones.push(copy);
        }
        // console.log(preguntas_con_opciones);
        let preguntas_por_responder_= (preguntas_con_opciones.filter(p=>p.pregunta_respondida==false)).length;
        preguntas_por_responder.push({
          banco_de_pregunta: preguntas_con_opciones[0].examen_banco_pregunta.nombre,
          banco_pregunta_examen: key,
          // preguntas: filtrado_preguntas[key],
          preguntas:preguntas_con_opciones,
          preguntas_por_responder: preguntas_por_responder_,
        });
      }
      // console.log(preguntas_por_responder);
      this.resumen_examen= preguntas_por_responder;
      
      this.preguntas_por_bancos_d_preg=filtrado_preguntas;
      this.getTiempoRestante();
      this.cargando=false;

      // console.log(this.preguntasExamen);
    });
  }

  getTiempoRestante() {
    if (this.tiempoExamen == "") {
      const tiempoRestante = this.examenes_generadosService.getDuracionRestanteExamenGenerado(this.examen_generado_id).subscribe(
        (res: any) => {
          // console.log(res.data.getDuracionRestanteExamenGenerado);
          this.tiempoExamen = res.data.getDuracionRestanteExamenGenerado.toString();
        },
        err => { 
          // console.log(err); 
        },
        () => { 
          // console.log("terminado"); 
          tiempoRestante.unsubscribe() }
      );
    }
  }
  verificarTiempoRestante() {
    const tiempoRestante = this.examenes_generadosService.getDuracionRestanteExamenGenerado(this.examen_generado_id).subscribe(
      (res: any) => {
        // console.log(res.data.getDuracionRestanteExamenGenerado);
        this.tiempoExamen = res.data.getDuracionRestanteExamenGenerado.toString();
        if (res.data.getDuracionRestanteExamenGenerado < 1) {
          // console.log(res.data.getDuracionRestanteExamenGenerado);
          // this.location.back();
          this.route.navigateByUrl('/home-estudiante');
        }
      },
      err => { 
        // console.log(err); 
      },
      () => { 
        // console.log("terminado"); tiempoRestante.unsubscribe();
       }
    );
  }

  diferenteBancoDePreguntas(preguntaActual: any, preguntaAnterior: any): boolean {
    if (!preguntaAnterior) {
      return true;
    }
    else {
      if (preguntaActual.banco_pregunta_id != preguntaAnterior.banco_pregunta_id) {
        return true;
      }
      else { return false }
    }
  }

  letraOpcion(numeroOpcion) {
    switch (numeroOpcion) {
      case 1: return "a"; break;
      case 2: return "b"; break;
      case 3: return "c"; break;
      case 4: return "d"; break;
      case 5: return "e"; break;
      default: 
      // console.log("numero de opcion invalida");
       break;
    }
  }


  onChange(e, pregunta_examen_generado_id, i, opcionMultiple) {
    let opcion_multiple: boolean = opcionMultiple;
    // console.log(e);
    // console.log(e.option.value.id);
    let valor_opcion_respuesta_user: boolean = e.option.selected;
    let opcion_respuesta_user_id: number = e.option.value.id;
    // console.log(opcion_multiple + " " + valor_opcion_respuesta_user + " " + opcion_respuesta_user_id);

    const guardar = this.opciones_respuestas_usersService.guardarOpcionRespuestaUser(opcion_multiple, valor_opcion_respuesta_user, opcion_respuesta_user_id).
      subscribe(res => {
        // console.log(res);
        if (res.data) {
          this.snackBarGuardadoConExito();
        }
      }, err => {
        // console.log(err);
        this.snackBarErrorAlGuardar();
      },
        () => {
          // console.log("Completado");
          guardar.unsubscribe();
        }
      );
  }

  marcarRespuesta() {
    let preguntaExamenGenerado = this.preguntasExamen[1];
  }


  snackBarGuardadoConExito() {
    this._snackBar.open('Guardado', null, {
      duration: 1000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  snackBarErrorAlGuardar() {
    this._snackBar.open('Error, vefique su conexión a Internet.', null, {
      duration: 4000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  handleEvent(event) {
    // if(event.action === 'notify'){
    //   console.log('Se termino el tiempo!');
    // }
    if (event.action === 'done') {
      if (this.tiempoExamen) {
        this.verificarTiempoRestante();
      }
      // console.log("Se termino el tiempo");
    }
  }

  calcularFechas() {
    let hoy = moment();
    let hoyMas10 = hoy.add(5, 'minutes');
    // console.log(moment());
    // console.log(hoyMas10);
  }

  finalizarExamen() {
    const finalizarExamen = this.examenes_generadosService.finalizarExamenGenerado(this.examen_generado_id).
      subscribe((res: any) => {
        // console.log(res);
        if (res) {
          this.route.navigateByUrl('/home-estudiante');
        }
      },
        err => { 
          // console.log(err);
         },
        () => { 
          // console.log("completado"); 
          finalizarExamen.unsubscribe(); 
        }
      )
  }
  changeSeleccionMultiple(e,opcion_multiple) {
    // console.log("opcion multiple "+opcion_multiple)
    // console.log(e.source.checked);
    // console.log(e);
    this.guardarRespuesta(e,opcion_multiple)

  }
  changeSeleccionUnica(e, opcion_multiple) {
    // console.log("opcion unica "+opcion_multiple)
    // console.log(e.source.checked)
    // console.log(e);
    this.guardarRespuesta(e,opcion_multiple);
  }
  guardarRespuesta(e,opcionMultiple){
    let opcion_multiple: boolean = opcionMultiple;
    let valor_opcion_respuesta_user: boolean = e.source.checked;
    let opcion_respuesta_user_id: number = e.source.value.id;
    this.opciones_respuestas_usersService.guardarOpcionRespuestaUser(opcion_multiple, valor_opcion_respuesta_user, opcion_respuesta_user_id).
    subscribe(res => {
      // console.log(res);
      if (res.data) {
        this.snackBarGuardadoConExito();
      }
    }, err => {
      // console.log(err);
      this.snackBarErrorAlGuardar();
    },
      () => {
        // console.log("Completado");
      }
    );
  }
  nroLetrasPalabra(palabra:string){
    // console.log(palabra)
    return palabra.length;
    
  }

  // buildFormGroupExamenes(){
  //   this.examenFormGroup= this.formBuilder.group({
  //     // preguntas_exameneFormArray: this.formBuilder.array([]),
  //   })
  // }

  // addMateria(): void {
  //   let preguntas=this.preguntas_por_bancos_d_preg;
  //   for (const iterator in preguntas) {
  //     console.log(iterator);
  //     console.log(preguntas[iterator]);
  //     this.examenFormGroup.addControl(iterator,this.formBuilder.array(preguntas[iterator]));

  //   }
  //   console.log(this.examenFormGroup)
  // }

  preguntasPorResponder(preguntas){
    // console.log(preguntas);
    for (const p of preguntas) {
      // console.log(p.opciones_respuestas_users);
    }
  }

  faltaPorResponder():boolean {
    let faltan_preguntas=false;
    for (const elem of this.resumen_examen) {
      if( elem.preguntas_por_responder>0 && faltan_preguntas==false ){
        faltan_preguntas=true;
      }
    }
    return faltan_preguntas
    
  }


}
