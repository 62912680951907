<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx" class="mx-4 my-1 ">
    <img src="assets/images/logoCCA2.png" width="80px">
</div>
<br>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
    <form novalidate #form="ngForm" [formGroup]="estudianteFormGroup" autocomplete="off">
        <mat-card fxFlex="30rem" fxFlex.lt-md="20rem" class="mat-elevation-z2">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx">
                <h3 id="titulo_card">Crear cuenta</h3>    
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0rem" class="mt-3">
                <mat-form-field appearance="" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1" > 
                    <mat-label>Nombres</mat-label>
                    <input matInput required formControlName="primer_nombre" type="text" autocomplete="new-primer_nombre" >
                </mat-form-field>
                <!-- <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Otros nombres</mat-label>
                    <input matInput formControlName="otros_nombres">
                </mat-form-field> -->
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Apellido Paterno</mat-label>
                    <input matInput formControlName="apellido_paterno" type="text" autocomplete="new-apellido_paterno">
                </mat-form-field>


                <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Apellido Materno</mat-label>
                    <input matInput formControlName="apellido_materno" type="text" autocomplete="new-apellido_materno">
                </mat-form-field>
            </div>



            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                <mat-form-field appearance="" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Correo electronico</mat-label>
                    <input matInput formControlName="correo_electronico" required type="email" autocomplete="new-correo_electronico">
                    <mat-icon matSuffix>alternate_email</mat-icon>
                    <mat-error *ngIf="this.estudianteFormGroup.get('correo_electronico').errors?.existe"> El correo ya fue registrado </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                <mat-form-field appearance="" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Celular</mat-label>
                    <input matInput formControlName="nro_celular" required type="tel" autocomplete="new-nro_celular">
                    <mat-icon matSuffix>phone</mat-icon>
                    <!-- <mat-error *ngIf="this.estudianteFormGroup.get('correo_electronico').errors?.existe"> El correo ya fue registrado </mat-error> -->
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Contraseña</mat-label>
                    <input matInput formControlName="password" [type]="estudianteFormGroup.get('visible_pass').value?'text':'password'" autocomplete="off">
                    <mat-icon matSuffix>vpn_key</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="" fxFlex="12rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Confirma la contraseña</mat-label>
                    <input matInput formControlName="confirmar_password"  [type]="estudianteFormGroup.get('visible_pass').value?'text':'password'" autocomplete="off">
                    <mat-icon matSuffix>vpn_key</mat-icon>
                </mat-form-field>
            </div>
            <!-- <input mat-checkbox type="checkbox" formControlName="visible_pass" id="vercontrasenias" > -->
            <!-- <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="gappx"> -->
                <div class="ml-3 mb-2">
                    <mat-checkbox formControlName="visible_pass"  color="primary" >Mostar contraseña</mat-checkbox>    
                </div>
            <!-- </div> -->
            

            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
                <mat-form-field appearance="outline" fxFlex="25rem" fxFlex.lt-md="100%" class="mx-1">
                    <mat-label>Selecciona tu carrera</mat-label>
                    <mat-select formControlName="examenPreUniversitario_id" required>
                        <mat-option *ngFor="let examen of examenesPreuniversitarios" [value]=examen.id>{{examen.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="mensaje_error" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" class="my-2">
                {{mensaje_error}}
            </div>
            <div *ngIf="registrando_estudiante" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">
                
                    <p>Procesando la solicitud...</p>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                
            </div>
            <div *ngIf="estudiante_registrado_correctamente" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">
                <p class="fw-500">Cuenta registrada correctamente <span><mat-icon class="color_success">done_outline</mat-icon></span></p>
                <div id="messageInfo">
                    <p>Se le ha enviado un correo a la dirección de correo electrónico <span class="fw-500">{{correoElectronico.value}}</span> para la verificación y activación de su cuenta.</p>
                    <p>Si no encuentra este correo en su bandeja de entrada, revise las carpetas de ‘SPAM’ o ‘Correo no deseado’, ya que algunos clientes de correo electrónico mueven este tipo de correos a estas carpetas al no reconocer el remitente.</p>
                </div>
                <button mat-raised-button color="primary" (click)="reenviarCorreo()">Reenviar correo</button>
                
               
            </div>
            <mat-card-actions align="end" *ngIf="estudiante_registrado_correctamente==false">
                <button mat-raised-button color=primary (click)="registrarEstudiante() " [disabled]="registrando_estudiante">Crear cuenta</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
<br><br><br>