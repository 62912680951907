import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AddressFormComponent } from './address-form/address-form.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { TreeComponent } from './tree/tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropComponent } from './drag-drop/drag-drop.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { GraphQLModule } from './graphql.module';
import { ListOfQuestionBanksComponent } from './list-of-question-banks/list-of-question-banks.component';
import { FormAddQuestionBankComponent } from './form-add-question-bank/form-add-question-bank.component';
import { FormEditQuestionBankComponent } from './form-edit-question-bank/form-edit-question-bank.component';
import { ListOfQuestionsComponent } from './list-of-questions/list-of-questions.component';
import { FormAddQuestionComponent } from './form-add-question/form-add-question.component';
import { FormEditQuestionComponent } from './form-edit-question/form-edit-question.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { ViewQuestionComponent } from './view-question/view-question.component';
import { ListOfExamsComponent } from './list-of-exams/list-of-exams.component';
import { FormAddExamComponent } from './form-add-exam/form-add-exam.component';
import { FormEditExamComponent } from './form-edit-exam/form-edit-exam.component';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './login/login.component';
import {MatDialogModule} from '@angular/material/dialog';
import { EliminarPreguntaAlertDialogComponent } from './eliminar-pregunta-alert-dialog/eliminar-pregunta-alert-dialog.component';
import { EliminarExamAlertDialogComponent } from './eliminar-exam-alert-dialog/eliminar-exam-alert-dialog.component';
import { EliminarBancoPreguntasAlertDialogComponent } from './eliminar-banco-preguntas-alert-dialog/eliminar-banco-preguntas-alert-dialog.component';
import { CommonModule } from '@angular/common';
import { HomeEstudianteComponent } from './home-estudiante/home-estudiante.component';
import { ExamenEstudianteComponent } from './examen-estudiante/examen-estudiante.component';

import { CountdownModule } from 'ngx-countdown';
import { HistorialExamenesEstudianteComponent } from './historial-examenes-estudiante/historial-examenes-estudiante.component';
import { RevisarExamenEstudianteComponent } from './revisar-examen-estudiante/revisar-examen-estudiante.component';
import { FormAddEstudianteComponent } from './form-add-estudiante/form-add-estudiante.component';
import { ListaDeEstudiantesComponent } from './lista-de-estudiantes/lista-de-estudiantes.component';
import { DetallesEstudianteComponent } from './detalles-estudiante/detalles-estudiante.component';
import { EditInfoEstudianteComponent } from './edit-info-estudiante/edit-info-estudiante.component';
import { DialogGenerarPanillaMensualComponent } from './dialog-generar-panilla-mensual/dialog-generar-panilla-mensual.component';
import { PruebasComponent } from './pruebas/pruebas.component';
import { VerificacionCorreoComponent } from './verificacion-correo/verificacion-correo.component';
import { HeaderEstudianteComponent} from './components/header-estudiante/header-estudiante.component';
import { CrearCuentaEstudianteComponent } from './crear-cuenta-estudiante/crear-cuenta-estudiante.component';
import { RecuperarCuentaComponent } from './recuperar-cuenta/recuperar-cuenta.component';
import { RestablecerPasswordComponent } from './restablecer-password/restablecer-password.component'
import { AngularDraggableModule } from 'angular2-draggable';
import { ExpocruzComponent } from './expocruz/expocruz.component';
import {MatTabsModule} from '@angular/material/tabs';
import { EliminarExamenEstudianteComponent } from './dialogs/eliminar-examen-estudiante/eliminar-examen-estudiante.component';
import { DialogDetallesExamenComponent } from './dialogs/dialog-detalles-examen/dialog-detalles-examen.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddressFormComponent,
    DashboardComponent,
    TreeComponent,
    DragDropComponent,
    ListOfQuestionBanksComponent,
    FormAddQuestionBankComponent,
    FormEditQuestionBankComponent,
    ListOfQuestionsComponent,
    FormAddQuestionComponent,
    FormEditQuestionComponent,
    ViewQuestionComponent,
    ListOfExamsComponent,
    FormAddExamComponent,
    FormEditExamComponent,
    LoginComponent,
    EliminarPreguntaAlertDialogComponent,
    EliminarExamAlertDialogComponent,
    EliminarBancoPreguntasAlertDialogComponent,
    HomeEstudianteComponent,
    ExamenEstudianteComponent,
    HistorialExamenesEstudianteComponent,
    RevisarExamenEstudianteComponent,
    FormAddEstudianteComponent,
    ListaDeEstudiantesComponent,
    DetallesEstudianteComponent,
    EditInfoEstudianteComponent,
    DialogGenerarPanillaMensualComponent,
    PruebasComponent,
    VerificacionCorreoComponent,
    HeaderEstudianteComponent,
    CrearCuentaEstudianteComponent,
    RecuperarCuentaComponent,
    RestablecerPasswordComponent,
    ExpocruzComponent,
    EliminarExamenEstudianteComponent,
    DialogDetallesExamenComponent,
    
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    FormsModule, 
    MatStepperModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTooltipModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    ReactiveFormsModule, 
    LayoutModule, 
    MatProgressBarModule,
    MatInputModule, 
    MatSelectModule, 
    MatRadioModule, 
    MatCardModule, 
    MatTableModule, 
    MatPaginatorModule, 
    MatSortModule, 
    MatSnackBarModule,
    MatGridListModule, 
    MatMenuModule, 
    MatTreeModule, 
    CountdownModule,
    DragDropModule, 
    GraphQLModule,
    CommonModule,
    AngularDraggableModule,
    MatTabsModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent],
  entryComponents:[EliminarPreguntaAlertDialogComponent, EliminarBancoPreguntasAlertDialogComponent],
})
export class AppModule { }
