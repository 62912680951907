
import gql from 'graphql-tag';


export const insertOrUpdateUserExamen=gql`
    mutation insertOrUpdateUserExamen($user_examenData:UserExamenInput!){
        insertOrUpdateUserExamen(user_examenData:$user_examenData){
          id,
          user_id,
          examen_id,
          estado,
          created_at,
          updated_at,
          cod_user
        }
    }
`;
