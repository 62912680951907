
<div>
    <button mat-raised-button color="primary" (click)="this.location.back()">Cancelar</button>
</div>


<mat-horizontal-stepper linear #stepper >
    <mat-step [editable]="isEditable">

        <section class="example-section">
            <label class="example-margin">Agregar:</label>
            <mat-radio-group [(ngModel)]="typeQuestionPart">
                <mat-radio-button class="example-margin" value="text">Texto</mat-radio-button>
                <mat-radio-button class="example-margin" value="image">Imagen</mat-radio-button>
            </mat-radio-group>
        </section>

        <ng-template matStepLabel type="button">Construir Pregunta</ng-template>

        <div *ngIf="typeQuestionPart=='text'; else elseBlock">
            <form [formGroup]="questionPartTextFormGroup">
                <mat-form-field appearance="outline" class="mb--1" fxFlexFill>
                    <mat-label>Texto</mat-label>
                    <input type="text" matInput formControlName="texto" placeholder="" 
                        autocomplete="off">
                </mat-form-field>
                <div class="mb--2">
                    <button type="submit" (click)="pushQuestionPartText()" mat-raised-button color="primary" class="btn-guardar"
                        [disabled]="questionPartTextFormGroup.invalid">
                        <mat-icon>add</mat-icon>
                        Agregar texto
                    </button>
                </div>
            </form>
        </div>
        <ng-template #elseBlock>
            <form [formGroup]="questionPartImageFormGroup">
                <div>
                    <!-- <p>Seleccione la imagen</p> -->
                    <input type="file" (change)="uploadImageQuestionPart($event)" id="img_parte_pregunta">
                    <p>Porcentaje de carga: {{carga|async}}%</p>
                </div>
                <mat-card>
                    <div fxLayout="row" fxLayoutAlign="center center" class="m-1rem">
                        <div class="div_imagen_resize" ngResizable [rzAspectRatio]="true" [rzMinWidth]="'20'">
                            <img class="imgPartQuestion" src={{fileurl}} alt="" id="image_part">
                        </div>
                    </div>

                    <div class="center mb-1">
                        <button type="submit" (click)="pushQuestionPartImage()" color="primary" mat-raised-button class="btn-guardar"
                            [disabled]="questionPartImageFormGroup.invalid">
                            <mat-icon>add_photo_alternate</mat-icon>
                            Agregar imagen
                        </button>
                    </div>
                </mat-card>
            </form>
        </ng-template>

        

        <!-- <mat-divider></mat-divider> -->
        <!-- <div *ngIf="questionParts.length>0">
            <br>
            <p class="center m-1">
                <mat-checkbox [(ngModel)]='confirmationQuestion' color="primary">Confirmar la construción de la
                    pregunta</mat-checkbox>
            </p>
            <div *ngIf="confirmationQuestion">
                <button mat-raised-button color="accent" matStepperNext>Siguiente</button>
            </div>
        </div> -->

    </mat-step>
    <!-- ================================================================================================================================== -->
    <mat-step>
        <ng-template matStepLabel>Respuestas</ng-template>
        <section class="example-section">
            <label class="example-margin">Agregar opción:</label>
            <mat-radio-group [(ngModel)]="typeAnswerOption">
                <mat-radio-button value="text">Texto</mat-radio-button>
                <mat-radio-button value="image">Imagen</mat-radio-button>
            </mat-radio-group>
        </section>

        <div *ngIf="typeAnswerOption=='text'; else elseBlockAnswer">
            <form [formGroup]="answerOptionTextFormGroup">
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
                    <mat-form-field appearance="outline" class="mb--2 mr-1">
                        <mat-label>Texto</mat-label>
                        <input type="text" matInput formControlName="texto" placeholder="">
                    </mat-form-field>
                    <button type="submit" (click)="pushAnswerOptionText()" mat-raised-button color="accent" class="btn-guardar"
                        [disabled]="answerOptionTextFormGroup.invalid">
                        <mat-icon>add</mat-icon>
                    Agregar opción texto
                    </button>
                </div>
               

                <!-- <div class="center m-1rem ">
                    <ng-container *ngIf="typeAnswerQuestion=='multipleOption'; else elseTemplate1">
                        <mat-slide-toggle formControlName="opcion_correcta">Opción correcta</mat-slide-toggle>
                    </ng-container>
                    <ng-template #elseTemplate1>
                        <mat-slide-toggle *ngIf="countCorrectOptions<1" formControlName="opcion_correcta">Opción
                            correcta</mat-slide-toggle>
                    </ng-template>
                </div> -->

                <!-- <div class=" mb--2">
                  
                </div> -->
            </form>
        </div>
        <ng-template #elseBlockAnswer>
            <form [formGroup]="answerOptionImageFormGroup">
                <div>
                    <!-- <p>Seleccione la imagen</p> -->
                    <input type="file" (change)="uploadImageAnswerOption($event)">
                    <p>Porcentaje de carga: {{carga|async}}%</p>
                </div>
                <mat-card>

                    <div fxLayout="row" fxLayoutAlign="center center" class="m-1rem">
                        <div class="div_imagen_resize" ngResizable [rzAspectRatio]="true" [rzMinWidth]="'20'">
                            <img class="imgPartQuestion mr-1" id="image_part_resp" src={{fileurl}} alt="">
                        </div>
                    </div>

                    <div class="center mb-1">
                        <button type="submit" (click)="pushAnswerOptionImage()" mat-raised-button class="btn-guardar"
                            [disabled]="answerOptionImageFormGroup.invalid"
                            color="accent"
                            >
                            <mat-icon>add_photo_alternate</mat-icon>
                            Agregar opción
                        </button>
                    </div>
                </mat-card>
            </form>
        </ng-template>


        <!-- <div class="div-vista-previa">
            <h4 class="my-1 c-primary fw-500">
                Vista previa de la pregunta
            </h4>
            <button mat-icon-button (click)="reiniciarRegistroDeRespuestas()">
                <mat-icon color='warn'>delete</mat-icon>
            </button>
        </div> -->

        <!-- <mat-card class="mt-1">

            <div fxLayout="row wrap " fxLayoutAlign="start center" class="m-1rem question">
                <div *ngFor="let questionPart of questionParts">
                    <ng-container *ngIf="questionPart.texto==''; else elseTemplate">
                        <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':questionPart.imagen_height}"
                            src={{questionPart.imagen_url}} alt="">
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="mr-1" ng-i>
                            {{questionPart.texto}}
                        </div>
                    </ng-template>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign=" start" class="m-1rem">
                <div *ngFor="let answerOption of answerOptions; index as i" class="mb-1 divAnswerOption">
                    <mat-checkbox class="example-margin" [disabled]="true" color="primary"
                        [checked]="answerOption.opcion_correcta"></mat-checkbox>
                    <label class="mr-1">{{answerOption.nro_orden+1+") "}}</label>

                    <ng-container *ngIf="answerOption.texto==''; else elseTemplate">
                        <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':answerOption.imagen_height}"
                            src={{answerOption.imagen_url}} alt="">
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="mr-1" ng-i>
                            {{answerOption.texto}}
                        </div>
                    </ng-template>
                </div>
                <div [formGroup]="preguntaFormGroup"  >
                    <div *ngFor="let opciones_respuestaGroup of opciones_respuesta.controls; let i = index" [formGroup]="opciones_respuestaGroup"
                    class="mb-1 divAnswerOption"
                    >
                        <mat-checkbox class="example-margin" color="primary"
                        formControlName="opcion_correcta"    
                        ></mat-checkbox>
                        
                        <label class="mr-1">{{opciones_respuestaGroup.value.nro_orden+1+") "}}</label>
                        <ng-container *ngIf="opciones_respuestaGroup.value.texto==''; else elseTemplate">
                            <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':opciones_respuestaGroup.value.imagen_height}"
                                src={{opciones_respuestaGroup.value.imagen_url}} alt="">
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div class="mr-1" ng-i>
                                {{opciones_respuestaGroup.value.texto}}
                            </div>
                        </ng-template>
                    </div>
                </div>
                <pre>
                    {{preguntaFormGroup.value | json}}
                    </pre>
            </div>
        </mat-card> -->

       
    </mat-step>
</mat-horizontal-stepper>

<mat-divider>
</mat-divider>
<div class="">
    <!-- <div class="div-vista-previa">
        
        <button mat-icon-button (click)="reiniciarConstruccionDePregunta()">
            <mat-icon color='warn'>delete</mat-icon>
        </button>
    </div> -->

    <mat-card class="px-2 mt-1 mat-elevation-z6">
        <section class="example-section">
            <label class="example-margin">Tipo de Respuesta:</label>
            <mat-radio-group [(ngModel)]="typeAnswerQuestion" required
            (change)="radioChange($event)"
            >
                <mat-radio-button class="example-margin" value="onlyOption">Opción unica</mat-radio-button>
                <mat-radio-button class="example-margin" value="multipleOption">Opción multiple
                </mat-radio-button>
            </mat-radio-group>
        </section>
        <h4 class=" c-primary fw-500">
            Vista previa de la pregunta
        </h4>
        
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="m-1rem question">
            <div *ngFor="let questionPart of questionParts ; let i = index">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0px">
                    <ng-container *ngIf="questionPart.texto==''; else elseTemplate">
                        <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':questionPart.imagen_height}"
                            src={{questionPart.imagen_url}} alt="">
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="mr-1" ng-i>
                            {{questionPart.texto}}
                        </div>
                    </ng-template>
                    <button mat-icon-button aria-label="" matTooltip="Eliminar"
                (click)="removePartePregunta(i)"
                >
                  <mat-icon class="">delete</mat-icon>
                </button>
                </div>
               
                
                
            </div>
        </div>
          <div [formGroup]="preguntaFormGroup"  >
                    <div *ngFor="let opciones_respuestaGroup of opciones_respuesta.controls; let i = index" [formGroup]="opciones_respuestaGroup"
                    class="mb-1 divAnswerOption"
                    >
                        <mat-checkbox class="example-margin" color="primary"
                        formControlName="opcion_correcta"
                        (change)="showOptions($event, i)"     
                        ></mat-checkbox>
                        
                        <!-- <label class="mr-1">{{opciones_respuestaGroup.value.nro_orden+1+") "}}</label> -->
                        <label class="mr-1">{{i+1+") "}}</label>
                        <ng-container *ngIf="opciones_respuestaGroup.value.texto==''; else elseTemplate">
                            <img class="imgPartQuestion mr-1" [ngStyle]="{'height.px':opciones_respuestaGroup.value.imagen_height}"
                                src={{opciones_respuestaGroup.value.imagen_url}} alt="">
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div class="mr-1" ng-i>
                                <mat-form-field appearance="legacy" class="mb--2 mr-1">
                                    <!-- <mat-label>Texto</mat-label> -->
                                    <input type="text" matInput formControlName="texto" placeholder="">
                                </mat-form-field>
                                <!-- {{opciones_respuestaGroup.value.texto}} -->
                            </div>
                        </ng-template>
                        <button mat-icon-button aria-label="" matTooltip="Eliminar"
                        (click)="removeOpcionRespuesta(i)"
                        >
                          <mat-icon class="">delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="center m-1rem">
                    <button mat-raised-button color="primary" type="button" (click)="guardarPregunta()"
                    [disabled]="this.conteoMarcado()==0 || this.questionParts.length==0"
                    > 
                        <mat-icon>save</mat-icon>
                         Guardar pregunta
                    </button>
                </div>

                <!-- <h1>{{this.conteoMarcado()}}</h1> -->
    </mat-card>
</div>