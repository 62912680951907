<app-header-estudiante></app-header-estudiante>
<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="0px">
    <button (click)="this.location.back()" mat-button matTooltip="Pagina anterior" matTooltipPosition="below" class="mr-1 silver ">
        <mat-icon> arrow_back</mat-icon> Atras
      </button>
</div>

<p class="mx-2 my-2" *ngIf="!this.preguntas_por_bancos_d_preg">Cargando...</p>

<mat-tab-group *ngIf="this.preguntas_por_bancos_d_preg" animationDuration="0ms">
    <mat-tab *ngFor="let banco_preguntas of this.preguntas_por_bancos_d_preg | keyvalue; let i=index">
        <ng-template mat-tab-label class="color_1">{{banco_preguntas.value[0].examen_banco_pregunta.nombre|uppercase}}</ng-template>
        <div *ngFor="let preguntaExamen of banco_preguntas.value; let i=index" class="pt-2">

        

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                <mat-card class="mb-1" fxFlex="80%" fxFlex.lt-md="100%" fxFlex.gt-md="65%" [ngStyle]="{'border':preguntaExamen.respuesta_correcta?'4px solid #4CAF50':'3px solid #FF5252'}">
                    <div fxLayout="row" fxLayoutAlign="start start" class="mb-1">
                        <strong class="mr-1 ">{{i+1}})</strong>
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                            <div *ngFor="let questionPart of preguntaExamen.pregunta.partes_pregunta;let  i=index"
                                class="d-flex">
                                <div *ngIf="questionPart.texto!=''" class="mb-0 mr-1">
                                    {{questionPart.texto}}
                                </div>
                                <img class="mr-1 imgPartQuestion" *ngIf="questionPart.imagen_url!=''"
                                    [ngStyle]="{'height.px':questionPart.imagen_height}" src={{questionPart.imagen_url}}>
                            </div>
    
                        </div>
                    </div>
    
                    <div *ngIf="!preguntaExamen.pregunta.opcion_multiple">
                        <mat-radio-button [disabled]="true" class="txtRadioButton" [name]="preguntaExamen.id"
                            class="example-radio-button"
                            *ngFor="let answerOption of preguntaExamen.opciones_respuestas_users; let indexOpcionRespuestaUser=index"
                            [checked]="answerOption.valor_opcion" [value]="answerOption">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <h3 class="mb-0 mr-2">{{letraOpcion(indexOpcionRespuestaUser+1)+") "}}
                                </h3>
                                <div fxLayout="row wrap" fxLayoutAlign="start center">
                                    <span class="mat-radio-label" *ngIf="answerOption.opcion_respuesta.texto!=''">
                                        {{answerOption.opcion_respuesta.texto}}
                                    </span>
                                    <span *ngIf="answerOption.opcion_respuesta.imagen_url!=''">
                                        <img class="imgPartQuestion mr-1"
                                            [ngStyle]="{'height.px':answerOption.opcion_respuesta.imagen_height}"
                                            src={{answerOption.opcion_respuesta.imagen_url}} alt="">
                                    </span>
                                </div>
                            </div>
    
                        </mat-radio-button>
                    </div>
    
    
                    <div *ngIf="preguntaExamen.pregunta.opcion_multiple" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="gappx">
                        <mat-checkbox class="m-checkbox" [disabled]="true"
                            *ngFor="let answerOption of preguntaExamen.opciones_respuestas_users; let indexOpcionRespuestaUser=index"
                            [checked]="answerOption.valor_opcion" [value]="answerOption">
    
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <h3 class="mb-0 mr-2">{{letraOpcion(indexOpcionRespuestaUser+1)+") "}}
                                </h3>
                                <div fxLayout="row wrap" fxLayoutAlign="start center">
                                    <span class="mat-radio-label" *ngIf="answerOption.opcion_respuesta.texto!=''">
                                        {{answerOption.opcion_respuesta.texto}}
                                    </span>
                                    <span *ngIf="answerOption.opcion_respuesta.imagen_url!=''">
                                        <img class="imgPartQuestion mr-1"
                                            [ngStyle]="{'height.px':answerOption.opcion_respuesta.imagen_height}"
                                            src={{answerOption.opcion_respuesta.imagen_url}} alt="">
                                    </span>
                                </div>
                            </div>
                        </mat-checkbox>
                    </div>
    
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="gappx">
                        <div  class="fw-500 mr-1">Respuesta:</div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" >
                            <div *ngFor="let item of preguntaExamen.opciones_respuestas_users; let i=index">
                                
                                <div *ngIf="item.opcion_respuesta.opcion_correcta==true" >
                                    <!-- {{item.opcion_respuesta.texto}} -->
                                    <ng-container *ngIf="item.opcion_respuesta.texto==''; else elseTemplate">
                                        <img class="imgPartQuestion mr-1"
                                            [ngStyle]="{'height.px':item.opcion_respuesta.imagen_height}"
                                            src={{item.opcion_respuesta.imagen_url}} alt="">
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        <span class="mr-1">
                                            {{item.opcion_respuesta.texto}}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>

        </div>
    </mat-tab>
    <mat-tab >
        <ng-template mat-tab-label>NOTA EXAMEN</ng-template>
        <div class="mx-2 mt-2">
            <h1> {{this.examen_generado.user_examen.examen.nombre}}</h1>
            <h3> Nota examen: {{this.examen_generado.nota_examen}}/100</h3>
            <!-- <div *ngIf="this.faltaPorResponder()==true">
                <h2 class="color_1">Le falta por responder: </h2>
                <div *ngFor="let elem of this.resumen_examen; let i=index">
                    {{elem.banco_de_pregunta +' '+elem.preguntas_por_responder +' de '+ elem.preguntas.length+' preguntas'}}
                </div>
                <br>
                <p>Si esta seguro de concluir con la prueba presione el boton <strong>FINALIZAR EXAMEN</strong> </p>
            </div> -->
            
            
            <!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" class="mt-2" *ngIf="!cargando">
                <button mat-raised-button color="primary" (click)="finalizarExamen()">FINALIZAR EXAMEN</button>
            </div> -->

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="materia">
                  <th mat-header-cell *matHeaderCellDef> Materia </th>
                  <td mat-cell *matCellDef="let element"> {{element.banco_de_pregunta.nombre |uppercase}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="porcentaje_calificacion">
                  <th mat-header-cell *matHeaderCellDef> Porcentaje Calificación </th>
                  <td mat-cell *matCellDef="let element"> {{element.banco_de_pregunta.porcentaje_calificacion}} % </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="nro_preguntas">
                  <th mat-header-cell *matHeaderCellDef> Nro. Preguntas </th>
                  <td mat-cell *matCellDef="let element"> {{element.preguntas.length}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="preg_resp_correctamente">
                  <th mat-header-cell *matHeaderCellDef> Correctas </th>
                  <td mat-cell *matCellDef="let element"> {{element.preguntas_respondidas_correctamente }} </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <br><br>
        </div>
    </mat-tab>
</mat-tab-group>
