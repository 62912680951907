import gql from 'graphql-tag';

export const insertOrUpdateUser=gql`
    mutation insertOrUpdateUser($userData:UserInput!){
      insertOrUpdateUser( userData:$userData ){
        id,
        primer_nombre,
        otros_nombres,
        apellido_paterno,
        apellido_materno,
        codigo_user,
        correo_electronico,
        nro_ci,
        compl_ci,
        exped_ci,
        password,
        estado,
        created_at,
        updated_at
      }
    }

`;


export const insertUser=gql`
  mutation insertUser($userData:UserInput!){
    insertUser( userData:$userData ){
      id,
      primer_nombre,
      otros_nombres,
      apellido_paterno,
      apellido_materno,
      codigo_user,
      correo_electronico,
      nro_ci,
      compl_ci,
      exped_ci,
      password,
      estado,
      created_at,
      updated_at
    }
  }
`;

export const confirmacionTokenCorreoElectronico=gql`
  mutation confirmacionTokenCorreoElectronico($token:String!){
    confirmacionTokenCorreoElectronico(token:$token)
  }
`;

export const restablecer_password=gql`
  mutation restablecer_password($correo_electronico:String!){
    restablecer_password(correo_electronico:$correo_electronico){
      id,
      primer_nombre,
      codigo_user,
      token_validacion_correo_electronico
    }
  }
`;


export const verificarTokenRestablecerPassword=gql`
  mutation verificarTokenRestablecerPassword($token:String!) {
    verificarTokenRestablecerPassword(token:$token){
      id
      primer_nombre,
      otros_nombres,
      apellido_paterno,
      apellido_materno
      codigo_user
      correo_electronico
      nro_ci
      compl_ci
      exped_ci
      password
      token_validacion_correo_electronico
      estado_validacion_correo_electronico
      
    }
  }
`; 

export const deleteUserExamen=gql`
  mutation deleteUserExamen($user_examen_id:Float!){
    deleteUserExamen(user_examen_id:$user_examen_id)
  }
`;
