
<div class="container">

    <form novalidate #form="ngForm" [formGroup]="estudianteFormGroup">

        <div fxLayout="row" fxLayoutAlign="center center"fxLayoutGap="gappx" class="mt-4">
            <mat-card fxFlex="50" fxFlex.xs="100" >
                <mat-card-header>
                <mat-card-title>Registrar Estudiante</mat-card-title>
                <!-- <mat-card-subtitle> &nbsp;</mat-card-subtitle> -->
            </mat-card-header>
                <mat-card-content>
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Primer nombre</mat-label>
                        <input matInput required formControlName="primer_nombre" autocomplete="off">
                    </mat-form-field>
                    <br>
    
                    <mat-form-field appearance="outline" fxFlexFill >
                        <mat-label>Otros nombres</mat-label>
                        <input matInput formControlName="otros_nombres" autocomplete="off">
                    </mat-form-field>
                    <br>
    
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Apellido Paterno</mat-label>
                        <input matInput formControlName="apellido_paterno" autocomplete="off">
                    </mat-form-field>
                    <br>
    
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Apellido Materno</mat-label>
                        <input matInput formControlName="apellido_materno" autocomplete="off">
                    </mat-form-field>
                    <br>
    
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Correo electronico</mat-label>
                        <input matInput formControlName="correo_electronico" required autocomplete="off">
    
                        <!-- <div class="errorExisteCorreo warn" *ngIf="existeEmail">
                      el correo ya existe
                    </div> -->
    
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Nro. C.I.</mat-label>
                        <input matInput formControlName="nro_ci" required autocomplete="off">
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Complemento C.I.</mat-label>
                        <input matInput formControlName="compl_ci" autocomplete="off">
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Expedito en</mat-label>
                        <mat-select formControlName="exped_ci" required autocomplete="off">
                            <mat-option *ngFor="let departamento of departamentos_exped_ci" [value]=departamento>
                                {{departamento}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>Examen Preuniversitario</mat-label>
                        <mat-select formControlName="examenPreUniversitario_id" required>
                          <mat-option *ngFor="let examen of examenes" [value]=examen.id>{{examen.nombre}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </mat-card-content>
                <mat-card-actions align="end">
                <button color="primary" mat-raised-button [disabled]="estudianteFormGroup.invalid" (click)="registrarEstudiante()">Registar Estudiante</button>
            </mat-card-actions>
            </mat-card>
        </div>


    </form>

</div>

<br><br><br><br>