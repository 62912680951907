import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import { concatMap, map, mergeMap } from 'rxjs/operators';
import { BancoPregunta } from '../@core/interfaces/banco-pregunta.interface';
import { ExamenBancoPregunta } from '../@core/interfaces/examen-banco-pregunta.interface';
import { Examen } from '../@core/interfaces/examen.interface';
import { User } from '../@core/interfaces/user.interface';
import { BancosPreguntasService } from '../@core/services/bancos-preguntas.service';
import { ExamenesBancosPreguntasService } from '../@core/services/examenes-bancos-preguntas.service';
import { ExamenesService } from '../@core/services/examenes.service';
import { UsersService } from '../@core/services/users.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-form-add-exam',
  templateUrl: './form-add-exam.component.html',
  styleUrls: ['./form-add-exam.component.scss']
})
export class FormAddExamComponent implements OnInit {

  examenFormGroup: FormGroup;
  bancoPreguntaFormGroup:FormGroup;
  examen: Examen;
  user:User;
  bancosPreguntas:BancoPregunta[];
  porcentajeTotal=0;

  get examen_bancos_preguntas(): FormArray {
    return this.examenFormGroup.get('examen_bancos_preguntas') as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private bancosPreguntasService:BancosPreguntasService,
    private examenesService:ExamenesService,
    private examenesBancosPreguntasService:ExamenesBancosPreguntasService,
    private location: Location
  ) {
    this.user=userService.getUser();
    // console.log(this.user.codigo_user);
    this.createBancoPreguntaForm();
    this.createExamenForm();
    // this.agregarBancoDePreguntas();
    this.examenFormGroup.controls['examen_bancos_preguntas'].valueChanges
    .subscribe(value=>{
      // console.log(value);
      let totalMinutosExamen=0;
      let totalPorcentaje=0;
      value.forEach(element => {
        let examenBancoPregunta:ExamenBancoPregunta=element;
        // console.log(examenBancoPregunta.duracion_minutos);
        if(examenBancoPregunta.duracion_minutos!=null){
          totalMinutosExamen=totalMinutosExamen+examenBancoPregunta.duracion_minutos;
        }
        if(examenBancoPregunta.porcentaje_calificacion!=null){
          totalPorcentaje=totalPorcentaje+examenBancoPregunta.porcentaje_calificacion;
        }
      });
      // console.log(totalMinutosExamen);
      this.examenFormGroup.controls['duracion_examen_minutos'].setValue(totalMinutosExamen);
      this.porcentajeTotal=totalPorcentaje;
    })
  }

  createBancoPreguntaForm(){
    this.bancoPreguntaFormGroup= this.formBuilder.group({
      bancoPreguntas:['',[Validators.required]]
    })
  }

  createExamenForm() {
    this.examenFormGroup = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      descripcion: [''],
      cod_user:[this.user.codigo_user],
      duracion_examen_minutos:[, [Validators.required ,Validators.min(1)]],
      nota_aprobacion:[51, [Validators.min(0)]],
      nota_admision:[70, [Validators.min(0)]],
      examen_bancos_preguntas:this.formBuilder.array([])
    });
  }


  agregarBancoDePreguntas(){
    let banco_pregunta=this.bancoPreguntaFormGroup.value.bancoPreguntas;
    // console.log(banco_pregunta.nombre);
    const examenBancoPregunta=this.formBuilder.group({
      examen_id:[],
      banco_pregunta_id:[banco_pregunta.id],
      banco_pregunta_nombre:[{value:banco_pregunta.nombre, disabled:true}],
      nombre: [banco_pregunta.nombre],           //nombre con el que aparecera en el examen del estudiante
      descripcion: [banco_pregunta.descripcion],
      nro_preguntas: [10,[Validators.min(1)]],
      porcentaje_calificacion: [20],
      duracion_minutos:[0],
      nro_orden: [],
      cod_user: [this.user.codigo_user],
    });
    this.examen_bancos_preguntas.push(examenBancoPregunta);
    // this.bancoPreguntaFormGroup.controls['bancoPreguntas'].setValue('');
    this.bancoPreguntaFormGroup.reset({
      bancoPreguntas:{value:""}
    });
    // console.log(this.examenFormGroup.get('examen_bancos_preguntas').value);
  }

  borrarExamenBancoPreguntas(indice: number) {
    this.examen_bancos_preguntas.removeAt(indice);
  }

  registrarExamen(){
    // console.log(this.examenFormGroup.value);
    this.examen=this.examenFormGroup.value;
    // console.log(this.examen);
    const insertExamem=this.examenesService.insertExamen(this.examen)
    .subscribe((res:any)=>{
      // console.log(res);
      let examen=res.data.insertOrUpdateExamen;
      let examenBancosPreguntas=this.examenFormGroup.controls['examen_bancos_preguntas'].value;
      examenBancosPreguntas.forEach(element => {
        let e:ExamenBancoPregunta=element;
        e.examen_id=examen.id;
        const insertExamenBancoPregunta=this.examenesBancosPreguntasService.insertExamenBancoPregunta(e).
        subscribe(res=>{
          // console.log(res);
        },
        err=>{
          // console.log(err);
        },
        ()=>{ 
          // console.log("completado"); 
          insertExamenBancoPregunta.unsubscribe(); 
        })
      });
    },
    err=>{
      // console.log(err);
    },
    ()=>{
      // console.log("completado");
      this.location.back();
      insertExamem.unsubscribe();
    })
  }



  ngOnInit(): void {
    const bancosPreguntas=this.bancosPreguntasService.getBancosPreguntas().subscribe(
      (data)=>{
        // console.log(data);
        this.bancosPreguntas=data;
      },
      (error)=>{
        // console.log(error);
      },
      ()=>{ 
        // console.log("completado"); 
        bancosPreguntas.unsubscribe(); 
      }
    )
  }

  onSubmit(){}

}
