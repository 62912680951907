import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserExamen } from '../@core/interfaces/user-examen.interface';
import { ExamenesGeneradosService } from '../@core/services/examenes-generados.service';
import { UsersExamenesService } from '../@core/services/users-examenes.service';
import { UsersService } from '../@core/services/users.service';
import { Operaciones } from '../@core/utils/opetarions';
import { ActivatedRoute } from '@angular/router';
import { log } from 'console';
@Component({
  selector: 'app-home-estudiante',
  templateUrl: './home-estudiante.component.html',
  styleUrls: ['./home-estudiante.component.scss']
})
export class HomeEstudianteComponent implements OnInit {


  moment: any = moment;
  // examenesUser:any[]=[];
  notaMinimaAprobacion = 50;
  user;
  ultimosExamenesGenerados = [];
  opAux = Operaciones;
  cargando = false;
  generando_examen_nuevo = false;
  generando_examen_repetido = false;



  constructor(
    private examenes_generadosService: ExamenesGeneradosService,
    private route: Router,
    private users_examenesService: UsersExamenesService,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.cargando = true;
    let selectUser = {};
    this.activatedRoute.params.subscribe(params => {
      const userString = params['user'];
      selectUser = userString?JSON.parse(userString):null;
      // Ahora, "this.user" contiene el objeto user pasado desde la otra página.
    });

    if (selectUser) {
      this.user = selectUser
      console.log('desde Admin', selectUser);
    }
    else {
      this.user = this.usersService.getUser();
      console.log('usuario logueado', this.user);
    }

    const getUltimosExamenesGenerados = this.examenes_generadosService.getUltimosExamenesGeneradosCodUser(this.user.codigo_user).
      subscribe((res: any) => {
        // console.log(res);
        this.ultimosExamenesGenerados = res.data.getUltimosExamenesGeneradosCodUser;
        this.cargando = false;

      },
        err => {
          // console.log(err);  
          this.cargando = false;
        },
        () => {
          // console.log("Completado get ultimos examenes generado"); 
          getUltimosExamenesGenerados.unsubscribe(); this.cargando = false;
        })
  }
  //   ngOnInit(): void {
  //     this.cargando = true;

  //     // Utiliza el activatedRoute para obtener el user_id de la URL
  //     const user_id = this.activatedRoute.snapshot.paramMap.get('user_id');

  //     // Si user_id está presente en la URL, úsalo; de lo contrario, obtén el user actual del servicio
  //     const userIdToUse = user_id ? parseInt(user_id, 10) : this.usersService.getUser().id;
  // console.log('userIdToUse',userIdToUse);

  //     const getUltimosExamenesGenerados = this.examenes_generadosService.getUltimosExamenesGeneradosCodUser(userIdToUse).
  //       subscribe((res: any) => {
  //         // ... tu lógica aquí ...
  //       },
  //       err => {
  //         // ... manejo de errores ...
  //       },
  //       () => {
  //         // ... código completado ...
  //         getUltimosExamenesGenerados.unsubscribe();
  //       });
  //   }
  generarExamen(user_examen_id) {
    this.generando_examen_nuevo = true;
    this.openSnackBar('Generando examen...');
    // console.log(user_examen_id);
    const generarExamen = this.examenes_generadosService.generarExamen(this.user.codigo_user, user_examen_id).
      subscribe((res: any) => {
        // console.log(res);
        this.generando_examen_nuevo = false;
        if (res.data.generarExamen) {
          let examenGenerado: any = res.data.generarExamen;
          console
          // const url='examen-estudiante?id-examen-generado=2';
          this.route.navigateByUrl(`/examen-estudiante/${examenGenerado.id}`);
        }
      },
        err => {
          // console.log(err);
          this.generando_examen_nuevo = false;
          this.openSnackBar('Error al generar el examen');
        },
        () => {
          // console.log("completado");
          generarExamen.unsubscribe();
        })
  }

  generarExamenRepetido(examen_generado_id) {
    this.generando_examen_repetido = true;
    this.openSnackBar('Generando examen...');
    const generarExamenRepetido = this.examenes_generadosService.generarExamenRepetido(this.user.codigo_user, examen_generado_id).
      subscribe((res: any) => {
        // console.log(res);
        this.generando_examen_repetido = false;
        if (res.data.generarExamenRepetido) {
          let examenGeneradoRepetido: any = res.data.generarExamenRepetido;
          // console.log(examenGeneradoRepetido);
          // const url='examen-estudiante?id-examen-generado=2';
          this.route.navigateByUrl(`/examen-estudiante/${examenGeneradoRepetido.id}`);
        }
      },
        err => {
          this.generando_examen_repetido = false;
          this.openSnackBar('Error al generar el examen');
          // console.log(err);
        },
        () => {
          // console.log("completado");
          generarExamenRepetido.unsubscribe();
        })
  }
  continuarExamen(examen_generado_id) {
    this.route.navigateByUrl(`/examen-estudiante/${examen_generado_id}`);
  }

  // dateTimeLocal(dateTime){
  //   return Operaciones.convertUTCDateToLocalDate(dateTime);
  // }
  cerrarSesion() {
    this.usersService.logout();
    this.route.navigateByUrl('login');
  }

  openSnackBar(mensaje: string) {
    // this.snackBar.open(mensaje,null, {horizontalPosition:'right', verticalPosition:'bottom'})
  }
}
