<h2 mat-dialog-title>Eliminar Banco de Preguntas</h2>

<mat-dialog-content>
    <p>
        {{data.nombre}}
    </p>
    <p>
        <mat-checkbox [(ngModel)]="confirmar" color="primary">Confirmar</mat-checkbox>
    </p>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="close()" cdkFocusInitial>Cancelar</button>
    <button mat-raised-button color="warn" (click)="eliminarBancoPreguntasPorEstado(data.id)"
        [disabled]="confirmar==false">Eliminar</button>
</mat-dialog-actions>