import { ExpocruzComponent } from './expocruz/expocruz.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardGuard } from './@core/auth/auth-guard.guard';
import { GuardHomeChildsGuard } from './@core/guards/guard-home-childs.guard';
import { CrearCuentaEstudianteComponent } from './crear-cuenta-estudiante/crear-cuenta-estudiante.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetallesEstudianteComponent } from './detalles-estudiante/detalles-estudiante.component';
import { EditInfoEstudianteComponent } from './edit-info-estudiante/edit-info-estudiante.component';
import { ExamenEstudianteComponent } from './examen-estudiante/examen-estudiante.component';
import { FormAddEstudianteComponent } from './form-add-estudiante/form-add-estudiante.component';
import { FormAddExamComponent } from './form-add-exam/form-add-exam.component';
import { FormAddQuestionBankComponent } from './form-add-question-bank/form-add-question-bank.component';
import { FormAddQuestionComponent } from './form-add-question/form-add-question.component';
import { FormEditQuestionComponent } from './form-edit-question/form-edit-question.component';
import { FormEditExamComponent } from './form-edit-exam/form-edit-exam.component';
import { FormEditQuestionBankComponent } from './form-edit-question-bank/form-edit-question-bank.component';
import { HistorialExamenesEstudianteComponent } from './historial-examenes-estudiante/historial-examenes-estudiante.component';
import { HomeEstudianteComponent } from './home-estudiante/home-estudiante.component';
import { HomeComponent } from './home/home.component';
import { ListOfExamsComponent } from './list-of-exams/list-of-exams.component';
import { ListOfQuestionBanksComponent } from './list-of-question-banks/list-of-question-banks.component';
import { ListOfQuestionsComponent } from './list-of-questions/list-of-questions.component';
import { ListaDeEstudiantesComponent } from './lista-de-estudiantes/lista-de-estudiantes.component';
import { LoginComponent } from './login/login.component';
import { PruebasComponent } from './pruebas/pruebas.component';
import { RecuperarCuentaComponent } from './recuperar-cuenta/recuperar-cuenta.component';
import { RestablecerPasswordComponent } from './restablecer-password/restablecer-password.component';
import { RevisarExamenEstudianteComponent } from './revisar-examen-estudiante/revisar-examen-estudiante.component';
import { VerificacionCorreoComponent } from './verificacion-correo/verificacion-correo.component';


const routes: Routes = [

  // {
  //   path:'exams', component:ExamsComponent
  // },

  { path: 'login', component: LoginComponent },
  {
    path: 'home', component: HomeComponent, canActivate: [GuardHomeChildsGuard],canActivateChild:[GuardHomeChildsGuard],
    children: [
      { path: 'list-of-question-banks', component: ListOfQuestionBanksComponent },
      { path: 'form-add-question-bank', component: FormAddQuestionBankComponent },
      { path: 'form-add-question/:id-question-bank', component: FormAddQuestionComponent },
      { path: 'form-edit-question/:id-question', component: FormEditQuestionComponent },
      { path: 'form-edit-question-bank/:id', component: FormEditQuestionBankComponent },
      { path: 'list-of-questions/:id-question-bank', component: ListOfQuestionsComponent },
      { path: 'list-of-exams', component: ListOfExamsComponent },
      { path: 'form-add-exam', component: FormAddExamComponent },
      { path: 'form-edit-exam/:id', component: FormEditExamComponent },
      { path: 'dashboard', component: DashboardComponent, },
      { path: 'form-add-estudiante', component: FormAddEstudianteComponent },
      { path: 'lista-de-estudiantes', component: ListaDeEstudiantesComponent },
      { path: 'detalles-estudiante/:user_id', component: DetallesEstudianteComponent },
      // { path: 'home-estudiante/:user_id', component: HomeEstudianteComponent },
    
    ]
  },
  { path: 'home-estudiante', component: HomeEstudianteComponent ,canActivate: [AuthGuardGuard]},
  { path: 'home-estudiante/:user', component: HomeEstudianteComponent, canActivate: [AuthGuardGuard] },
  // { path: 'home-estudiante/:id-user', component: HomeEstudianteComponent ,canActivate: [AuthGuardGuard]},
  { path: 'examen-estudiante/:id-examen-generado', component: ExamenEstudianteComponent,canActivate: [AuthGuardGuard] },
  { path: 'historial-examenes/:user_examen_id', component: HistorialExamenesEstudianteComponent ,canActivate: [AuthGuardGuard]},
  { path: 'revisar-examen/:examen_generado_id', component: RevisarExamenEstudianteComponent ,canActivate: [AuthGuardGuard]},
  { path: 'edit-info-estudiante', component: EditInfoEstudianteComponent ,canActivate: [AuthGuardGuard]},
  { path: 'pruebas', component: PruebasComponent ,canActivate: [AuthGuardGuard]},
  { path: 'verificacion-correo/:token', component: VerificacionCorreoComponent },
  { path: 'crear-cuenta-estudiante', component: CrearCuentaEstudianteComponent },
  { path: 'recuperar-cuenta', component: RecuperarCuentaComponent },
  { path: 'restablecer-password/:token', component: RestablecerPasswordComponent},
  { path: 'expocruz', component: ExpocruzComponent},
  
 


  // {
  //   path:'exam-add-form', component: ExamAddFormComponent
  // },
  // {
  //   path:'exam-edit-form/:idExam', component: ExamEditFormComponent
  // },
  {
    path: '',
    redirectTo: 'expocruz',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
