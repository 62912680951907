import { DialogDetallesExamenComponent } from './../dialogs/dialog-detalles-examen/dialog-detalles-examen.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { UsersRolesService } from '../@core/services/users-roles.service';
import { UsersService } from '../@core/services/users.service';
import { Operaciones } from '../@core/utils/opetarions';
import { Router } from '@angular/router';
@Component({
  selector: 'app-lista-de-estudiantes',
  templateUrl: './lista-de-estudiantes.component.html',
  styleUrls: ['./lista-de-estudiantes.component.scss']
})
export class ListaDeEstudiantesComponent implements OnInit ,AfterViewInit {

  opAux=Operaciones;
  moment: any = moment;
  users:any[]=[];
  users_roles:any[]=[];

  displayedColumns: string[] = [ 'created_at','primer_nombre','otros_nombres','apellido_paterno','apellido_materno','nro_ci','nro_celular','compl_ci','exped_ci','correo_electronico','codigo_user','opciones'];  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private usersService:UsersService,
    private users_rolesService:UsersRolesService,
    public matDialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    const getUsersEstudiantes = this.users_rolesService.getUsersEstudiantes().subscribe(
      (res: any) => {
        this.users_roles = res.data.getUsersEstudiantes.slice(); // Crear una copia
        this.users_roles.reverse();
        this.dataSource = new MatTableDataSource(this.users_roles);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        console.log(err);
      },
      () => { 
        getUsersEstudiantes.unsubscribe();
      }
    );
  
    const getUsers = this.usersService.getUsers().subscribe(
      (res: any) => {
        this.users = res.data.getUsers;
      },
      err => {
        console.log(err);
      },
      () => {
        getUsers.unsubscribe();
      }
    );
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

    trackByUid(index, item) {
      return item.uid;
    }

    openDialog(examen) {
      console.log(examen);
    }
    verDetalles(user: any) {
      console.log('userVer detalles',user);
      
      this.router.navigate(['/home-estudiante', { user: JSON.stringify(user) }]);
    }

}
