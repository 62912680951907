import { Injectable } from '@angular/core';
import { throwServerError } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { CookieService } from "ngx-cookie-service";
import { map } from 'rxjs/operators';
import { User } from '../interfaces/user.interface';

import { confirmacionTokenCorreoElectronico, deleteUserExamen, insertOrUpdateUser, insertUser, restablecer_password, verificarTokenRestablecerPassword } from '../operations/users/users-mutations';
import { existeCorreoElectronico, getUserCodigoUser, getUsers, loginEmailPass, reenviarCorreoCreacionCuenta } from '../operations/users/users-queries';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private apollo: Apollo,
    private cookies: CookieService
  ) { }

  loginEmailPass(correo_electronico: string, password: string) {
    // console.log(correo_electronico);
    // console.log(password);
    return this.apollo.query({
      query: loginEmailPass,
      fetchPolicy: 'network-only',
      variables:{
         correo_electronico: correo_electronico,
         password: password
      }
    })
    .pipe(map((result: any) => {
      return result.data.loginEmailPass;
    }))
  }

  //t=true f=false estado de login
  setEstadoLogin(value: string) {
    localStorage.setItem('estadoLogueado',value);
    // this.cookies.set('estadoLogueado', value);
  }
  logout() {
    // this.cookies.set('estadoLogueado', 'f');
    // this.cookies.delete("estadoLogueado");
    // console.log(this.cookies.getAll());
    localStorage.clear()
  }
  getEstadoLogin() {
    return localStorage.getItem('estadoLogueado');
    // return this.cookies.get("estadoLogueado");
  }

  getUser():User{
    let userLocalStorage:User=JSON.parse(localStorage.getItem("user"));
    return userLocalStorage;
  }

  getRoles(){
    let rolesLocalStorage:string[]=JSON.parse(localStorage.getItem("roles"));
    return rolesLocalStorage;
  }

  getUsers(){
    return this.apollo.query({
      query: getUsers,
      fetchPolicy:'network-only'
    })
  }

  insertUser(userData:User){
    return this.apollo.mutate({
      mutation: insertUser,
      variables:{userData:userData}
    })
  }

  getUserCodigoUser(codigo_user){
    return this.apollo.query({
      query:getUserCodigoUser,
      fetchPolicy:'network-only',
      variables:{codigo_user:codigo_user}
    })
  }

  updateUser(userData:User){
    return this.apollo.mutate({
      mutation: insertOrUpdateUser,
      variables:{userData:userData}
    })
  }

  existeCorreoElectronico(correo_electronico:string){
    return this.apollo.query({
      query:existeCorreoElectronico,
      variables:{
        correo_electronico:correo_electronico
      },
      fetchPolicy:'network-only'
    })
  }
  confirmacionTokenCorreoElectronico(token){
    return this.apollo.mutate({
      mutation:confirmacionTokenCorreoElectronico,
      variables:{ token: token }
    })
  }
  restablecer_password(correo_electronico:string){
    return  this.apollo.mutate({
      mutation:restablecer_password,
      variables:{ correo_electronico:correo_electronico}
    })
  }

  verificarTokenRestablecerPassword(token){
    return this.apollo.mutate({
      mutation:verificarTokenRestablecerPassword,
      variables:{token:token}
    })
  }
  reenviarCorreoCreacionCuenta(correo_electronico){
    return this.apollo.mutate({
      mutation:reenviarCorreoCreacionCuenta,
      variables:{correo_electronico:correo_electronico}
    })
  }

}
