import gql from 'graphql-tag';


export const getExamenGenerado = gql`
     query getExamenGenerado($id: Int!){
      getExamenGenerado(id:$id){
        id,
        codigo_examen,
        duracion_examen_minutos,
        user_examen_id,
    user_examen{
      id
      user_id
      examen_id
      examen{
        id
        codigo
        nombre
        descripcion
        duracion_examen_minutos
        nota_aprobacion
        nota_admision
        estado
        created_at
        updated_at
        cod_user
      }
      estado
      created_at
      updated_at
      cod_user
    }
        nota_examen,
        examen_finalizado,
        estado,
        created_at,
        updated_at,
        cod_user,
        preguntas_examenes_generados{
          id,
          examen_generado_id,
          examen_banco_pregunta_id,
          examen_banco_pregunta{
            id,
            nombre,
            descripcion,
            nro_preguntas,
            porcentaje_calificacion,
            duracion_minutos,
            nro_orden
          },
          pregunta_id,
          pregunta{
            opcion_multiple,
            partes_pregunta{
              texto,
              imagen_nombre,
              imagen_url,
              imagen_height,
              nro_orden,
              estado
            }
          },
          banco_pregunta_id,
          respuesta_correcta,
          estado,
          created_at,
          updated_at,
          opciones_respuestas_users{
            id,
            pregunta_examen_generado_id,
            opcion_respuesta_id,
            opcion_respuesta{
              texto,
              imagen_nombre,
              imagen_url,
              imagen_height,
              nro_orden,
              opcion_correcta
            },
            valor_opcion,
            estado,
            created_at,
            updated_at,
            cod_user
          }     
        }
      }
    }
`;

export const getDuracionRestanteExamenGenerado =gql`
  query getDuracionRestanteExamenGenerado($id: Int!){
    getDuracionRestanteExamenGenerado(id:$id)
  }
`;

export const getUltimosExamenesGeneradosCodUser= gql`
query getUltimosExamenesGeneradosCodUser($cod_user:String!){
  getUltimosExamenesGeneradosCodUser(cod_user:$cod_user){
    user_examen{
      id,
      user_id,
      user{
        primer_nombre,
        otros_nombres,
        apellido_paterno,
        apellido_materno,
        codigo_user,
        correo_electronico,
        nro_ci,
        compl_ci,
        exped_ci
      },
      examen{
        id,
        codigo,
        nombre,
        descripcion,
        duracion_examen_minutos,
        nota_aprobacion,
        nota_admision,
        examen_bancos_preguntas{
          id,
          nombre,
          descripcion,
          nro_preguntas,
          porcentaje_calificacion,
          duracion_minutos,
          nro_orden,
          estado
        }
      }
    },
    ultimo_examen_generado{
      id,
      codigo_examen,
      duracion_examen_minutos,
      nota_examen,
      fecha_hora_inicio,
      fecha_hora_fin,
      examen_finalizado,
      created_at
    }
  }
}
`;

