<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a class="a-perfil">
        <img src="assets/images/img_default_user.png" alt="Avatar" class="avatar">
        {{user.primer_nombre+" "+user.otros_nombres}} {{user.apellido_paterno}} {{user.apellido_materno}}
      </a>
      <!-- <a mat-list-item routerLink="dashboard">Dashboard</a> -->
      <a mat-list-item routerLink="list-of-question-banks">Bancos de preguntas</a>
      <a *ngIf="tieneAcceso()" mat-list-item routerLink="list-of-exams">Exámenes</a>
      <a *ngIf="tieneAcceso()" mat-list-item routerLink="lista-de-estudiantes">Estudiantes</a>
      
      <!-- <a mat-list-item href="#">Construir</a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- <mat-toolbar color="primary"> -->
      <mat-toolbar class="mat-elevation-z3 mb-1" >
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="truncar_texto_nowrap">EXÁMENES SIMULACROS</span>
      <span class="example-spacer"></span>
      <button mat-stroked-button (click)="cerrarSesion()"><span> Cerrar sesión</span> </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <!-- <app-address-form></app-address-form> -->
    <!-- <app-table></app-table>  -->
    <router-outlet></router-outlet>
    <!-- <app-dashboard></app-dashboard> -->
    <!-- <app-tree></app-tree> -->
    <!-- <app-drag-drop></app-drag-drop> -->
  </mat-sidenav-content>
</mat-sidenav-container>
