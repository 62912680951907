<mat-toolbar class="toolbar_home_estudiante app-toolbar">

    <img src="assets/images/logoCCA2.png" id="altura_imagen">
    <span class="ml-1 t_h_e_title truncar_texto_nowrap"> Examen Simulacro</span>
    <span class="example-spacer"></span>
    <countdown #cd [config]="{leftTime: tiempoExamen, notify: [ 1 ]}" (event)="handleEvent($event)"></countdown>
</mat-toolbar>

<!-- <ng-container *ngIf="(asyncTabs | async) === null">
        Loading tabs...
      </ng-container>
      
      <mat-tab-group>
        <mat-tab *ngFor="let tab of asyncTabs | async">
          <ng-template mat-tab-label>{{tab.label}}</ng-template>
          {{tab.content}}
        </mat-tab>
      </mat-tab-group> -->


<p *ngIf="!isConnected"
class="mx-2 my-2"
>
No tiene conexión a Internet
</p>



<mat-tab-group *ngIf="this.preguntas_por_bancos_d_preg && isConnected" animationDuration="0ms">
    <mat-tab *ngFor="let banco_preguntas of this.preguntas_por_bancos_d_preg_query | keyvalue; let i=index">
        <ng-template mat-tab-label>{{banco_preguntas.value[0].examen_banco_pregunta.nombre|uppercase}}</ng-template>
        <div *ngFor="let preguntaExamen of banco_preguntas.value; let i=index" class="pt-2">

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                <mat-card class="mb-1" fxFlex="80%" fxFlex.lt-md="100%" fxFlex.gt-md="65%">
                    <div fxLayout="row" fxLayoutAlign="start start" class="mb-1">
                        <strong class="mr-1 ">{{i+1}})</strong>
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                            <div *ngFor="let questionPart of preguntaExamen.pregunta.partes_pregunta;let  i=index"
                                class="d-flex">
                                <div *ngIf="questionPart.texto!=''" class="mb-0 mr-1">
                                    {{questionPart.texto}}
                                </div>
                                <img class="mr-1 imgPartQuestion" *ngIf="questionPart.imagen_url!=''"
                                    [ngStyle]="{'height.px':questionPart.imagen_height}"
                                    src={{questionPart.imagen_url}}>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="!preguntaExamen.pregunta.opcion_multiple">

                        <mat-radio-button class="txtRadioButton" [name]="preguntaExamen.id" class="example-radio-button"
                            *ngFor="let answerOption of preguntaExamen.opciones_respuestas_users; let indexOpcionRespuestaUser=index"
                            [checked]="answerOption.valor_opcion"
                            (change)="changeSeleccionUnica($event, preguntaExamen.pregunta.opcion_multiple)"
                            [value]="answerOption">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <h3 class="mb-0 mr-2">{{letraOpcion(indexOpcionRespuestaUser+1)+") "}}
                                </h3>
                                <div fxLayout="row wrap" fxLayoutAlign="start center">
                                    <span class="mat-radio-label" *ngIf="answerOption.opcion_respuesta.texto!=''">
                                        {{answerOption.opcion_respuesta.texto}}
                                    </span>
                                    <span *ngIf="answerOption.opcion_respuesta.imagen_url!=''">
                                        <img class="imgPartQuestion mr-1"
                                            [ngStyle]="{'height.px':answerOption.opcion_respuesta.imagen_height}"
                                            src={{answerOption.opcion_respuesta.imagen_url}} alt="">
                                    </span>
                                </div>
                            </div>
                        </mat-radio-button>

                    </div>


                    <div *ngIf="preguntaExamen.pregunta.opcion_multiple" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="gappx">

                        <mat-checkbox class="m-checkbox"
                            *ngFor="let answerOption of preguntaExamen.opciones_respuestas_users; let indexOpcionRespuestaUser=index"
                            [checked]="answerOption.valor_opcion"
                            (change)="changeSeleccionMultiple($event, preguntaExamen.pregunta.opcion_multiple)"
                            [value]="answerOption">

                            <div fxLayout="row" fxLayoutAlign="start center">
                                <h3 class="mb-0 mr-2">{{letraOpcion(indexOpcionRespuestaUser+1)+") "}}
                                </h3>
                                <div fxLayout="row wrap" fxLayoutAlign="start center">
                                    <span class="mat-radio-label" *ngIf="answerOption.opcion_respuesta.texto!=''">
                                        {{answerOption.opcion_respuesta.texto}}
                                    </span>
                                    <span *ngIf="answerOption.opcion_respuesta.imagen_url!=''">
                                        <img class="imgPartQuestion mr-1"
                                            [ngStyle]="{'height.px':answerOption.opcion_respuesta.imagen_height}"
                                            src={{answerOption.opcion_respuesta.imagen_url}} alt="">
                                    </span>
                                </div>
                            </div>
                        </mat-checkbox>
                    </div>
                </mat-card>
            </div>

        </div>
    </mat-tab>
    <mat-tab >
        <ng-template mat-tab-label>FINALIZAR EXAMEN</ng-template>
        <div class="mx-2 mt-2">
            <div *ngIf="this.faltaPorResponder()==true">
                <h2 class="color_1">Le falta por responder: </h2>
                <div >
                    <table>
                        <tr *ngFor="let elem of this.resumen_examen; let i=index">
                            <td class="mr-1">{{elem.banco_de_pregunta +' ' |uppercase}}</td>
                            <td>{{' '+elem.preguntas_por_responder +' de '+ elem.preguntas.length+' preguntas'}}</td>
                        </tr>
                    </table>
                    
                    
                    
                </div>
                <br>
                <p>Si esta seguro de concluir con la prueba presione el boton <strong>FINALIZAR EXAMEN</strong> </p>
            </div>
            
            
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" class="mt-2" *ngIf="!cargando">
                <button mat-raised-button color="primary" (click)="finalizarExamen()">FINALIZAR EXAMEN</button>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>

<div *ngIf="cargando" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">
    <br><br>
    <p>Cargando...</p>
    <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->

</div>
<div class="container_examen">


    <!-- <div *ngFor="let preguntaExamen of preguntasExamen; let i=index">
    
            <div *ngIf="diferenteBancoDePreguntas(preguntaExamen,preguntasExamen[i-1])" class="pt-3"
                fxLayoutAlign="center center">
                <h1 class="titleNombreMateria color-1 ">
                    {{preguntaExamen.examen_banco_pregunta.nombre}}</h1>
            </div>
    
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                <mat-card class="mb-1" fxFlex="80%" fxFlex.lt-md="100%" fxFlex.gt-md="65%">
                    <div fxLayout="row" fxLayoutAlign="start start" class="mb-1">
                        <strong class="mr-1 ">{{i+1}})</strong>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" >
                            <div *ngFor="let questionPart of preguntaExamen.pregunta.partes_pregunta;let  i=index" class="d-flex">
                                <div *ngIf="questionPart.texto!=''" class="mb-0 mr-1">
                                    {{questionPart.texto}}
                                </div>
                                <img class="mr-1 imgPartQuestion" *ngIf="questionPart.imagen_url!=''"
                                    [ngStyle]="{'height.px':questionPart.imagen_height}" src={{questionPart.imagen_url}}>
                            </div>
            
                        </div>
                    </div>
            
                    <div *ngIf="!preguntaExamen.pregunta.opcion_multiple" >
                        <mat-radio-button class="txtRadioButton" [name]="preguntaExamen.id" class="example-radio-button"
                            *ngFor="let answerOption of preguntaExamen.opciones_respuestas_users; let indexOpcionRespuestaUser=index"
                            [checked]="answerOption.valor_opcion"
                            (change)="changeSeleccionUnica($event, preguntaExamen.pregunta.opcion_multiple)" [value]="answerOption">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <h3 class="mb-0 mr-2">{{letraOpcion(answerOption.opcion_respuesta.nro_orden+1)+") "}}
                                </h3>
                                <div fxLayout="row wrap" fxLayoutAlign="start center">
                                    <span class="mat-radio-label" *ngIf="answerOption.opcion_respuesta.texto!=''">
                                        {{answerOption.opcion_respuesta.texto}}
                                    </span>
                                    <span *ngIf="answerOption.opcion_respuesta.imagen_url!=''">
                                        <img class="imgPartQuestion mr-1"
                                            [ngStyle]="{'height.px':answerOption.opcion_respuesta.imagen_height}"
                                            src={{answerOption.opcion_respuesta.imagen_url}} alt="">
                                    </span>
                                </div>
                            </div>
                        </mat-radio-button>
                    </div>
            
            
                    <div *ngIf="preguntaExamen.pregunta.opcion_multiple" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="gappx" >
                        <mat-checkbox class="m-checkbox"
                            *ngFor="let answerOption of preguntaExamen.opciones_respuestas_users; let indexOpcionRespuestaUser=index"
                            [checked]="answerOption.valor_opcion"
                            (change)="changeSeleccionMultiple($event, preguntaExamen.pregunta.opcion_multiple)"
                            [value]="answerOption">
            
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <h3 class="mb-0 mr-2">{{letraOpcion(answerOption.opcion_respuesta.nro_orden+1)+") "}}
                                </h3>
                                <div fxLayout="row wrap" fxLayoutAlign="start center">
                                    <span class="mat-radio-label" *ngIf="answerOption.opcion_respuesta.texto!=''">
                                        {{answerOption.opcion_respuesta.texto}}
                                    </span>
                                    <span *ngIf="answerOption.opcion_respuesta.imagen_url!=''">
                                        <img class="imgPartQuestion mr-1"
                                            [ngStyle]="{'height.px':answerOption.opcion_respuesta.imagen_height}"
                                            src={{answerOption.opcion_respuesta.imagen_url}} alt="">
                                    </span>
                                </div>
                            </div>
                        </mat-checkbox>
                    </div>
                </mat-card>
            </div>
    
        </div> -->



</div>

<br><br><br><br>