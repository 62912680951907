
<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx" class="mx-4 my-1 ">
    <img src="assets/images/logoCCA2.png" width="80px">
</div>
<br><br>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
    <mat-card fxFlex='30rem' fxFlex.xs='95'>
        <mat-card-header>
            <mat-card-title>Verificación de cuenta</mat-card-title>
            <!-- <mat-card-subtitle>subtitle</mat-card-subtitle> -->
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>

            <ng-container *ngIf="verificando_token; else elseTemplate">
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem" class="my-4">
                    <p>Verificando correo electrónico...</p>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
               
            </ng-container>
            <ng-template #elseTemplate>
                <ng-container *ngIf="token_verificado_correctamente; else elseTemplate1">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" class="mt-4 mb-2">
                        <p class="mb-0 mr-1">Su correo fue verificado correctamente. </p>
                        <span> <mat-icon class="color_success">done_outline</mat-icon></span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
                        <button (click)="ir_a_home_estudiante()" mat-raised-button color='primary' ><mat-icon class="mr-1">home</mat-icon> Ir a la página principal</button>
                    </div> 
                </ng-container>
                <ng-template #elseTemplate1>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx" class="mt-4 mb-2">
                        <p class="mb-0 mr-1">Error al verificar el correo electrónico. </p>
                        <span> <mat-icon class="color_warn">report_problem</mat-icon></span>
                    </div>
                </ng-template>
                
            </ng-template>
            

      
        </mat-card-content>
        <!-- <mat-card-actions>
            <button mat-button>Ok</button>
        </mat-card-actions> -->
    </mat-card>    
</div>



