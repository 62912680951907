import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expocruz',
  templateUrl: './expocruz.component.html',
  styleUrls: [
    './expocruz.component.scss',
  ]
})
export class ExpocruzComponent implements OnInit {
  carouselItems = [
    { image: 'assets/expocruz/ilustrator/1.webp', title:'ADMISIÓN-USFX', description:'Prepárate para los exámenes de a la USFX con nuestro simulador diseñado para fortalecer tus conocimientos y asegurar tu éxito. ¡Alcanza tu máximo potencial y asegura tu lugar en la universidad!' },
    { image: 'assets/expocruz/ilustrator/2.webp' ,title:'ADMISIÓN-ESFM', description:'Simulador diseñado para futuros maestros que desean prepararse para el examen de ingreso a la ESFM. Practica, refuerza tus conocimientos y enfréntate a la prueba con seguridad.'},
    { image: 'assets/expocruz/ilustrator/3.webp' , title:'ADMISIÓN-FATESCIPOL', description:'Simulador especializado para candidatos a la FATESCIPOL, diseñado para preparar a futuros policías. Refuerza tus conocimientos, practica con exámenes reales y prepárate para enfrentar con éxito las pruebas de admisión.'},
  ];

  currentSlide = 0;
  slideInterval: any;
  constructor() { }

  ngOnInit(): void {
    this.startCarousel();
  }
  startCarousel(): void {
    this.slideInterval = setInterval(() => {
      this.nextSlide();
    }, 3000); // Cambia de imagen cada 3 segundos
  }

  nextSlide(): void {
    console.log('next');
    
    this.currentSlide = (this.currentSlide + 1) % this.carouselItems.length;
  }

  prevSlide(): void {
    console.log('prev');
    this.currentSlide =
      (this.currentSlide - 1 + this.carouselItems.length) % this.carouselItems.length;
  }

  ngOnDestroy(): void {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }

}
