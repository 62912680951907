<p>
  <mat-toolbar>
    <mat-toolbar-row>
      <span>Estudiantes</span>
      <span class="example-spacer"></span>
      <button mat-raised-button color="primary" routerLink="/home/form-add-estudiante"><i
          class="material-icons">add</i><span> Registrar estudiante</span></button>
    </mat-toolbar-row>
  </mat-toolbar>
</p>

<div class="container ">
  <mat-form-field>
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder=" " #input>
  </mat-form-field>

  <div class="mat-elevation-z1 scroll">
    <table mat-table [dataSource]="dataSource" matSort [trackBy]="trackByUid" class="animate">

      <!-- Position Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha registro </th>
        <!-- <td mat-cell *matCellDef="let element"> {{moment(dateTimeLocal(element.user.created_at)).format('YYYY/MM/DD HH:mm:ss')}} </td> -->
        <td mat-cell *matCellDef="let element"> {{opAux.fechaTimeZone(element.user.created_at)}} </td>
      </ng-container>

      <ng-container matColumnDef="primer_nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Primer nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.user.primer_nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="otros_nombres">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros nombres </th>
        <td mat-cell *matCellDef="let element"> {{element.user.otros_nombres}} </td>
      </ng-container>

      <ng-container matColumnDef="apellido_paterno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido Paterno </th>
        <td mat-cell *matCellDef="let element"> {{element.user.apellido_paterno}} </td>
      </ng-container>

      <ng-container matColumnDef="apellido_materno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido Materno </th>
        <td mat-cell *matCellDef="let element"> {{element.user.apellido_materno}} </td>
      </ng-container>
      <ng-container matColumnDef="nro_celular">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de Celular </th>
        <td mat-cell *matCellDef="let element"> {{element.user.nro_celular}} </td>
      </ng-container>
      <ng-container matColumnDef="nro_ci">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. CI </th>
        <td mat-cell *matCellDef="let element"> {{element.user.nro_ci}} </td>
      </ng-container>
      <ng-container matColumnDef="compl_ci">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Compl. CI </th>
        <td mat-cell *matCellDef="let element"> {{element.user.compl_ci}} </td>
      </ng-container>
      <ng-container matColumnDef="exped_ci">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Exped. CI </th>
        <td mat-cell *matCellDef="let element"> {{element.user.exped_ci}} </td>
      </ng-container>
      <ng-container matColumnDef="correo_electronico">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo Electronico </th>
        <td mat-cell *matCellDef="let element"> {{element.user.correo_electronico}} </td>
      </ng-container>
      <ng-container matColumnDef="codigo_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod User </th>
        <td mat-cell *matCellDef="let element"> {{element.user.codigo_user}} </td>
      </ng-container>

      <ng-container matColumnDef="examen_preuniversitario">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod User </th>
        <td mat-cell *matCellDef="let element"> {{element.user.codigo_user}} </td>
      </ng-container>

      <ng-container matColumnDef="opciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Opciones </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" [matMenuTriggerFor]="beforeMenu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #beforeMenu="matMenu" xPosition="before">
            <!-- <button mat-menu-item [routerLink]="['/home/home-estudiante',element.user.id]"> <span>Ver
                detalles</span></button> -->
                <button mat-menu-item (click)="verDetalles(element.user)"> <span>Ver detalles</span></button>
          </mat-menu>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>