import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TransitionCheckState } from '@angular/material/checkbox';
import { map } from 'rxjs/operators';
import { UserExamen } from '../@core/interfaces/user-examen.interface';
import { UserRol } from '../@core/interfaces/user-rol.interface';
import { User } from '../@core/interfaces/user.interface';
import { ExamenesService } from '../@core/services/examenes.service';
import { UsersExamenesService } from '../@core/services/users-examenes.service';
import { UsersRolesService } from '../@core/services/users-roles.service';
import { UsersService } from '../@core/services/users.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-crear-cuenta-estudiante',
  templateUrl: './crear-cuenta-estudiante.component.html',
  styleUrls: ['./crear-cuenta-estudiante.component.scss']
})
export class CrearCuentaEstudianteComponent implements OnInit {

  estudianteFormGroup: FormGroup;
  departamentos_exped_ci: string[] = ['Chuquisaca', 'La Paz', 'Cochabamba', 'Oruro', 'Potosi', 'Tarija', 'Santa Cruz', 'Beni', 'Pando'];
  examenesPreuniversitarios = [];
  visible_pass = false;
  mensaje_error = '';
  registrando_estudiante = false;
  estudiante_registrado_correctamente=false;

  constructor(
    private fb: FormBuilder,
    private examenesService: ExamenesService,
    private usersService: UsersService,
    private users_examenesService: UsersExamenesService,
    private users_rolesService: UsersRolesService,
    private location:Location,
  ) {
    this.buildEstudianteForm();
    this.cargarExamenesPreuniversitarios()
  }

  ngOnInit(): void {
  }

  cargarExamenesPreuniversitarios() {
    this.examenesService.getExamenes().
      subscribe((res: any) => {
        // console.log(res);
        this.examenesPreuniversitarios = res.data.getExamenes;
      },
        err => { 
          // console.log(err);
         },
        () => { 
          // console.log("completado"); 
        }
      );
  }

  buildEstudianteForm() {

    this.estudianteFormGroup = this.fb.group({
      primer_nombre: ['', [Validators.required]],
      otros_nombres: [''],
      apellido_paterno: [''],
      apellido_materno: [''],
      correo_electronico: new FormControl('', [Validators.required, Validators.email], this.validarCorreoElectronico.bind(this)),
      nro_celular:['', [Validators.required, Validators.minLength(8),Validators.maxLength(8)] ],
      // nro_ci: ['',[Validators.required]],
      // compl_ci: [''],
      // exped_ci: ['',[Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmar_password: ['', [Validators.required, Validators.minLength(4)]],
      examenPreUniversitario_id: [, [Validators.required]],
      visible_pass: [false]
    })
  }
  get correoElectronico() { return this.estudianteFormGroup.get('correo_electronico'); }

  validarCorreoElectronico(control: AbstractControl) {
    return this.usersService.existeCorreoElectronico(control.value).pipe(map((res: any) => {
      // console.log(res.data.existeCorreoElectronico);
      return !res.data.existeCorreoElectronico ? null : { existe: true }
    }))
  }

  registrarEstudiante() {

    this.mensaje_error = "";
    if (this.estudianteFormGroup.valid) {
      if (this.estudianteFormGroup.get('password').value != this.estudianteFormGroup.get('confirmar_password').value) {
        this.mensaje_error = "Las contraseñas no coinciden.";
        // console.log("las contrase;as no coinciden")
      } else {
        this.registrarUser(this.estudianteFormGroup.value);
      }
    }
  }

  registrarUser(valueEstFormGroup) {
    this.registrando_estudiante = true;
    // console.log(valueEstFormGroup);
    let element = valueEstFormGroup;
    let user = {} as User;
    user.primer_nombre = element.primer_nombre;
    user.otros_nombres = element.otros_nombres;
    user.apellido_paterno = element.apellido_paterno;
    user.apellido_materno = element.apellido_materno;
    user.nro_celular= element.nro_celular;
    user.correo_electronico = element.correo_electronico;
    user.password = element.password;
    this.usersService.insertUser(user).subscribe(
      (res: any) => {
        // console.log(res.data.insertUser);
        let user=res.data.insertUser;
        this.registrarUserExamem(user,valueEstFormGroup.examenPreUniversitario_id)
      },
      err => { this.registrando_estudiante = false; },
      () => { }
    )

  }

  registrarUserExamem(user, examen_id) {
    let user_examen = {} as UserExamen;
    user_examen.cod_user = user.codigo_user;
    user_examen.user_id = user.id;
    user_examen.examen_id = examen_id;
    this.users_examenesService.insertUserExamen(user_examen).
      subscribe((res: any) => {
        let user_examen = res.data.insertUserExamen;
        this.registrarUserRol(user);
      },
        err => { this.registrando_estudiante = false; },
        () => { }
      )
  }

  registrarUserRol(user) {
    let user_rol={} as UserRol;
    user_rol.rol_id=3; //el id 3 es del rol estudiante
    user_rol.user_id=user.id;
    user_rol.cod_user=user.codigo_user;

    this.users_rolesService.insertUserRol(user_rol).
    subscribe((res:any)=>{
      // console.log(res);
      this.registrando_estudiante=false;
      this.estudiante_registrado_correctamente=true;
      //  alert('Estudiante registrado correctamente.');
        // this.location.back();
    },
    err=>{
      // console.log(err);
    },
    ()=>{
      // console.log("completado registro user_rol"); 
    }
  );
  }

  reenviarCorreo(){
    let correo_electronico=this.estudianteFormGroup.controls['correo_electronico'].value;
    this.usersService.reenviarCorreoCreacionCuenta(correo_electronico).subscribe(
      (res:any)=>{
        alert("Correo reenviado.");
      }
    )
  }
}
