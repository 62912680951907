import { DialogDetallesExamenComponent } from './../dialogs/dialog-detalles-examen/dialog-detalles-examen.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ExamenesService } from '../@core/services/examenes.service';
import { Operaciones } from '../@core/utils/opetarions';
import { EliminarExamAlertDialogComponent } from '../eliminar-exam-alert-dialog/eliminar-exam-alert-dialog.component';

@Component({
  selector: 'app-list-of-exams',
  templateUrl: './list-of-exams.component.html',
  styleUrls: ['./list-of-exams.component.scss']
})
export class ListOfExamsComponent implements OnInit, AfterViewInit {

  moment:any=moment;
  examenes:any[]=[];
  opAux=Operaciones;

  displayedColumns: string[] = [ 'created_at','codigo','nombre','bancos','opciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private examenesService:ExamenesService,
    public matDialog: MatDialog
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.examenesService.getExamenes().
    subscribe((res:any)=>{
      // console.log(res.data.getExamenes);
      this.examenes=res.data.getExamenes;
      this.dataSource=new MatTableDataSource(this.examenes);
      this.dataSource.paginator= this.paginator;
      this.dataSource.sort= this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  trackByUid(index, item) {
    return item.uid;
  }

  openDialog(examen) {
    console.log(examen);
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data={
      examen
    };
    let dialogRef=this.matDialog.open(DialogDetallesExamenComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( result=>{
    });
  }
  deleteExam(exam) {
    
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data=exam;
    let dialogRef=this.matDialog.open(EliminarExamAlertDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( value=>{
      // console.log(`Dialog envio: ${value}`);
      window.location.reload();
    });
  }

}
